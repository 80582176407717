import isAbsoluteUrl from 'onyx-common/isAbsoluteUrl'

const toAbsoluteUrl = (url, origin) => {
  if (isAbsoluteUrl(url)) return url

  if (!origin) origin = window.location.origin

  return `${origin}${url}`
}

export default toAbsoluteUrl
