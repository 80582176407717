import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const RouteComponent = ({ component, componentProps = {}, routeData, prepared, scrollToTop }) => {
  const Component = component.read()
  if (!prepared) prepared = {}

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0)
    }
  }, [scrollToTop])

  return (
    <Component
      {...componentProps}
      routeData={routeData}
      {...prepared}
    />
  )
}

RouteComponent.propTypes = {
  componentProps: PropTypes.object,
  routeData: PropTypes.object.isRequired,
  prepared: PropTypes.any,
  scrollToTop: PropTypes.bool,
  component: PropTypes.object.isRequired
}

export default RouteComponent
