// DO NOT USE ARROW FUNCTIONS FOR PROTOTYPE ADDITIONS
// They don't have access to the rest of the `this` context from the compiled class
import VibeApi from './VibeApiBase'
import VibeApiAuth from './VibeApiAuth'
import VibeApiShop from './VibeApiShop'

// decorate the prototype
VibeApiAuth(VibeApi)
VibeApiShop(VibeApi)

export default VibeApi
