import normalizeQueryParam from 'onyx-common/normalizeQueryParam'
import isUndefined from 'onyx-common/isUndefined'

const normalizeQueryParams = (params) => {
  return Object.keys(params).reduce((acc, key) => {
    const value = params[key]
    const normalized = normalizeQueryParam(key, value)

    // this is how we allow values to be removed entirely
    return isUndefined(normalized) ? acc : acc.concat(normalized)
  }, [])
}

export default normalizeQueryParams
