import getCurrentDecoratedLocation from 'onyx-common/getCurrentDecoratedLocation'

const getOnyxIsEmbedded = (win = window) => {
  if (win.location !== win.parent.location) return true

  const {
    queryParams: {
      embedded
    }
  } = getCurrentDecoratedLocation()

  return !!embedded
}

export default getOnyxIsEmbedded
