import Context from './context'
import PropTypes from 'prop-types'
import React from 'react'

const ConfigProvider = ({ children, config }) => (
  <Context.Provider value={config}>{children}</Context.Provider>
)

ConfigProvider.propTypes = {
  config: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default ConfigProvider
