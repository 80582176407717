import JSResource from 'onyx-common/JSResource'

const PreloadImage = (src) => {
  JSResource(src, () => {
    return new Promise(resolve => {
      const img = new Image()
      img.onload = () => {
        resolve(src)
      }
      img.onerror = error => {
        console.error(error)
        resolve(src)
      }
      img.src = src
    })
  }).load()
}

export default PreloadImage
