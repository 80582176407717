import WebFont from 'onyx-common/webfontloader'

const themeId = 'Vibe'
const lightThemeId = 'Vibe'
const darkThemeId = 'VibeDark'

const getThemeSetup = (themePayload) => () => {
  WebFont.load({
    google: {
      families: ['Roboto:300,400,500,700', 'Material Icons']
    }
  })
}

const getThemeDefinition = (themePayload) => {
  const opts = {
    palette: {
      text: {
        primary: '#566270'
      },
      background: {
        paper: '#fafafa',
        paperActionBar: '#F3F4F4',
        default: '#fff',
        footerTop: '#838990',
        footerBtm: '#666f7a'
      },
      shadow: {
        default: '#e0e0e1'
      },
      border: {
        default: '#e0e0e1'
      },
      primary: {
        light: '#e6ecf5',
        main: '#4c79b7',
        dark: '#345481',
        contrastText: '#fff'
      },
      secondary: {
        light: '#838990',
        main: '#838990',
        dark: '#838990'
      },
      tertiary: {
        light: '#374060',
        main: '#374060',
        dark: '#374060,',
        contrastText: '#fff'
      },
      icon: {
        check: '#71cea3'
      }
    },
    shape: {
      defaultRounded: 4,
      chubby: 8,
      rounded: 20
    },
    snackbar: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      }
    },
    topBarProgress: {
      barColors: {
        0: '#4c79b7',
        '1.0': '#4c79b7'
      },
      shadowBlur: 0
    },
    materialIconBase: {
      font: 'unset',
      fontFamily: 'Material Icons',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontSize: '24px',
      lineHeight: '1',
      letterSpacing: 'normal',
      textTransform: 'none',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      wordWrap: 'normal',
      direction: 'ltr',
      fontFeatureSettings: 'liga',
      fontSmoothing: 'antialiased',
      width: '1em',
      height: '1em',
      overflow: 'hidden',
      flexShrink: '0',
      userSelect: 'none'
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            height: '100vh'
          }
        }
      },
      MuiFormControlLabel: {
        root: {
          userSelect: 'none'
        }
      }
    }
  }

  opts.palette.primaryAlt = { ...opts.palette.primary }
  opts.palette.secondaryAlt = { ...opts.palette.secondary }

  return opts
}

const theme = {
  themeId,
  darkThemeId,
  lightThemeId,
  getThemeSetup,
  getThemeDefinition
}

export {
  themeId,
  darkThemeId,
  lightThemeId,
  getThemeSetup,
  getThemeDefinition
}

export default theme
