import 'onyx-common/browser-shims'
import 'onyx-common/prototype-shims'
import React, { createRef } from 'react'
import ReactDOM from 'onyx-common/react-dom'

import isDefined from 'onyx-common/isDefined'
import createOrGetAppRoot from 'onyx-common/createOrGetAppRoot'
import createPreheatEntree from 'onyx-common/createPreheatEntree'

const bootstrapPeApp = (props) => {
  const {
    rootId = 'onyx-app-pe-root',
    config,
    recipes,
    appComponent: App
  } = props

  const rootRef = createRef()

  const render = Component => {
    const onRenderStartEvent = new CustomEvent('onyx.onRenderStart')
    window.dispatchEvent(onRenderStartEvent)

    ReactDOM.render(<Component ref={rootRef} />, document.getElementById(rootId), () => {
      const onRenderFinishEvent = new CustomEvent('onyx.onRenderFinish', { details: { rootRef } })
      window.dispatchEvent(onRenderFinishEvent)
    })
  }

  const runOnyx = () => render(App)

  window.Onyx = {
    start: () => runOnyx(),
    rootRef
  }

  const preheatPayload = {
    config,
    recipes
  }

  const preheatEntree = createPreheatEntree(preheatPayload)
  let preheated = false
  const skipOnyxPreheatEntree = !!window.skipOnyxPreheatEntree

  // if app subscribes to onOnyxReady we let it control when to render
  let loaded = false
  const startOrDefer = () => {
    if (loaded) return
    if (!skipOnyxPreheatEntree && !preheated) {
      const onPreheatStartEvent = new CustomEvent('onyx.onPreheatStart', { details: { rootRef } })
      window.dispatchEvent(onPreheatStartEvent)
      preheatEntree()
      preheated = true
    }

    createOrGetAppRoot(rootId)
    if (isDefined(window.onOnyxReady)) window.onOnyxReady(window.Onyx.start)
    else window.Onyx.start()

    loaded = true
  }

  if (!document.body) {
  // preheatEntree only runs if we're loaded in head.  The intent is to hide lz elements before they ever render
    if (!skipOnyxPreheatEntree) {
      preheated = true
      preheatEntree()
    }
  }

  document.addEventListener('readystatechange', event => {
    const readyState = event.target.readyState
    if (readyState === 'interactive' || readyState === 'complete') startOrDefer()
  })

  window.Onyx.preheated = preheated
}

export default bootstrapPeApp
