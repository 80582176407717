import appWindowHelpers from 'onyx-common/appWindowHelpers'
import isFunction from 'onyx-common/isFunction'

const generateAppWindowHelpers = ({ config }) => {
  const helpers = {
    ...appWindowHelpers,
    config
  }

  if (isFunction(config?.augmentFetchPayload)) {
    helpers.fetchWrap = (payload) => helpers._fetchWrap(config.augmentFetchPayload(payload, helpers))
    helpers.fetchWrapAndForget = (payload) => helpers._fetchWrapAndForget(config.augmentFetchPayload(payload, helpers))
  }

  return helpers
}

export default generateAppWindowHelpers
