import baseConfig from './config.base'

const config = {
  ...baseConfig
}

config.augmentFetchPayload = (payload, helpers) => {
  const csrfToken = helpers.document.querySelector("meta[name='csrf-token']").getAttribute('content')
  let finalPayload = {
    ...payload,
    headers: {
      ...payload?.headers,
      'x-csrf-token': csrfToken
    }
  }

  try {
    const username = helpers.document.querySelector("meta[name='onyx-username']").getAttribute('content')
    const onxyToken = helpers.document.querySelector("meta[name='onyx-token']").getAttribute('content')

    finalPayload = {
      ...finalPayload,
      headers: {
        ...finalPayload?.headers,
        'ONYX-TOKEN': onxyToken,
        'USER-NAME': username
      }
    }
  } catch (e) {
    // expected error state
  }

  return finalPayload
}

var a = document.createElement('a')
a.href = document.currentScript.src

const baseUrl = a.origin

config.i18n = {
  baseUrl
}

export default config
