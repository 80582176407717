import ifPromise from 'onyx-common/ifPromise'

const ZEventsApi = ({ prototype }) => {
  prototype._deleteEventManagerAssignment = function ({ userId, id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/event_manager_assignments/${id}`),
      method: 'DELETE',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }
  prototype.deleteEventManagerAssignment = function (payload) {
    const raw = ifPromise(payload, () => this._deleteEventManagerAssignment(payload))
    return raw
      .then(res => Promise.resolve(this.normalizeAndCamelize(res)))
      .catch(error => {
        Promise.reject(Error('loginError', error))
      })
  }

  prototype._getEventManagerAssignments = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${id}/event_manager_assignments`),
      method: 'GET',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }
  prototype.getEventManagerAssignments = function (payload) {
    const raw = ifPromise(payload, () => this._getEventManagerAssignments(payload))
    return raw
      .then(res => Promise.resolve(this.normalizeAndCamelize(res)))
      .catch(error => {
        Promise.reject(Error('loginError', error))
      })
  }
}

export default ZEventsApi
