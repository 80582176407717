import qs from 'onyx-common/qs'

const decorateLocation = location => {
  const { search, hash, pathname } = location

  const queryParams = search ? qs.parse(search) : {}
  const hashParams = hash ? qs.parse(hash) : {}

  const url = [pathname, search, hash].join('')

  return {
    ...location,
    hashParams,
    queryParams,
    url
  }
}

export default decorateLocation
