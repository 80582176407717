import isFunction from 'onyx-common/isFunction'

const getQueriesFromApps = ({ apps, queriesPayload, queriesOverride }) => {
  const arr = apps.map(a => a.getQueries)
  const queries = arr
    .map(func => func(queriesPayload))
    .reduce((accumulator, obj, key) => {
      return {
        ...accumulator,
        ...obj
      }
    }, {})

  // if no id is specified, assume key
  Object.keys(queries).map(key => {
    if (!('id' in queries[key])) queries[key].id = key
  })

  const finalQueries = isFunction(queriesOverride) ? queriesOverride(queries, queriesPayload) : queries

  return finalQueries
}

export default getQueriesFromApps
