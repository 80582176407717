import ifPromise from 'onyx-common/ifPromise'

const ZEventsApiAddons = ({ prototype }) => {
  prototype.normalizeAddons = function (_data) {
    const data = this.normalizeData(_data)

    const ret = {
      id: data.id,
      name: data.name,
      capacity: data.capacity,
      price: data.price,
      description: data.description
    }

    return ret
  }

  prototype._createAddon = function ({ eventId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/addons`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} Addon
   * @property {number} id - addon id
   * @property {string} name - addon name
   * @property {number} capacity - number of addons available
   * @property {string} description - more details on addon
   * @property {number} price - addon price
   */

  /**
   * @function createAddon
   * @param {object} payload -  Addon payload
   * @param {number} payload.eventId - event id
   * @param {string} payload.name - name of addon
   * @param {number} payload.capacity - number of addon available
   * @param {number} payload.price - price of addon
   * @param {string} payload.description - more detailed description of addon
   * @returns {Addon} - Addon instance
   * @example
   *
   * createAddon({
   *   eventId: 10,
   *   name: 'VIP',
   *   capacity: 1000,
   *   price: 500,
   *   description: "Special backstage pass"
   * })
   */

  prototype.createAddon = function (payload) {
    const {
      eventId,
      name,
      capacity,
      price,
      description
    } = payload

    const finalPayload = {
      eventId,
      name,
      capacity,
      price,
      description
    }

    const raw = ifPromise(payload, () => this._createAddon(finalPayload))
    return raw
      .then(res => this.normalizeAddons(res.data))
      .catch(error => this.onError('createAddon', error))
  }

  prototype._deleteAddon = function ({ eventId, id }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/addons/${id}`),
      method: 'DELETE',
      requestType: 'json'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function deleteAddon
   * @param {object} payload - The addon payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.id - addon id
   * @returns {void}
   * @example
   *
   * deleteAddon({
   *   eventId: 100,
   *   id: 3838
   * })
   */

  prototype.deleteAddon = function (payload) {
    const finalPayload = {
      eventId: payload.eventId,
      id: payload.id
    }

    const raw = ifPromise(payload, () => this._deleteAddon(finalPayload))
    return raw
      .catch(error => this.onError('deleteAddon', error))
  }

  prototype._getAddons = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${id}/addons`),
      method: 'GET',
      requestType: 'json',
      data: rest
    }

    return this.fetchWrap(payload)
  }

  /**
   * @typedef {object} getAddonsSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} getAddonsReturn
   * @property {Addon[]} entries - list of addons returned
   * @property {getAddonsSummary} listSummary - list summary object
   * @property {object} criteriaSummary - criteria summary object (empty)
   */

  /**
   * @function getAddons
   * @param {object} payload - The payload
   * @param {number} payload.id - event id
   * @param {number} [payload.page=1] - result set page
   * @returns {getAddonsReturn} - getAddonsReturn
   * @example
   *
   * getAddons({
   *   id: 10,
   *   page: 2
   * })
   */

  prototype.getAddons = function (payload) {
    const finalPayload = {
      id: payload.id,
      page: payload?.page || 1
    }

    const raw = ifPromise(payload, () => this._getAddons(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeAddons))
      .catch(error => this.onError('getAddons', error))
  }

  prototype._showAddon = function ({ eventId, id }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/addons/${id}`),
      method: 'GET'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function showAddon
   * @param {object} payload - Addon payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.id - addon id
   * @returns {Addon} - Addon instance
   * @example
   *
   * showAddon({
   *   eventId: 10,
   *   id: 100
   * })
   */

  prototype.showAddon = function (payload) {
    const finalPayload = {
      eventId: payload.eventId,
      id: payload.id
    }
    const raw = ifPromise(payload, () => this._showAddon(finalPayload))
    return raw
      .then(res => this.normalizeAddons(res.data))
      .catch(error => this.onError('showAddon', error))
  }

  prototype._updateAddon = function ({ eventId, id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/addons/${id}`),
      method: 'PUT',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function updateAddon
   * @param {object} payload -  Addon payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.id - addon id
   * @param {string} payload.name - name of addon
   * @param {number} payload.capacity - number of addon available
   * @param {number} payload.price - price of addon
   * @param {string} payload.description - more detailed description of addon
   * @returns {Addon} - Addon instance
   * @example
   *
   * updateAddon({
   *   eventId: 10,
   *   id: 100,
   *   name: 'VIP',
   *   capacity: 1000,
   *   price: 500,
   *   description: "Special backstage pass"
   * })
   */

  prototype.updateAddon = function (payload) {
    const finalPayload = {
      eventId: payload.eventId,
      id: payload.id,
      name: payload.name,
      description: payload.description,
      price: payload.price,
      capacity: payload.capacity
    }

    const raw = ifPromise(payload, () => this._updateAddon(finalPayload))
    return raw
      .then(res => this.normalizeAddons(res.data))
      .catch(error => this.onError('updateAddon', error))
  }
}

export default ZEventsApiAddons
