import assign from 'onyx-common/assign'

import {
  getThemeSetup,
  lightThemeId,
  darkThemeId,
  getThemeDefinition as getBaseThemeDefinition
} from '../Vibe'

import resetDarkTheme from '../utils/resetDarkTheme'

const themeId = 'VibeDark'

const getThemeDefinition = (themePayload) => {
  const baseTheme = getBaseThemeDefinition(themePayload)
  const resetDark = resetDarkTheme(baseTheme)

  const dark = {
    palette: {
      background: {
        footerTop: '#565c63',
        footerBtm: '#3d4249'
      },
      shadow: {
        default: '#535354'
      },
      border: {
        default: '#535354'
      },
      primary: {
        main: '#4c79b7'
      },
      primaryAlt: {
        main: '#fff'
      },
      secondary: {
        main: '#838990'
      },
      secondaryAlt: {
        main: '#4c79b7'
      },
      tertiary: {
        main: '#838990'
      }
    }
  }

  const opts = assign(resetDark, dark)

  // opts.shadows[0] = 'none'
  // opts.shadows[1] = '0px 2px 1px -1px rgba(192,192,192,0.2), 0px 1px 1px 0px rgba(192,192,192,0.14), 0px 1px 3px 0px rgba(192,192,192,0.12)'

  console.log(opts)
  return opts
}

const theme = {
  themeId,
  lightThemeId,
  darkThemeId,
  getThemeSetup,
  getThemeDefinition
}

export {
  themeId,
  lightThemeId,
  darkThemeId,
  getThemeSetup,
  getThemeDefinition
}

export default theme
