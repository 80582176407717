import Context from './context'
import RootRefProvider from './RootRefProvider'
import RootRefConsumer from './RootRefConsumer'
import withRootRef from './withRootRef'
import useRootRef from './useRootRef'

const RootRef = Context

export {
  RootRefProvider,
  RootRefConsumer,
  withRootRef,
  RootRef,
  useRootRef
}

export default RootRefProvider
