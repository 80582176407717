import noop from 'onyx-common/noop'
import generateIngredient from 'onyx-common/generateIngredient'
import generateRecipePlaygroundComponent from 'onyx-common/generateRecipePlaygroundComponent'
import RecipePlaygroundView from 'onyx-common/RecipePlaygroundView'

const defaults = {
  preheat: (recipePayload) => {
    const { availableHydratedIngredients } = recipePayload

    availableHydratedIngredients.forEach(ingredient => {
      ingredient.preheat()
    })
  },
  preheatPlayground: (recipePayload) => {
    const { availableHydratedIngredientsPlayground } = recipePayload

    availableHydratedIngredientsPlayground.forEach(ingredient => {
      ingredient.preheatPlayground()
    })
  },
  prep: noop,
  prepPlayground: noop,
  bake: (recipePayload) => {
    const { availableHydratedIngredients, onBakeComplete } = recipePayload

    availableHydratedIngredients.forEach(ingredient => {
      ingredient.prep()
      ingredient.add()
    })

    onBakeComplete(recipePayload)
  },
  bakePlayground: (recipePayload) => {
    const { availableHydratedIngredientsPlayground, onBakeCompletePlayground } = recipePayload

    availableHydratedIngredientsPlayground.forEach(ingredient => {
      ingredient.prepPlayground()
      ingredient.addPlayground()
    })

    onBakeCompletePlayground(recipePayload)
  },
  bakeOnly: (recipePayload) => {
    const { availableHydratedIngredients, onBakeComplete } = recipePayload

    availableHydratedIngredients.forEach(ingredient => {
      ingredient.add()
    })

    onBakeComplete(recipePayload)
  },
  bakeOnlyPlayground: (recipePayload) => {
    const { availableHydratedIngredientsPlayground, onBakeCompletePlayground } = recipePayload

    availableHydratedIngredientsPlayground.forEach(ingredient => {
      ingredient.addPlayground()
    })

    onBakeCompletePlayground(recipePayload)
  },
  hydrateIngredients: (recipePayload) => {
    const {
      _filterHydratedIngredients,
      _filterHydratedIngredientsPlayground,
      _preheat,
      _preheatPlayground,
      _bake,
      _bakePlayground,
      _bakeOnly,
      _bakeOnlyPlayground,
      _prep,
      _prepPlayground,
      _shouldBake,
      _shouldBakePlayground,
      ingredients,
      ...ingredientPayload
    } = recipePayload

    return ingredients.map(ingredient => generateIngredient(ingredient)(ingredientPayload))
  },
  filterHydratedIngredients: (recipePayload) => {
    const { hydratedIngredients } = recipePayload
    return hydratedIngredients.filter(ingredient => ingredient.shouldAdd())
  },
  filterHydratedIngredientsPlayground: (recipePayload) => {
    const { hydratedIngredients } = recipePayload
    return hydratedIngredients.filter(ingredient => ingredient.shouldAddPlayground())
  },
  shouldBake: () => false,
  shouldBakePlayground: () => true,
  onBakeComplete: (recipePayload) => {
    const { availableHydratedIngredients } = recipePayload

    availableHydratedIngredients.forEach(ingredient => {
      ingredient.onBakeComplete()
    })
  },
  onBakeCompletePlayground: (recipePayload) => {
    const { availableHydratedIngredients } = recipePayload

    availableHydratedIngredients.forEach(ingredient => {
      ingredient.onBakeCompletePlayground()
    })
  },
  ingredients: [],
  PlaygroundViewComponent: RecipePlaygroundView
}

const generateRecipe = recipe => payload => {
  const tmpRecipe = {
    ...defaults,
    ...recipe
  }

  const tmpPayload = {
    ...payload,
    id: tmpRecipe.id,
    ingredients: tmpRecipe.ingredients,
    _filterHydratedIngredients: defaults.filterHydratedIngredients,
    _filterHydratedIngredientsPlayground: defaults.filterHydratedIngredientsPlayground,
    _preheat: defaults.preheat,
    _preheatPlayground: defaults.preheatPlayground,
    _bake: defaults.bake,
    _bakePlayground: defaults.bakePlayground,
    _bakeOnly: defaults.bakeOnly,
    _bakeOnlyPlayground: defaults.bakeOnlyPlayground,
    _hydrateIngredients: defaults.hydrateIngredients,
    _prep: defaults.prep,
    _prepPlayground: defaults.prepPlayground,
    _shouldBake: defaults.shouldBake,
    _shouldBakePlayground: defaults.shouldBakePlayground,
    _onBakeComplete: defaults.onBakeComplete,
    _onBakeCompletePlayground: defaults.onBakeCompletePlayground
  }

  const hydratedIngredients = tmpRecipe.hydrateIngredients(tmpPayload)
  tmpPayload.hydratedIngredients = hydratedIngredients
  tmpPayload.availableHydratedIngredients = tmpRecipe.filterHydratedIngredients(tmpPayload)
  tmpPayload.availableHydratedIngredientsPlayground = tmpRecipe.filterHydratedIngredientsPlayground(tmpPayload)

  const ret = {
    id: tmpRecipe.id,
    ingredients: tmpRecipe.ingredients,
    PlaygroundViewComponent: tmpRecipe.PlaygroundViewComponent,
    hydratedIngredients
  }

  ret.PlaygroundComponent = generateRecipePlaygroundComponent(ret.PlaygroundViewComponent)
  ret.preheat = () => tmpRecipe.preheat(tmpPayload)
  ret.preheatPlayground = () => tmpRecipe.preheatPlayground({ ...tmpPayload, preheat: ret.preheat })
  ret.prep = () => tmpRecipe.prep(tmpPayload)
  ret.prepPlayground = () => tmpRecipe.prepPlayground({ ...tmpPayload, prep: ret.prep })
  ret.onBakeComplete = () => tmpRecipe.onBakeComplete(tmpPayload)
  ret.onBakeCompletePlayground = () => tmpRecipe.onBakeCompletePlayground({ ...tmpPayload, onBakeComplete: ret.onBakeComplete })
  ret.bake = () => tmpRecipe.bake({ ...tmpPayload, onBakeComplete: ret.onBakeComplete })
  ret.bakePlayground = () => tmpRecipe.bakePlayground({ ...tmpPayload, bake: ret.bake, onBakeCompletePlayground: ret.onBakeCompletePlayground })
  ret.bakeOnly = () => tmpRecipe.bakeOnly({ ...tmpPayload, onBakeComplete: ret.onBakeComplete })
  ret.bakeOnlyPlayground = () => tmpRecipe.bakeOnly({ ...tmpPayload, bakeOnly: ret.bakeOnly, onBakeCompletePlayground: ret.onBakeCompletePlayground })
  ret.shouldBake = () => tmpRecipe.shouldBake(tmpPayload)
  ret.shouldBakePlayground = () => tmpRecipe.shouldBakePlayground({ ...tmpPayload, shouldBake: ret.shouldBake })

  return ret
}

export default generateRecipe
