import getVideoId from 'onyx-common/get-video-id'
import createEmbedCode from 'onyx-common/createEmbedCode'
import generateVideoEmbedUrl from 'onyx-common/generateVideoEmbedUrl'

const parseEmbedCode = (htmlBlock, providerType) => {
  let ret = {}
  try {
    ret = getVideoId(htmlBlock)
  } catch (e) {
    ret.id = null
  }

  if (ret.id === null) {
    return {
      id: undefined,
      service: providerType,
      embedCode: htmlBlock,
      embedUrl: undefined,
      _orig: htmlBlock
    }
  }

  return {
    ...ret,
    _orig: htmlBlock,
    embedUrl: generateVideoEmbedUrl(ret.id, ret.service),
    embedCode: createEmbedCode(ret.id, ret.service, true)
  }
}

export default parseEmbedCode
