const preheat = (payload) => {
  const { injectGlobalJss } = payload

  // CSS specificity hack, chaining a classname to itself makes it more specific and take precedent w/o having to use !important.  Like a maniac.
  injectGlobalJss({
    'body.training_users_viewer .vibex-container.vibex-container': {
      display: 'none'
    },
    'body.training_users_viewer.training_users_viewer .header-nav-mobile .submenu': {
      display: 'none !important'
    },
    'body.training_users_viewer.training_users_viewer.custom-background textarea': {
      border: 0
    },
    '.ui-variant-default.ui-variant-default': {
      '& p, & #contact-details-panel #contact-details, & .form-control': {
        fontSize: 'unset !important',
        color: 'unset'
      }
    },
    '#main': {
      minHeight: 400
    },
    '#lmsViewer-lz': {
      paddingBottom: 24
    }
  })

  injectGlobalJss({
    'body.training_users_viewer.training_users_viewer.training_users_viewer': {
      '& .header-nav-mobile': {
        display: 'none'
      },
      '& #page': {
        marginTop: '0 !important',
        padding: '0 !important'
      },
      '& #main': {
        margin: '0 !important',
        padding: '0 !important'
      }
    }
  }, { media: '(max-width: 992px)' })

  injectGlobalJss({
    'body.training_users_viewer.training_users_viewer.training_users_viewer': {
      '& .mobile-flex-container': {
        marginTop: '0 !important'
      },
      '& .header-nav-mobile': {
        position: 'relative'
      },
      '& #main': {
        paddingTop: 25
      }
    }
  }, { media: '(min-width: 992px)' })
}

const prep = (payload) => {
  const { $ } = payload

  $('#spinner-container').remove()
  $('<div id="lmsViewer-lz"></div>').insertBefore('.vibex-container')
  $('.vibex-container').empty()
}

const getItem = (payload) => {
  const { instanceId } = payload

  const item = {
    instanceId,
    paramPrefix: '',
    courseId: payload.location.hashParams?.courseId,
    component: 'LMSViewer',
    lzSelector: '#lmsViewer-lz',
    lzCreateIfMissing: false,
    adaptorId: 'vibeCoreAdaptor'
  }

  return item
}

const shouldAdd = (payload) => {
  const { location: { pathname } } = payload

  return pathname === '/pyr_core/training_users/viewer'
}

const getItemPlayground = (payload) => {
  const baseItem = payload._getItemPlayground(payload)

  if (payload.isUndefined(baseItem?.courseId)) baseItem.courseId = 1

  const item = {
    ...baseItem,
    adaptorId: 'playgroundAdaptor'
  }

  return item
}

export default {
  id: 'stup.lmsViewer',
  preheat,
  prep,
  getItem,
  shouldAdd,
  getItemPlayground
}
