const createOrGetAppRoot = id => {
  let root = document.getElementById(id)
  if (root) return root

  root = document.createElement('div')
  root.id = id
  document.body.appendChild(root)

  return root
}

export default createOrGetAppRoot
