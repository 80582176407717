
import urlParse from 'onyx-common/url-parse'

const urlToRoute = url => {
  const parsed = urlParse(url)

  return {
    pathname: parsed.pathname,
    search: parsed.query,
    hash: parsed.hash
  }
}

export default urlToRoute
