import ifPromise from 'onyx-common/ifPromise'
import hasKey from 'onyx-common/hasKey'

const ZEventsApiEventAttendees = ({ prototype }) => {
  prototype.normalizeEventAttendee = function (_data) {
    const data = this.normalizeData(_data)
    const mapProps = {
      name: 'viewerName',
      avatar_url: 'avatarUrl',
      is_blocked: ['isBlocked', val => !!val],
      is_banned: ['isBanned', val => !!val],
      user_id: ['userId', val => parseInt(val)]
    }

    const mapRt = this.filterAndMapProps(data, mapProps)

    const eventObj = this.normalizeEvent(data.event)
    const ret = {
      ...eventObj,
      ...mapRt
    }

    ret.isEventRoomMemberEventHost = ret.userId === ret.hostId

    if (hasKey(data, 'zoom_authorization')) {
      ret.zoomAuthorization = {
        zoomSignature: data.zoom_authorization.signature,
        zoomMeetingNumber: data.zoom_authorization.meeting_number,
        zoomApiKey: data.zoom_authorization.api_key,
        zoomUserName: data.zoom_authorization.username,
        zoomPassword: data.zoom_authorization.password,
        zoomEmail: data.zoom_authorization.email,
        zoomRole: data.zoom_authorization.role,
        zoomLeaveUrl: this.getSiteUrl('/#view=event&eventId=123')
      }
    }

    if (hasKey(data, 'jitsi_authorization')) {
      ret.jitsiAuthorization = {
        jwt: data.jitsi_authorization.jwt,
        roomName: data.jitsi_authorization.room,
        domain: '8x8.vc'
      }
    }
    return ret
  }

  /**
   * @typedef {object} EventAttendee
   * @property {number} userId - user id
   * @property {string} type - type
   * @property {string} url -  url to link to
   * @property {number} name - name
   * @property {string} avatarUrl - url
   * @property {boolean} isBanned - is the user banned from chat?
   * @property {boolean} isBlocked - is the user blocked from the event entirely?
   */

  prototype._banEventAttendee = function ({ eventId, userId }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/attendees/${userId}/ban`),
      method: 'POST'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function banEventAttendee
   * @param {object} payload - The payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.userId - userId to ban
   * @returns {void}
   * @example
   *
   * banEventAttendee({
   *   eventId: 81818
   *   userId: 222
   * })
   */
  prototype.banEventAttendee = function (payload) {
    const finalPayload = {
      eventId: parseInt(payload.eventId),
      userId: parseInt(payload.userId)
    }

    const raw = ifPromise(payload, () => this._banEventAttendee(finalPayload))
    return raw
      .catch(error => this.onError('banEventAttendee', error))
  }

  prototype._blockEventAttendee = function ({ eventId, userId }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/attendees/${userId}/block`),
      method: 'POST'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function blockEventAttendee
   * @param {object} payload - The payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.userId - user id
   * @returns {void}
   * @example
   *
   * blockEventAttendee({
   *   eventId: 1127,
   *   userId: 3
   * })
   */
  prototype.blockEventAttendee = function (payload) {
    const finalPayload = {
      eventId: parseInt(payload.eventId),
      userId: parseInt(payload.userId)
    }

    const raw = ifPromise(payload, () => this._blockEventAttendee(finalPayload))
    return raw
      .catch(error => this.onError('blockEventAttendee', error))
  }

  prototype._muteEventAttendee = function ({ eventId, userId }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/attendees/${userId}/mute`),
      method: 'POST'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function muteEventAttendee
   * @param {object} payload - The payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.userId - user id
   * @returns {void}
   * @example
   *
   * muteEventAttendee({
   *   eventId: 1127,
   *   userId: 3
   * })
   */
  prototype.muteEventAttendee = function (payload) {
    const finalPayload = {
      eventId: parseInt(payload.eventId),
      userId: parseInt(payload.userId)
    }

    const raw = ifPromise(payload, () => this._muteEventAttendee(finalPayload))
    return raw
      .catch(error => this.onError('muteEventAttendee', error))
  }

  prototype._unbanEventAttendee = function ({ eventId, userId }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/attendees/${userId}/unban`),
      method: 'POST'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function unbanEventAttendee
   * @param {object} payload - The payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.userId - user id
   * @returns {void}
   * @example
   *
   * unbanEventAttendee({
   *   eventId: 1127,
   *   userId: 1818
   * })
   */
  prototype.unbanEventAttendee = function (payload) {
    const finalPayload = {
      eventId: parseInt(payload.eventId),
      userId: parseInt(payload.userId)
    }

    const raw = ifPromise(payload, () => this._unbanEventAttendee(finalPayload))
    return raw
      .catch(error => this.onError('unbanEventAttendee', error))
  }

  prototype._unblockEventAttendee = function ({ eventId, userId }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/attendees/${userId}/unblock`),
      method: 'POST'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function unblockEventAttendee
   * @param {object} payload - The payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.userId - user id
   * @returns {void}
   * @example
   *
   * unblockEventAttendee({
   *   eventId: 1127,
   *   attendeeId: 1818
   * })
   */
  prototype.unblockEventAttendee = function (payload) {
    const finalPayload = {
      eventId: parseInt(payload.eventId),
      userId: parseInt(payload.userId)
    }

    const raw = ifPromise(payload, () => this._unblockEventAttendee(finalPayload))
    return raw
      .catch(error => this.onError('unblockEventAttendee', error))
  }

  prototype._unmuteEventAttendee = function ({ eventId, userId }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/attendees/${userId}/unmute`),
      method: 'POST'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function unmuteEventAttendee
   * @param {object} payload - The payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.userId - user id
   * @returns {void}
   * @example
   *
   * unmuteEventAttendee({
   *   eventId: 1127,
   *   userId: 2828
   * })
   */
  prototype.unmuteEventAttendee = function (payload) {
    const finalPayload = {
      eventId: parseInt(payload.eventId),
      userId: parseInt(payload.userId)
    }

    const raw = ifPromise(payload, () => this._unmuteEventAttendee(finalPayload))
    return raw
      .catch(error => this.onError('unmuteEventAttendee', error))
  }
  prototype._getEventAttendees = function ({ eventId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/attendees`),
      method: 'GET',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} getEventAttendeesSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} getEventAttendeesReturn
   * @property {EventAttendee[]} eventWatches - list of users returned
   * @property {getEventAttendeesSummary} listSummary - list summary object
   * @property {object} criteriaSummary - list summary object (empty)
   */

  /**
   * @function getEventAttendees
   * @param {object} payload - The payload
   * @param {number} payload.eventId - event ID
   * @param {number} [payload.page=1] - page
   * @returns {getEventAttendeesReturn} - attendee return
   * @example
   *
   * getEventAttendees({
   *   eventId: 1127,
   *   page: 2
   * })
   */
  prototype.getEventAttendees = function (payload) {
    const {
      eventId,
      page = 1
    } = payload

    const finalPayload = {
      eventId: parseInt(eventId),
      page: parseInt(page)
    }

    const raw = ifPromise(payload, () => this._getEventAttendees(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeEventAttendee))
      .catch(error => this.onError('getEventAttendees', error))
  }

  prototype._postEventAttendee = function ({ eventId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/attendees`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
      .catch(error => {
        if (error.data.message === 'forbidden') {
          this.onError('_postEventAttendee', error)
        }
      })
  }

  /**
   * @typedef {object} postEventAttendeeSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} postEventAttendeeReturn
   * @property {EventAttendee[]} eventWatches - list of users returned
   * @property {postEventAttendeeSummary} listSummary - list summary object
   * @property {object} criteriaSummary - list summary object (empty)
   */

  /**
   * @function postEventAttendee
   * @param {object} payload - The payload
   * @param {number} payload.eventId - event ID
   * @param {number} [payload.page=1] - page
   * @returns {postEventAttendeeReturn} - attendee return
   * @example
   *
   * postEventAttendee({
   *  eventId: 1127
   * })
   */
  prototype.postEventAttendee = function (payload) {
    const {
      eventId
    } = payload

    const finalPayload = {
      eventId: parseInt(eventId)
    }
    const raw = ifPromise(payload, () => this._postEventAttendee(finalPayload))
    return raw
      .then(res => this.normalizeEventAttendee(res.data))
      .catch(error => this.onError('postEventAttendee', error))
  }

  prototype.normalizeAttendeeCount = function (data) {
    return parseInt(data.attendee_count)
  }

  prototype._getEventAttendeeCount = function ({ eventId }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/attendee_count`),
      method: 'GET'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function getEventAttendeeCount
   * @param {object} payload - The payload
   * @param {number} payload.eventId - event id
   * @returns {number} - the current attendee count for the event
   * @example
   *
   * getEventAttendeeCount({
   *   eventId: 1127
   * })
   */
  prototype.getEventAttendeeCount = function (payload) {
    const finalPayload = {
      eventId: parseInt(payload.eventId)
    }

    const raw = ifPromise(payload, () => this._getEventAttendeeCount(finalPayload))
    return raw
      .then(res => this.normalizeAttendeeCount(res.data))
      .catch(error => this.onError('getEventAttendeeCount', error))
  }
}

export default ZEventsApiEventAttendees
