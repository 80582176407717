/* eslint-disable import/first */
const onyxReplacementUrl = document.documentElement.getAttribute('data-onyx-replacement-url')
const src = onyxReplacementUrl || document.currentScript.src
const a = document.createElement('a')
a.href = src
const baseUrl = a.origin + '/'
// eslint-disable-next-line no-undef
__webpack_public_path__ = baseUrl

import config from './config'
import recipes from './recipes'
import pe from 'onyx-scenes/pe'
import generatePeAppComponent from 'onyx-common/generatePeAppComponent'
import bootstrapPeApp from 'onyx-common/bootstrapPeApp'

const apps = [pe]
const appPayload = {
  config,
  recipes,
  apps
}

const appComponent = generatePeAppComponent(appPayload)

const bootstrapPayload = {
  appComponent,
  config,
  recipes
}

bootstrapPeApp(bootstrapPayload)
