import ifPromise from 'onyx-common/ifPromise'
import hasKey from 'onyx-common/hasKey'

const ZEventsApiPrivacySettings = ({ prototype }) => {
  prototype.normalizePrivacySettings = function (_data) {
    const data = this.normalizeData(_data)

    const ret = {
      showFollowees: !!data?.show_followees,
      showEventsAttending: !!data?.show_events_attending,
      showEventsAttended: !!data?.show_events_attended,
      showInUserSearch: !!data?.show_in_user_search,
      showInSpeakerSearch: !!data?.show_in_speaker_search
    }

    return ret
  }

  prototype._createPrivacySettings = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${id}/privacy_settings`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} PrivacySettings
   * @property {boolean} showFollowees - show followees?
   * @property {boolean} showEventsAttending - show events attending?
   * @property {boolean} showEventsAttended - show events previously attended?
   * @property {boolean} showInUserSearch - should user show in user search results?
   * @property {boolean} showInSpeakerSearch - should user show in speaker search results?
   */

  /**
   * @function createPrivacySettings
   * @param {object} payload - the payload -- should include 1 or more privacy settings
   * @param {string} [payload.id='me'] - user id to assign preferences
   * @param {boolean} [payload.showFollowees] - show followees?
   * @param {boolean} [payload.showEventsAttending] - show events attending?
   * @param {boolean} [payload.showEventsAttended] - show events previously attended?
   * @param {boolean} [payload.showInUserSearch] - should user show in user search results?
   * @param {boolean} [payload.showInSpeakerSearch] - should user show in speaker search results?
   * @returns {PrivacySettings} - Privacy Settings object
   * @example
   *
   * createPrivacySettings({
   *   showFollowees: true
   * })
   */
  prototype.createPrivacySettings = function (payload) {
    const finalPayload = {
      id: this.normalizeUserId(payload.id)
    }

    if (hasKey(payload, 'showFollowees')) finalPayload.show_followees = !!payload.showFollowees
    if (hasKey(payload, 'showEventsAttending')) finalPayload.show_events_attending = !!payload.showEventsAttending
    if (hasKey(payload, 'showEventsAttended')) finalPayload.show_events_attended = !!payload.showEventsAttended
    if (hasKey(payload, 'showInUserSearch')) finalPayload.show_in_user_search = !!payload.showInUserSearch
    if (hasKey(payload, 'showInSpeakerSearch')) finalPayload.show_in_speaker_search = !!payload.showInSpeakerSearch

    const raw = ifPromise(payload, () => this._createPrivacySettings(finalPayload))
    return raw
      .then(res => this.normalizePrivacySettings(res.data))
      .catch(error => this.onError('createPrivacySettings', error))
  }

  prototype._getPrivacySettings = function ({ id }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${id}/privacy_settings`),
      method: 'GET'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function getPrivacySettings
   * @param {object} [payload] - the payload
   * @param {string} [payload.id='me'] - user id to assign privacy settings
   * @returns {PrivacySettings} - Privacy Settings object
   * @example
   *
   * getPrivacySettings()
   */

  prototype.getPrivacySettings = function (payload = {}) {
    const finalPayload = {
      id: this.normalizeUserId(payload?.userId)
    }
    const raw = ifPromise(payload, () => this._getPrivacySettings(finalPayload))
    return raw
      .then(res => this.normalizePrivacySettings(res.data))
      .catch(error => this.onError('getPrivacySettings', error))
  }
}

export default ZEventsApiPrivacySettings
