/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next'
import Backend from 'onyx-common/i18next-http-backend'
import LanguageDetector from 'onyx-common/i18next-browser-languagedetector'
import { initReactI18next } from 'onyx-common/react-i18next'
import isString from 'onyx-common/isString'

const initi18n = ({ i18n: i18nConfig = {} }) => {
  const { baseUrl = '' } = i18nConfig

  return i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .init({
      fallbackLng: 'en-US',
      backend: {
        loadPath: `${baseUrl}/locales/{{lng}}/{{ns}}.json`,
        crossDomain: true
      },
      load: 'currentOnly',
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        format: (value, format, lng) => {
          if (format === 'intlDate') {
            if (isString(value)) value = new Date(value)
            return new Intl.DateTimeFormat(lng).format(new Date(value))
          }

          return value
        }
      },
      ...i18nConfig
    })
}

export default initi18n
