import Context from './context'
import PropTypes from 'prop-types'
import React from 'react'

const RecipesProvider = ({ children, recipes }) => (
  <Context.Provider value={recipes}>{children}</Context.Provider>
)

RecipesProvider.propTypes = {
  recipes: PropTypes.array,
  children: PropTypes.node.isRequired
}

export default RecipesProvider
