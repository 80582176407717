import alpha from './alpha'
import deployed from './deployed'
import beta from './beta'

import generateRecipesIndex from 'onyx-common/generateRecipesIndex'

export default generateRecipesIndex([
  deployed,
  beta,
  alpha
])
