import createJss from 'onyx-common/createJss'

const jss = createJss()

const createStyleSheet = (styles, opts) => {
  const sheet = jss.createStyleSheet(styles, opts)

  return sheet
}

export default createStyleSheet
