import EventEmitter from 'onyx-common/eventemitter3'

const eventEmitter = new EventEmitter()

const on = (event, fn) => eventEmitter.on(event, fn)
const once = (event, fn) => eventEmitter.once(event, fn)
const off = (event, fn) => eventEmitter.off(event, fn)
const emit = (event, payload) => eventEmitter.emit(event, payload)

const emitter = {
  on,
  once,
  off,
  emit
}

Object.freeze(emitter)

export {
  on,
  once,
  off,
  emit,
  emitter
}

export default emitter
