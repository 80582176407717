import Context from './context'
import LayoutVarsProvider from './LayoutVarsProvider'
import LayoutVarsConsumer from './LayoutVarsConsumer'
import withLayoutVars from './withLayoutVars'
import useLayoutVars from './useLayoutVars'

const LayoutVarsContext = Context

export {
  LayoutVarsProvider,
  LayoutVarsConsumer,
  withLayoutVars,
  LayoutVarsContext,
  useLayoutVars
}

export default LayoutVarsProvider
