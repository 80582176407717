import React from 'react'
import Context from './context'
import PropTypes from 'prop-types'

const QueryHelperProvider = ({ children, queryHelper }) => (
  <Context.Provider value={queryHelper}>{children}</Context.Provider>
)

QueryHelperProvider.propTypes = {
  queryHelper: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default QueryHelperProvider
