import youtubeUrlParser from 'onyx-common/youtubeUrlParser'
import vimeoUrlParser from 'onyx-common/vimeoUrlParser'

const createEmbedCode = (url, provider, direct = false) => {
  const finalProvider = provider.trim().toLowerCase()
  switch (finalProvider) {
    case 'youtube': {
      const link = direct ? url : youtubeUrlParser(url)
      return `<iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/${link}"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                >
                </iframe>`
    }
    case 'vimeo': {
      const link = direct ? url : vimeoUrlParser(url)
      return `<iframe src="https://player.vimeo.com/video/${link}?h=66b8434e92&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`
    }
  }
}

export default createEmbedCode
