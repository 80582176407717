const preheat = (payload) => {
  const { injectGlobalJss } = payload
  // CSS specificity hack, chaining a classname to itself makes it more specific and take precedent w/o having to use !important.  Like a maniac.
  injectGlobalJss({
    html: {
      overflowX: 'hidden'
    },
    'body.training_users_index': {
      '& .vibex-container.vibex-container': {
        display: 'none'
      },
      '& h6': {
        color: 'inherit'
      }
    },
    '.header-nav-mobile': {
      maxWidth: '100vw'
    },
    'body.training_users_index.training_users_index .header-nav-mobile .submenu': {
      display: 'none !important'
    },
    '.ui-variant-default.ui-variant-default': {
      '& p, & #contact-details-panel #contact-details, & .form-control': {
        fontSize: 'unset !important',
        color: 'unset'
      }
    },
    '.custom-background input': {
      border: '0 !important'
    },
    '#main': {
      minHeight: 400
    },
    '.MuiTooltip-popper': {
      marginTop: -55
    },
    '#lms-lz': {
      paddingBottom: 24,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4
    }
  })
}

const prep = (payload) => {
  const { $ } = payload

  $('#spinner-container').remove()
  $('<div id="lms-lz" style=""></div>').insertBefore('.vibex-container')
  $('.vibex-container').empty()
}

const getItem = (payload) => {
  const { instanceId } = payload

  const item = {
    instanceId,
    component: 'LMSV2',
    lzSelector: '.training_users_index #lms-lz',
    lzCreateIfMissing: false,
    courseCardVariant: 4,
    stackEnabled: true,
    adaptorId: 'playgroundAdaptor'
  }

  return item
}

const shouldAdd = (payload) => {
  const { location: { pathname } } = payload

  return pathname === '/pyr_core/training_users'
}

export default {
  id: 'stup.lmsV2',
  preheat,
  prep,
  getItem,
  shouldAdd
}
