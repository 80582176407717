import normalizeDate from 'onyx-common/normalizeDate'

const isFutureDate = (test, compare = new Date()) => {
  const dt = normalizeDate(test)
  const dtCompare = normalizeDate(compare)

  return dt > dtCompare
}

export default isFutureDate
