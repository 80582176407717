import RubikBoldFont from './static/Rubik-Bold.ttf'
import RubikLightFont from './static/Rubik-Light.ttf'

const styles = {
  '@font-face': [
    {
      fontFamily: '"Rubik", sans-serif',
      fontWeight: 'light',
      fontStyle: 'normal',
      src: `url(${RubikLightFont}) format('woff2')`
    },
    {
      fontFamily: '"Rubik", sans-serif',
      fontWeight: 'bold',
      fontStyle: 'normal',
      src: `url(${RubikBoldFont}) format('woff2')`
    }
  ]
}

export default styles
