import fetchWrap from 'onyx-common/fetchWrap'
import camelize from 'onyx-common/camelize'
import ifPromise from 'onyx-common/ifPromise'
import delay from 'onyx-common/delay'
/*
All API calls are broken into (at least) 2 methods:
- A "public" method the app-land code we call from the store. (ie: login)
- A "private" method by the same name but with a "_" in front that actually handles the raw API call (ie: _login)

Why?
- We can test the API contract with the private method.
- With the public, we can manipulate the raw result we get back to make it easier to work with in our app itself
  - ie: we camelize the field names, filter down the fields we care about, etc.
*/
const VibeApiAuth = ({ prototype }) => {
  prototype._login = function ({ username, password }) {
    const payload = {
      url: this.getUrl('/api/v1/tokens'),
      method: 'POST',
      data: {
        username,
        password
      }
    }

    return fetchWrap(payload)
  }

  prototype.login = function (payload) {
    // this gives us the ability to pass in the response from _login directly if we want to
    const raw = ifPromise(payload, () => this._login(payload))

    // most public functions will have a "normalize" function inside of them
    // the login call here returns a TON of data (see `onyx-services/VibeApi/VibeApiAuth/responses/201-success`)
    // and a lot of it we don't care about, so we only want to pluck out what matters most to us
    // we also want to ensure any fields we do keep are camelized
    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)
      const { user } = camelized

      const { authenticationToken, spreeApiKey, ...restUser } = user

      const normalized = {
        user: restUser,
        authenticationToken,
        spreeToken: spreeApiKey
      }

      return {
        ...rest,
        data: normalized
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => Promise.reject(Error('loginError', error)))
  }

  prototype._logout = function ({ authenticationToken }) {
    const url = this.getUrl('/api/v1/tokens/{authenticationToken}')
    const payload = {
      url,
      method: 'DELETE',
      urlMerge: {
        authenticationToken
      }
    }

    return fetchWrap(payload)
  }

  prototype.logout = function (payload) {
    const raw = ifPromise(payload, () => this._logout(payload))

    // we don't care if logout fails on the server
    raw.catch(e => {})

    return Promise.resolve()
    /* return raw
      .then(() => Promise.resolve())
      .catch(() => Promise.reject(Error('logoutError'))) */
  }

  prototype._forgotPassword = function ({ username, email }) {
    /* let payload = {
      url: this.getUrl('/api/v1/forgot-password'),
      method: 'POST',
      data: {
        username,
        email
      }
    } */

    // return delay(() => Promise.reject(new Error('BADnEWS'), 2000))

    const payload = { response: { status: 200 }, data: {} }
    return delay(payload, 2000)

    // return fetchWrap(payload)
  }

  prototype.forgotPassword = function (payload) {
    const raw = ifPromise(payload, () => this._forgotPassword(payload))

    return raw
      .then(() => Promise.resolve())
      .catch(() => Promise.reject(Error('forgotPasswordError')))
  }

  prototype._forgotUsername = function ({ email }) {
    /* let payload = {
      url: this.getUrl('/api/v1/forgot-username'),
      method: 'POST',
      data: {
        email
      }
    } */

    // return delay(() => Promise.reject(new Error('BADnEWS'), 2000))

    const payload = { response: { status: 200 }, data: {} }
    return delay(payload, 2000)

    // return fetchWrap(payload)
  }

  prototype.forgotUsername = function (payload) {
    const raw = ifPromise(payload, () => this._forgotUsername(payload))

    return raw
      .then(() => Promise.resolve())
      .catch(() => Promise.reject(Error('forgotUsernameError')))
  }
}

export default VibeApiAuth
