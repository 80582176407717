import ifPromise from 'onyx-common/ifPromise'

const ZEventsApiEventWatches = ({ prototype }) => {
  prototype.normalizeEventWatch = function (_data) {
    const data = this.normalizeData(_data)

    const mapProps = {
      id: 'id',
      created_at: 'createdAt',
      event_id: 'eventId'
    }

    return this.filterAndMapProps(data, mapProps)
  }

  prototype._getEventWatches = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${id}/event_watches`),
      method: 'GET',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} getEventWatchesListSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} getEventWatchesReturn
   * @property {EventWatch[]} eventWatches - list of users returned
   * @property {getEventWatchesListSummary} listSummary - list summary object
   * @property {object} criteriaSummary - list summary object (empty)
   */

  /**
   * @function getEventWatches
   * @param {object} payload - The payload
   * @param {string} [payload.userId='me'] - userid to assign
   * @param {number} [payload.page=1] - page
   * @returns {getEventWatchesReturn}
   * @example
   *
   * getEventWatches({
   *   page: 2
   * })
   */

  prototype.getEventWatches = function (payload = {}) {
    const {
      userId,
      page = 1
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      page
    }

    const raw = ifPromise(payload, () => this._getEventWatches(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeEventWatch))
      .catch(error => this.onError('getEventWatches', error))
  }

  prototype._createEventWatch = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${id}/event_watches`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} EventWatch
   * @property {number} id - EventWatch join id
   * @property {number} eventId - event to watch
   */

  /**
   * @function createEventWatch
   * @param {object} payload - The event watch payload
   * @param {string} [payload.userId='me'] - userid to assign
   * @param {number} payload.eventId - event to watch
   * @returns {EventWatch}
   * @example
   *
   * createEventWatch({
   *   eventId: 100
   * })
   */

  prototype.createEventWatch = function (payload) {
    const {
      userId,
      eventId
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      event_id: eventId
    }

    const raw = ifPromise(payload, () => this._createEventWatch(finalPayload))
    return raw
      .then(res => this.normalizeEventWatch(res.data))
      .catch(error => this.onError('createEventWatch', error))
  }

  prototype._deleteEventWatch = function ({ userId, id }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/event_watches/${id}`),
      method: 'DELETE'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function deleteEventWatch
   * @param {object} payload - The event watch payload
   * @param {string} [payload.userId='me'] - userid to assign
   * @param {number} payload.id - event watch join id NOT event_id
   * @returns {void}
   * @example
   *
   * deleteEventWatch({
   *   id: 100
   * })
   */

  prototype.deleteEventWatch = function (payload) {
    const {
      userId,
      id
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      id
    }

    const raw = ifPromise(payload, () => this._deleteEventWatch(finalPayload))
    return raw
      .catch(error => this.onError('deleteEventWatch', error))
  }
}

export default ZEventsApiEventWatches
