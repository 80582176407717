import ifPromise from 'onyx-common/ifPromise'
import hasKey from 'onyx-common/hasKey'

const ZEventsApiUserPreferences = ({ prototype }) => {
  prototype.normalizeUserPreferences = function (_data) {
    const data = this.normalizeData(_data)

    const mapProps = {
      registered_event_start: 'registeredEventStart',
      registered_event_1_hour_before: 'registeredEvent1HourBefore',
      registered_event_24_hours_before: 'registeredEvent24HoursBefore',
      registered_event_is_live: 'registeredEventIsLive'
    }

    const interim = this.filterAndMapProps(data, mapProps)
    const finalRet = {}
    for (const prop in interim) {
      finalRet[prop] = !!interim[prop]
    }

    return finalRet
  }

  prototype._createUserPreferences = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${id}/user_preferences`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function createUserPreferences
   * @param {object} payload - the payload -- should include 1 or more preferences
   * @param {string} [payload.id='me'] - user id to assign preferences
   * @param {boolean} [payload.registeredEventStart] - notify user when event starts?
   * @param {boolean} [payload.registeredEvent1HourBefore] - notify user 1 hour before event starts?
   * @param {boolean} [payload.registeredEvent24HoursBefore] - notify 24 hours before?
   * @param {boolean} [payload.registeredEventIsLive] - notify right when event goes live?
   * @returns {UserPreferences} - UserPreferences object
   * @example
   *
   * createUserPreferences({
   *   registeredEventStart: true
   * })
   */
  prototype.createUserPreferences = function (payload) {
    const finalPayload = {
      id: this.normalizeUserId(payload.id)
    }

    if (hasKey(payload, 'registeredEventStart')) finalPayload.registered_event_start = !!payload.registeredEventStart
    if (hasKey(payload, 'registeredEvent1HourBefore')) finalPayload.registered_event_1_hour_before = !!payload.registeredEvent1HourBefore
    if (hasKey(payload, 'registeredEvent24HoursBefore')) finalPayload.registered_event_24_hours_before = !!payload.registeredEvent24HoursBefore
    if (hasKey(payload, 'registeredEventIsLive')) finalPayload.registered_event_is_live = !!payload.registeredEventIsLive
    // if (hasKey(payload, 'kwivrrMarketing')) finalPayload.kwivrrMarketing = !!payload.kwivrrMarketing

    const raw = ifPromise(payload, () => this._createUserPreferences(finalPayload))
    return raw
      .then(res => this.normalizeUserPreferences(res.data))
      .catch(error => this.onError('createUserPreference', error))
  }

  prototype._getUserPreferences = function ({ id }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${id}/user_preferences`),
      method: 'GET'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} UserPreferences
   * @property {boolean} registeredEventStart - notify user when event starts?
   * @property {boolean} registeredEvent1HourBefore - notify user 1 hour before event starts?
   * @property {boolean} registeredEvent24HoursBefore - notify 24 hours before?
   * @property {boolean} registeredEventIsLive - notify right when event goes live?
   */

  /**
   * @function getUserPreferences
   * @param {object} [payload] - the payload
   * @param {string} [payload.id='me'] - user id to assign preferences
   * @returns {UserPreferences} - UserPreferences object
   * @example
   *
   * getUserPreferences()
   */

  prototype.getUserPreferences = function (payload = {}) {
    const finalPayload = {
      id: this.normalizeUserId(payload?.userId)
    }
    const raw = ifPromise(payload, () => this._getUserPreferences(finalPayload))
    return raw
      .then(res => this.normalizeUserPreferences(res.data))
      .catch(error => this.onError('getUserPreferences', error))
  }
}

export default ZEventsApiUserPreferences
