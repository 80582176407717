import WebFont from 'onyx-common/webfontloader'

const themeId = 'Nsp'
const lightThemeId = 'Nsp'
const darkThemeId = 'NspDark'

const getThemeSetup = (themePayload) => () => {
  WebFont.load({
    google: {
      // for all icons, right now we only care about standard and outlined
      // families: ['Roboto:300,400,500,700', 'Material Icons', 'Material Icons Outlined', 'Material Icons Two Tone', 'Material Icons Round', 'Material Icons Sharp']
      families: ['Roboto:300,400,500,700', 'Material Icons', 'Material Icons Outlined']
    }
  })
}

const getThemeDefinition = (themePayload) => {
  const opts = {
    isDark: false,
    palette: {
      text: {
        primary: '#566270'
      },
      primary: {
        light: '#00b39e',
        main: '#008071',
        dark: '#00675a',
        contrastText: '#ffffff'
      },
      secondary: {
        light: '#164071',
        main: '#12345c',
        dark: '#0e2847',
        contrastText: '#ffffff'
      },
      shadow: {
        default: '#e0e0e1'
      },
      border: {
        default: '#e0e0e1'
      },
      background: {
        paper: '#fafafa',
        paperActionBar: '#F3F4F4',
        default: '#ffffff'
      }
    },
    shape: {
      defaultCorners: 4,
      chubby: 8,
      rounded: 20
    },
    snackbar: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      }
    },
    materialIconBase: {
      font: 'unset',
      fontFamily: 'Material Icons',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontSize: '24px',
      lineHeight: '1',
      letterSpacing: 'normal',
      textTransform: 'none',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      wordWrap: 'normal',
      direction: 'ltr',
      fontFeatureSettings: 'liga',
      fontSmoothing: 'antialiased',
      width: '1em',
      height: '1em',
      overflow: 'hidden',
      flexShrink: '0',
      userSelect: 'none'
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            height: '100vh'
          }
        }
      },
      MuiFormControlLabel: {
        root: {
          userSelect: 'none'
        }
      }
    }
  }

  opts.palette.primaryAlt = { ...opts.palette.primary }
  opts.palette.secondaryAlt = { ...opts.palette.secondary }

  return opts
}

const theme = {
  themeId,
  darkThemeId,
  lightThemeId,
  getThemeSetup,
  getThemeDefinition
}

export {
  themeId,
  darkThemeId,
  lightThemeId,
  getThemeSetup,
  getThemeDefinition
}

export default theme
