import ifPromise from 'onyx-common/ifPromise'

const ZEventsApiTicketingTypes = ({ prototype }) => {
  prototype.normalizeTicketingType = function (_data) {
    const data = this.normalizeData(_data)

    const ret = {
      id: data.id,
      name: data.name,
      capacity: data.capacity,
      price: data.price,
      description: data.description
    }

    return ret
  }

  prototype._createTicketingType = function ({ eventId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/ticketing_types`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} TicketingType
   * @property {number} id - ticketing type id
   * @property {string} name - ticketing type name
   * @property {number} capacity - number of ticketing types available
   * @property {string} description - more details on ticketing type
   * @property {number} price - ticketing type price
   */

  /**
   * @function createTicketingType
   * @param {object} payload -  TicketingType payload
   * @param {number} payload.eventId - event id
   * @param {string} payload.name - name of ticketing type
   * @param {number} payload.capacity - number of ticketing type available
   * @param {number} payload.price - price of ticketing type
   * @param {string} payload.description - more detailed description of ticketing type
   * @returns {TicketingType} - TicketingType instance
   * @example
   *
   * createTicketingType({
   *   eventId: 10,
   *   name: 'VIP',
   *   capacity: 1000,
   *   price: 500,
   *   description: "Special backstage pass"
   * })
   */

  prototype.createTicketingType = function (payload) {
    const {
      eventId,
      name,
      capacity,
      price,
      description
    } = payload

    const finalPayload = {
      eventId,
      name,
      capacity,
      price,
      description
    }

    const raw = ifPromise(payload, () => this._createTicketingType(finalPayload))
    return raw
      .then(res => this.normalizeTicketingType(res.data))
      .catch(error => this.onError('createTicketingType', error))
  }

  prototype._deleteTicketingType = function ({ eventId, id }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/ticketing_types/${id}`),
      method: 'DELETE',
      requestType: 'json'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function deleteTicketingType
   * @param {object} payload - The event payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.id - ticketing type id
   * @returns {void}
   * @example
   *
   * deleteEvent({
   *   eventId: 100,
   *   id: 3838
   * })
   */

  prototype.deleteTicketingType = function (payload) {
    const finalPayload = {
      eventId: payload.eventId,
      id: payload.id
    }

    const raw = ifPromise(payload, () => this._deleteTicketingType(finalPayload))
    return raw
      .catch(error => this.onError('deleteTicketingType', error))
  }

  prototype._getTicketingTypes = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${id}/ticketing_types`),
      method: 'GET',
      requestType: 'json',
      data: rest
    }

    return this.fetchWrap(payload)
  }

  /**
   * @typedef {object} getTicketingTypesSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} getTicketingTypesReturn
   * @property {TicketingType[]} entries - list of ticketing types returned
   * @property {getTicketingTypesSummary} listSummary - list summary object
   * @property {object} criteriaSummary - criteria summary object (empty)
   */

  /**
   * @function getTicketingTypes
   * @param {object} payload - The payload
   * @param {number} payload.id - event id
   * @param {number} [payload.page=1] - result set page
   * @returns {getTicketingTypesReturn} - getTicketingTypesReturn
   * @example
   *
   * getTicketingTypes({
   *   id: 10,
   *   page: 2
   * })
   */

  prototype.getTicketingTypes = function (payload) {
    const finalPayload = {
      id: payload.id,
      page: payload?.page || 1
    }

    const raw = ifPromise(payload, () => this._getTicketingTypes(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeTicketingType))
      .catch(error => this.onError('getTicketingTypes', error))
  }

  prototype._showTicketingType = function ({ eventId, id }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/ticketing_types/${id}`),
      method: 'GET'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function showTicketingType
   * @param {object} payload - TicketingType payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.id - ticketing type id
   * @returns {TicketingType} - TicketingType instance
   * @example
   *
   * showTicketingType({
   *   eventId: 10,
   *   id: 100
   * })
   */

  prototype.showTicketingType = function (payload) {
    const finalPayload = {
      eventId: payload.eventId,
      id: payload.id
    }
    const raw = ifPromise(payload, () => this._showTicketingType(finalPayload))
    return raw
      .then(res => this.normalizeTicketingType(res.data))
      .catch(error => this.onError('showTicketingType', error))
  }

  prototype._updateTicketingType = function ({ eventId, id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/ticketing_types/${id}`),
      method: 'PUT',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function updateTicketingType
   * @param {object} payload -  TicketingType payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.id - ticketing type id
   * @param {string} payload.name - name of ticketing type
   * @param {number} payload.capacity - number of ticketing type available
   * @param {number} payload.price - price of ticketing type
   * @param {string} payload.description - more detailed description of ticketing type
   * @returns {TicketingType} - TicketingType instance
   * @example
   *
   * updateTicketingType({
   *   eventId: 10,
   *   id: 100,
   *   name: 'VIP',
   *   capacity: 1000,
   *   price: 500,
   *   description: "Special backstage pass"
   * })
   */

  prototype.updateTicketingType = function (payload) {
    const finalPayload = {
      eventId: payload.eventId,
      id: payload.id,
      name: payload.name,
      description: payload.description,
      price: payload.price,
      capacity: payload.capacity
    }

    const raw = ifPromise(payload, () => this._updateTicketingType(finalPayload))
    return raw
      .then(res => this.normalizeTicketingType(res.data))
      .catch(error => this.onError('updateTicketingType', error))
  }
}

export default ZEventsApiTicketingTypes
