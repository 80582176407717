const DEFAULT_CORS_BYPASS_ORIGIN_TOKEN = 'YOmArfBcpG3t5XGswTM5J0B4c9ydXota'

const augmentFetchPayloadCorsBypass = (payload, helpers = {}) => {
  const { corsBypassOriginToken = DEFAULT_CORS_BYPASS_ORIGIN_TOKEN } = helpers

  const finalPayload = {
    ...payload,
    headers: {
      ...payload?.headers,
      'cors-token': corsBypassOriginToken
    }
  }

  return finalPayload
}

export default augmentFetchPayloadCorsBypass
