import jsonapiFlattener from 'onyx-common/jsonapi-flattener'

export default jsonapiFlattener

// import { Response } from 'onyx-common/jsonapi-flatten'

// const flattenJsonApiResponse = (data) => {
//   console.log('##flattenJsonApiResponse', data)
//   const res = new Response(data)

//   const transform = res.flatten()

//   console.log('##flattenJsonApiResponseTransform', transform)
//   return transform
// }

// export default flattenJsonApiResponse
