import { useEffect, useRef } from 'react'
import useCallback from 'onyx-hooks/useCallback'
import wrapPromise from 'onyx-common/wrapPromise'
import isFunction from 'onyx-common/isFunction'
import createCache from 'onyx-common/createCache'
import stringify from 'onyx-common/stringify'
import useForceUpdate from 'onyx-hooks/useForceUpdate'
import deepcopy from 'onyx-common/deepcopy'
import isDefined from 'onyx-common/isDefined'
import isUndefined from 'onyx-common/isUndefined'
import isObjectLike from 'onyx-common/isObjectLike'
import passthroughFunc from 'onyx-common/passthroughFunc'
import tickWrap from 'onyx-common/tickWrap'
import useErrorHandler from 'onyx-hooks/useErrorHandler'
import parseStateSlicer from 'onyx-common/parseStateSlicer'
import hasKey from 'onyx-common/hasKey'
import normalizeArray from 'onyx-common/normalizeArray'
import makeCancellablePromise from 'onyx-common/make-cancellable-promise'

const createQueryHelper = ({ config: { defaultService, defaultServiceMethod }, services }) => {
  const cache = createCache()

  let nextId = 0
  const subscribers = {}

  const subscribe = (query, cb) => {
    const key = getKey(query)

    if (!subscribers[key]) subscribers[key] = new Map()

    const id = nextId++
    const dispose = () => {
      subscribers[key].delete(id)
      if (subscribers[key].size <= 0) delete (subscribers[key])
    }

    subscribers[key].set(id, cb)
    return dispose
  }

  const notifySubscribers = (query, event) => {
    const key = getKey(query)
    if (isUndefined(event.payload)) event.payload = null
    if (isUndefined(event.opts)) event.opts = {}
    if (!(key in subscribers)) return
    if (subscribers[key].size <= 0) return

    try {
      tickWrap(() => {
        try {
          subscribers[key].forEach(cb => {
            try {
              return cb(event)
            } catch (e) {
              console.log('##innerInnerTickwrap', e)
            }
          })
        } catch (e) {
          console.log('##innerTickeWrap', e)
        }
      })
    } catch (e) {
      console.log('##tickWrap', e)
    }
  }

  const setCache = (query, value) => {
    const key = getKey(query)
    cache.set(key, { ...value, query })
  }

  const hasCache = query => {
    const key = getKey(query)
    return cache.has(key)
  }

  const getCache = query => {
    const key = getKey(query)
    return cache.get(key)
  }

  const getBaseCache = query => {
    const baseKey = getBaseKey(query)

    const ret = {}
    cache.forEach((v, k) => {
      if (k.startsWith(baseKey)) ret[k] = v
    })

    return ret
  }

  const _deleteCache = (key) => {
    const previous = cache.get(key)
    cache.del(key)
    const event = {
      type: 'DELETE_CACHE',
      payload: {
        prevResult: previous?.getResult()
      }
    }
    notifySubscribers(previous.query, event)
  }

  const deleteCache = (query) => {
    const key = getKey(query)
    _deleteCache(key)
  }

  const _getServiceObj = service => {
    if (!service) service = defaultService
    return isObjectLike(service) ? service : services[service]
  }

  const _getMethodFunc = (serviceObj, method) => {
    if (!method) method = defaultServiceMethod
    return isFunction(method) ? method : serviceObj[method]
  }

  const getKey = (query) => {
    const { payload, payloadKey } = query

    const key = payloadKey || stringify(payload)

    return key
  }

  const getBaseKey = (query) => {
    const { basePayloadKey } = query
    if (isDefined(basePayloadKey)) return basePayloadKey

    const key = getKey(query)
    return key
  }

  const getFetcher = (fetcherPayload) => {
    let { service, method, payload, normalize, summarize } = fetcherPayload
    const serviceObj = _getServiceObj(service)
    const methodFunc = _getMethodFunc(serviceObj, method)

    if (!isFunction(normalize)) normalize = passthroughFunc
    if (!isFunction(summarize)) summarize = passthroughFunc

    const ret = () => methodFunc(payload)
      .then(res => ({
        ...res,
        data: summarize(normalize(res.data))
      }))

    return ret
  }

  const getParams = (query) => {
    return query?.params
  }

  const getPayload = ({ payload }) => payload

  const updateQueryResult = (query, resultOrCb, prevResult) => {
    const resource = getCache(query)

    let newResult = resultOrCb
    const finalPrevResult = isDefined(prevResult) ? prevResult : resource.getResult()
    if (isFunction(resultOrCb)) newResult = resultOrCb(deepcopy(finalPrevResult))
    if (isFunction(query?.summarize) && isDefined(newResult.data)) newResult.data = query.summarize(newResult.data)

    resource.updateResult(newResult)

    const event = {
      type: 'UPDATE_RESULT',
      payload: {
        prevResult: finalPrevResult,
        newResult
      }
    }
    notifySubscribers(query, event)
  }

  const triggerSubscribesTo = (query, dataOrCb, opts = {}) => {

  }

  const updateQueryData = (query, dataOrCb, opts = {}) => {
    const resource = getCache(query)
    if (isUndefined(resource)) {
      // the query hasn't been run yet so we just bail
      return undefined
    }

    let newData = dataOrCb

    const prevData = resource.getData()
    if (isFunction(dataOrCb)) newData = dataOrCb(deepcopy(prevData))
    if (isFunction(query?.summarize)) newData = query.summarize(newData)

    resource.updateData(newData)

    const payload = {
      prevData,
      newData
    }

    const event = {
      type: 'UPDATE_DATA',
      payload
    }

    notifySubscribers(query, event)
  }

  const retryQuery = (query, opts = {}) => {
    const prevResult = getCache(query)?.getResult()
    deleteCache(query)
    const result = runQuery(query)
    setCache(query, result)
    notifySubscribers(query, { type: 'RETRY_START', payload: { result, query }, opts })

    let newResult = null
    return result.getPromise()
      .then(r => {
        newResult = r
        notifySubscribers(query, { type: 'RETRY_SUCCESS', payload: r, opts })
      })
      .catch(e => {
        newResult = e
        notifySubscribers(query, { type: 'RETRY_ERROR', payload: e, opts })
      })
      .finally(() => {
        updateQueryResult(query, newResult, prevResult)
        notifySubscribers(query, { type: 'RETRY_COMPLETE', opts })
      })
  }

  const refreshQuery = (query, opts = {}) => {
    const result = runQuery(query)

    notifySubscribers(query, { type: 'REFRESH_START', payload: { result, query }, opts })

    let newResult = null
    return result.getPromise()
      .then(r => {
        newResult = r
        notifySubscribers(query, { type: 'REFRESH_SUCCESS', payload: r, opts })
      })
      .catch(e => {
        newResult = e
        notifySubscribers(query, { type: 'REFRESH_ERROR', payload: e, opts })
      })
      .finally(() => {
        updateQueryResult(query, newResult)
        notifySubscribers(query, { type: 'REFRESH_COMPLETE', opts })
      })
  }

  const revalidateQuery = (query, opts = {}) => {
    // on a revalidate request, we're assuming the result will match, so we want to avoid suspending even if the child would prefer it more generally
    opts = {
      ...opts,
      triggerSuspenseOnRefresh: false
    }

    return refreshQuery(query, opts)
  }

  const updateAndRevalidateQuery = (query, dataOrFunc, opts) => {
    updateQueryData(query, dataOrFunc, opts)
    revalidateQuery(query, opts)
  }

  const updateAndRefreshQuery = (query, dataOrFunc, opts) => {
    updateQueryData(query, dataOrFunc, opts)
    refreshQuery(query, opts)
  }

  const runQuery = (query, handleError) => {
    if (query.isRunning) {
      const currentEntry = getCache(query)
      currentEntry.cancel()
    }

    query.isRunning = true
    const fetcher = getFetcher(query)
    const { promise, cancel } = makeCancellablePromise(fetcher())

    promise.finally(res => {
      query.isRunning = false
      return res
    })
    return wrapPromise({ promise, cancel }, handleError)
  }

  const preloadQuery = (query, force, handleError) => {
    let result = getCache(query)

    if (isUndefined(result) || force) {
      result = runQuery(query, handleError)
      setCache(query, result)
    }

    // this is just for convenience
    return query
  }

  const loadQuery = (query, force, handleError) => {
    preloadQuery(query, force, handleError)

    return getCache(query)
  }

  const _subscribeCallbacks = {
    onRetryStart: ({ stateSlicer, payload: { result, query }, channel, forceUpdate, opts }) => {
      forceUpdate()
    },
    onRetrySuccess: ({ stateSlicer, payload, channel, forceUpdate, opts }) => {
    },
    onRetryComplete: ({ stateSlicer, payload, channel, forceUpdate, opts }) => {
    },
    onRetryError: ({ stateSlicer, payload, channel, forceUpdate, opts }) => {
    },
    onRefreshStart: ({ stateSlicer, payload: { result, query }, channel, forceUpdate, opts }) => {
      if (opts.triggerSuspenseOnRefresh && channel === 'default') {
        setCache(query, result)
        forceUpdate()
      }
    },
    onRefreshSuccess: ({ stateSlicer, payload, channel, forceUpdate, opts }) => {
    },
    onRefreshComplete: ({ stateSlicer, payload, channel, forceUpdate, opts }) => {
    },
    onRefreshError: ({ stateSlicer, payload, channel, forceUpdate, opts }) => {
    },
    onUpdateResult: ({ stateSlicer, payload, channel, forceUpdate }) => {
      const { prevResult, newResult } = payload

      const [, shouldUpdate] = parseStateSlicer(stateSlicer)
      if (shouldUpdate(prevResult?.data, newResult?.data)) forceUpdate()
    },
    onUpdateData: ({ stateSlicer, payload, channel, forceUpdate }) => {
      const { prevData, newData } = payload

      const [, shouldUpdate] = parseStateSlicer(stateSlicer)
      const answer = shouldUpdate(prevData, newData)
      if (answer) forceUpdate()
    },
    onDeleteCache: ({ stateSlicer, forceUpdate }) => {
      forceUpdate()
    }
  }

  const _subscribeReducer = (event, channel, forceUpdate, opts, stateSlicer) => {
    const finalOpts = {
      ..._subscribeCallbacks,
      ...opts
    }

    const {
      onUpdateResult,
      onUpdateData,
      onRefreshStart,
      onRefreshSuccess,
      onRefreshComplete,
      onRefreshError,
      onDeleteCache,
      onRetryStart,
      onRetrySuccess,
      onRetryComplete,
      onRetryError,
      ...rest
    } = finalOpts

    const { type, payload, opts: _eventOpts } = event

    const finalEventOpts = {
      ...rest,
      ..._eventOpts
    }

    const cbPayload = { stateSlicer, payload, channel, forceUpdate, opts: finalEventOpts }

    switch (type) {
      case 'REFRESH_START': return onRefreshStart(cbPayload)
      case 'REFRESH_SUCCESS': return onRefreshSuccess(cbPayload)
      case 'REFRESH_COMPLETE': return onRefreshComplete(cbPayload)
      case 'REFRESH_ERROR': return onRefreshError(cbPayload)
      case 'UPDATE_RESULT': return onUpdateResult(cbPayload)
      case 'UPDATE_DATA': return onUpdateData(cbPayload)
      case 'DELETE_CACHE': return onDeleteCache(cbPayload)
      case 'RETRY_START': return onRetryStart(cbPayload)
      case 'RETRY_SUCCESS': return onRetrySuccess(cbPayload)
      case 'RETRY_COMPLETE': return onRetryComplete(cbPayload)
      case 'RETRY_ERROR': return onRetryError(cbPayload)
    }
  }

  const isComplete = (query) => {
    const previousResult = getCache(query)
    if (!isDefined(previousResult)) return false

    return previousResult.isComplete()
  }

  const getPromise = query => {
    const cache = getCache(query)
    return cache.getPromise(query?.stateSlicer)
  }

  const getData = query => {
    const cache = getCache(query)
    return cache.getData(query?.stateSlicer)
  }

  const getResult = query => {
    const cache = getCache(query)
    return cache.getResult(query?.stateSlicer)
  }

  const isError = (query) => {
    if (!isComplete(query)) return false

    const result = getCache(query)
    return result.isError()
  }

  const isSuccess = (query) => {
    if (!isComplete(query)) return false

    const result = getCache(query)
    return result.isSuccess()
  }

  const isPending = (query) => {
    if (!isComplete(query)) return false

    const result = getCache(query)
    return result.isPending()
  }

  const getNextPayload = (query) => {
    return query?.getNextPayload(query)
  }

  const getNextQueryHelperBag = (query, associationHelper) => {
    return query?.getNextQueryHelperBag(query, associationHelper)
  }

  const getPreviousPayload = (query) => {
    return query?.getPreviousPayload(query)
  }

  const getPreviousQueryHelperBag = (query, associationHelper) => {
    return query?.getPreviousQueryHelperBag(query, associationHelper)
  }

  const getNextQuery = (query) => {
    return query?.getNextQuery(query)
  }

  const getPreviousQuery = (query) => {
    return query?.getPreviousQuery(query)
  }

  const defaultOpts = {}
  const useResourceHook = (query, opts) => {
    const handleError = useErrorHandler()
    if (!opts) opts = defaultOpts
    const isGo = !(opts?.bail === true)

    if (isGo) preloadQuery(query, (opts?.force === true), (opts?.handleError ? opts?.handleError : handleError))
    const _forceUpdate = useForceUpdate()
    const resource = isGo ? getCache(query) : undefined

    const subscribeReducer = opts.subscribeReducer || _subscribeReducer

    useEffect(() => {
      if (!isGo) return
      const channel = 'default'
      const unsubscribeFromResource = subscribe(query, event => subscribeReducer(event, channel, _forceUpdate, opts, query?.stateSlicer))
      return unsubscribeFromResource
    }, [query, subscribeReducer, _forceUpdate, opts, isGo])

    return {
      ...resource,
      read: () => resource.read(query?.stateSlicer),
      getResult: () => resource.getResult(query?.stateSlicer),
      getData: () => resource.getData(query?.stateSlicer)
    }
  }

  const useHook = (query, opts) => {
    const resource = useResourceHook(query, opts)
    if (isUndefined(resource)) return undefined

    return resource.read(query?.stateSlicer)
  }

  const usePromiseHook = (query, opts) => {
    const resource = useResourceHook(query, opts)
    if (isUndefined(resource)) return undefined
    return resource.getPromise(query?.stateSlicer)
  }

  const useDataHook = (query, opts) => {
    const resource = useResourceHook(query, opts)
    if (isUndefined(resource)) return undefined
    return resource.getData(query?.stateSlicer)
  }

  // we split this hook out so if you don't want to subscribe to refresh changes, you don't have to.
  const useIsRefreshingHook = (query, initial = false) => {
    const handleError = useErrorHandler()
    preloadQuery(query, false, handleError)
    const forceUpdate = useForceUpdate()
    const isRefreshing = useRef(initial)

    const setIsRefreshing = useCallback(val => {
      if (val === isRefreshing.current) return
      isRefreshing.current = val
      forceUpdate()
    }, [forceUpdate, isRefreshing])

    useEffect(() => {
      // const channel = 'isRefreshing'
      const unsubscribeFromResource = subscribe(query, event => {
        const { type } = event
        switch (type) {
          case 'REFRESH_START': {
            setIsRefreshing(true)
            break
          }
          case 'REFRESH_COMPLETE':
            setIsRefreshing(false)
            break
        }
      })
      return unsubscribeFromResource
    }, [query, setIsRefreshing])

    return isRefreshing.current
  }

  const listenForQueryDataUpdateCallback = (event) => {
    if (event.eventCode === 'FLUSH_CACHE') {
      return resetCache()
    }

    cache.forEach((v, k) => {
      let cont = true
      if (hasKey(v.query, 'eventsToListenFor')) {
        const test = normalizeArray(v.query.eventsToListenFor)
        if (!test.includes(event.eventCode)) cont = false
      }
      if (cont && hasKey(v.query, 'shouldListenFor')) {
        cont = v.query.shouldListenFor({ eventCode: event.eventCode, params: v.query.params, actionPayload: event.actionPayload, getData: v.getData })
      }
      if (cont && isFunction(v.query?.listenFor)) {
        const query = v.query
        const helpers = {
          updateQueryData: (dataOrCb, opts) => updateQueryData(query, dataOrCb, opts),
          updateAndRevalidateQuery: (dataOrCb, opts) => updateAndRevalidateQuery(query, dataOrCb, opts),
          revalidateQuery: (opts) => revalidateQuery(query, opts),
          updateAndRefreshQuery: (dataOrFunc, opts) => updateAndRefreshQuery(query, dataOrFunc, opts),
          refreshQuery: (opts) => refreshQuery(query, opts),
          deleteCache: (opts) => deleteCache(query, opts)
        }

        const cb = v.query.listenFor
        const payload = {
          event,
          isMounted: (k in subscribers),
          currentEntry: v,
          helpers
        }
        cb(payload)
      }
    })
  }

  const resetCache = () => {
    cache.reset()
  }

  return {
    resetCache,
    useHook,
    usePromiseHook,
    useResourceHook,
    useIsRefreshingHook,
    preloadQuery,
    loadQuery,
    refreshQuery,
    revalidateQuery,
    updateAndRevalidateQuery,
    updateAndRefreshQuery,
    triggerSubscribesTo,
    updateQueryData,
    updateQueryResult,
    getFetcher,
    getKey,
    getBaseKey,
    getBaseCache,
    getPayload,
    subscribe,
    subscribers,
    _subscribeCallbacks,
    _subscribeReducer,
    setCache,
    getCache,
    getData,
    getResult,
    getPromise,
    _cache: cache,
    deleteCache,
    isComplete,
    isSuccess,
    isError,
    isPending,
    retryQuery,
    useDataHook,
    hasCache,
    listenForQueryDataUpdateCallback,
    getNextQuery,
    getNextPayload,
    getPreviousPayload,
    getPreviousQuery,
    getPreviousQueryHelperBag,
    getNextQueryHelperBag,
    getParams
  }
}

export default createQueryHelper
