const preheat = (payload) => {
  const { injectGlobalJss } = payload
  // CSS specificity hack, chaining a classname to itself makes it more specific and take precedent w/o having to use !important.  Like a maniac.
  injectGlobalJss({
    html: {
      fontSize: '16px !important'
    },
    '.MuiCard-root': {
      '& p, &.MuiIcon-root': {
        // color: '#ffffff'
      },
      h5: {
        fontSize: '18px'
      }
    }
  })

  injectGlobalJss({
    'body.is-logged-in.is-logged-in:not(.admin_layout) #page': {
      marginTop: '46px !important'
    }
  }, { media: '(max-width: 768px)' })
}

const onBakeComplete = (payload) => {
  // const { $ } = payload
  // setTimeout(() => {
  //   $('.mobile-flex-container #page').prop('style', 'opacity: 1')
  // }, 225)
}
export default {
  id: 'stup.base',
  preheat,
  onBakeComplete
}
