import urlencode from 'onyx-common/urlencode'
import isUndefined from 'onyx-common/isUndefined'

const normalizeQueryParam = (key, value) => {
  if (isUndefined(value)) return undefined

  if (Array.isArray(value)) {
    if (key.endsWith('[]')) return value.map(v => `${key}=${urlencode(v)}`)

    // ok so for this we're just going to comma delimit
    const normalized = value.map(v => {
      return urlencode(v)
    }).join(',')

    return `${key}=${normalized}`
  }

  // ok so this is just a normal case
  const normalized = urlencode(value)
  return `${key}=${normalized}`
}

export default normalizeQueryParam
