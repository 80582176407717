import assign from 'onyx-common/assign'

import {
  getThemeSetup,
  lightThemeId,
  darkThemeId,
  getThemeDefinition as getBaseThemeDefinition
} from '../Nsp'

import resetDarkTheme from '../utils/resetDarkTheme'

const themeId = 'NspDark'

const getThemeDefinition = (themePayload) => {
  const baseTheme = getBaseThemeDefinition(themePayload)
  const resetDark = resetDarkTheme(baseTheme)

  const dark = {
    isDark: true,
    palette: {
      text: {
        primary: '#e5e5e5',
        secondary: '#cccccc'
      },
      primary: {
        light: '#00b39e',
        main: '#008071',
        dark: '#00675a',
        contrastText: '#ffffff'
      },
      secondary: {
        light: '#164071',
        main: '#12345c',
        dark: '#0e2847',
        contrastText: '#ffffff'
      },
      shadow: {
        default: '#535354'
      },
      border: {
        default: '#535354'
      },
      background: {
        paper: '#252525',
        default: '#191919'
      }
    }
    // palette: {
    //   background: {
    //     footerTop: '#565c63',
    //     footerBtm: '#3d4249'
    //   },
    //   shadow: {
    //     default: '#535354'
    //   },
    //   border: {
    //     default: '#535354'
    //   },
    //   primary: {
    //     main: '#4c79b7'
    //   },
    //   primaryAlt: {
    //     main: '#ffffff'
    //   },
    //   secondary: {
    //     main: '#838990'
    //   },
    //   secondaryAlt: {
    //     main: '#4c79b7'
    //   }
    // }
  }

  const opts = assign(resetDark, dark)

  // opts.shadows[0] = 'none'
  // opts.shadows[1] = '0px 2px 1px -1px rgba(192,192,192,0.2), 0px 1px 1px 0px rgba(192,192,192,0.14), 0px 1px 3px 0px rgba(192,192,192,0.12)'

  // console.log(opts)
  return opts
}

const theme = {
  themeId,
  lightThemeId,
  darkThemeId,
  getThemeSetup,
  getThemeDefinition
}

export {
  themeId,
  lightThemeId,
  darkThemeId,
  getThemeSetup,
  getThemeDefinition
}

export default theme
