import getRoutes from './getRoutes'
import getStoreDefinition from './getStoreDefinition'
import getLayouts from './getLayouts'
import getQueries from './getQueries'
import getLayoutVars from './getLayoutVars'

export default {
  getRoutes,
  getStoreDefinition,
  getLayouts,
  getQueries,
  getLayoutVars
}
