import WebFont from 'onyx-common/webfontloader'
import injectJss from 'onyx-common/injectJss'
import Rubik from './fonts/Rubik'
import Lora from './fonts/Lora'

const themeId = 'Kwivvr'
const lightThemeId = 'Kwivvr'
const darkThemeId = 'KwivvrDark'

const getThemeSetup = (themePayload) => () => {
  injectJss(Rubik)
  injectJss(Lora)
  WebFont.load({
    google: {
      families: ['Roboto:300,400,500,700', 'Material Icons']
    }
  })
}

const getThemeDefinition = (themePayload) => {
  const opts = {
    maxWidth: 1000,
    isDark: false,
    typography: {
      fontFamily: "'Rubik', sans-serif"
      // fontSize: 14
    },
    palette: {
      text: {
        primary: '#090909'
      },
      background: {
        paper: '#fafafa',
        default: '#fff'
      },
      shadow: {
        default: '#e0e0e1'
      },
      border: {
        default: '#e0e0e1'
      },
      primary: {
        main: '#3551A1',
        contrastText: '#fff'
      },
      primary2: {
        main: '#F2BE48',
        contrastText: '#fff'
      },
      secondary: {
        main: '#EC453D',
        light: '#facbc9',
        contrastText: '#fff'
      },
      secondary2: {
        main: '#67BFDC',
        contrastText: '#fff'
      },
      green: {
        main: '#2CCC7A',
        light: '#facbc9',
        contrastText: '#fff'
      }
    },
    shape: {
      defaultRounded: 4,
      chubby: 8,
      rounded: 20
    },
    snackbar: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      }
    },
    zIndex: {
      snackbar: 1500
    },
    materialIconBase: {
      font: 'unset',
      fontFamily: 'Material Icons',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontSize: '24px',
      lineHeight: '1',
      letterSpacing: 'normal',
      textTransform: 'none',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      wordWrap: 'normal',
      direction: 'ltr',
      fontFeatureSettings: 'liga',
      fontSmoothing: 'antialiased',
      width: '1em',
      height: '1em',
      overflow: 'hidden',
      flexShrink: '0',
      userSelect: 'none'
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            height: '100vh'
          }
        }
      },
      MuiFormControlLabel: {
        root: {
          userSelect: 'none'
        }
      }
    }
  }

  opts.palette.primaryAlt = { ...opts.palette.primary }
  opts.palette.secondaryAlt = { ...opts.palette.secondary }

  return opts
}

const theme = {
  themeId,
  darkThemeId,
  lightThemeId,
  getThemeSetup,
  getThemeDefinition
}

export {
  themeId,
  darkThemeId,
  lightThemeId,
  getThemeSetup,
  getThemeDefinition
}

export default theme
