import { createStore } from 'onyx-common/store'
import isFunction from 'onyx-common/isFunction'

const getStoreFromApps = ({ apps, storePayload, storeDefinitionOverride }) => {
  const arr = apps.map(a => a.getStoreDefinition)

  const storeDefinition = arr.reduce((reducedStoreDefinition, func) => {
    const appDef = func(storePayload)
    return {
      ...reducedStoreDefinition,
      ...appDef
    }
  }, {})

  const finalStoreDefinition = isFunction(storeDefinitionOverride) ? storeDefinitionOverride(storeDefinition, storePayload) : storeDefinition

  const store = createStore(finalStoreDefinition)

  return store
}

export default getStoreFromApps
