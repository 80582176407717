import castToArray from 'onyx-common/castToArray'
import textCompare from 'onyx-common/textCompare'

const generateShouldBakeByOnyxVariation = testVariation => payload => {
  const { getOnyxVariation } = payload

  const onyxVariation = getOnyxVariation()
  if (!onyxVariation) return false

  // trim and lower case for convenience
  const finalOnyxVariation = textCompare(onyxVariation)

  const arrTestVariation = castToArray(testVariation)

  let shouldBake = false
  arrTestVariation.forEach(variation => {
    if (finalOnyxVariation === textCompare(variation)) shouldBake = true
  })

  return shouldBake
}

export default generateShouldBakeByOnyxVariation
