import { createBrowserHistory } from 'onyx-common/history'
import { matchRoutes } from 'onyx-common/react-router-config'
import decorateLocation from 'onyx-common/decorateLocation'
import isDefined from 'onyx-common/isDefined'
import buildUrl from 'onyx-common/buildUrl'
import urlToRoute from 'onyx-common/urlToRoute'
import isArray from 'onyx-common/isArray'
/**
 * A custom router built from the same primitives as react-router. Each object in `routes`
 * contains both a Component and a prepare() function that can preload data for the component.
 * The router watches for changes to the current location via the `history` package, maps the
 * location to the corresponding route entry, and then preloads the code and data for the route.
 */

const defaultShouldUpdate = (newLocation, prevLocation) => {
  return newLocation.pathname !== prevLocation.pathname
}

const createRouter = ({ routes, options }) => {
  // Initialize history
  const history = createBrowserHistory(options)

  // Find the initial match and prepare it

  const initialMatches = matchRoute(routes, history.location)
  const initialEntries = prepareMatches(initialMatches, history.location)
  let currentEntry = {
    location: history.location,
    entry: initialEntries[0]
  }

  // maintain a set of subscribers to the active entry
  let nextId = 0
  const subscribers = new Map()

  // maintain a set of rawSubscribers to the active entry
  let rawNextId = 0
  const rawSubscribers = new Map()

  // Listen for location changes, match to the route entry, prepare the entry,
  // and notify subscribers. Note that this pattern ensures that data-loading
  // occurs *outside* of - and *before* - rendering.
  const cleanup = history.listen((payload, action) => {
    const { location } = payload

    if (isDefined(location.key) && (location.key === currentEntry.location.key)) return

    const matches = matchRoute(routes, location)
    const entries = prepareMatches(matches, location)
    const nextEntry = {
      location,
      entry: entries[0]
    }

    rawSubscribers.forEach(cb => {
      cb(nextEntry, currentEntry)
    })

    if (currentEntry.entry.shouldUpdate(location, currentEntry.location, defaultShouldUpdate)) subscribers.forEach(cb => cb(nextEntry))

    currentEntry = nextEntry
  })

  // The actual object that will be passed on the RoutingConext.
  const context = {
    history,
    get () {
      return currentEntry
    },
    preloadCode (pathname) {
      // preload just the code for a route, without storing the result
      const matches = matchRoutes(routes, pathname)
      matches.forEach(({ route }) => {
        route.component.load()
        route.layout && route.layout.component.load()
      })
    },
    preload (pathname) {
      // preload the code and data for a route, without storing the result
      const matches = matchRoutes(routes, pathname)
      prepareMatches(matches, currentEntry.location)
    },
    subscribe (cb) {
      const id = nextId++
      const dispose = () => {
        subscribers.delete(id)
      }
      subscribers.set(id, cb)
      return dispose
    },
    rawSubscribe (cb) {
      const id = rawNextId++
      const dispose = () => {
        rawSubscribers.delete(id)
      }
      rawSubscribers.set(id, cb)
      return dispose
    },
    navigate (to, replace = false) {
      const finalUrl = buildUrl(to)
      const finalTo = urlToRoute(finalUrl)

      if (replace) history.replace(finalTo)
      else history.push(finalTo)
    },
    getUrl (to) {
      const finalUrl = buildUrl(to)
      return finalUrl
    }
  }

  // Return both the context object and a cleanup function
  return { cleanup, context }
}

/**
 * Match the current location to the corresponding route entry.
 */
function matchRoute (routes, location) {
  const matchedRoutes = matchRoutes(routes, location.pathname)
  if (!isArray(matchedRoutes) || matchedRoutes.length === 0) {
    throw new Error('No route for ' + location.pathname)
  }
  return matchedRoutes
}

/**
 * Load the data for the matched route, given the params extracted from the route
 */
function prepareMatches (matches, location) {
  return matches.map(match => {
    const { route, match: matchData } = match

    const { queryParams, hashParams } = decorateLocation(location)

    const payload = { params: matchData.params, queryParams: queryParams, hashParams, location }

    const prepared = route.prepare(payload)
    const layoutPrepared = route.layout && route.layout.prepare(payload)

    const scrollToTop = !!route.scrollToTop || false
    const Component = route.component.get()
    if (Component == null) {
      route.component.load() // eagerly load
    }

    const LayoutComponent = route.layout.component.get()
    if (LayoutComponent == null) {
      route.layout.component.load()
    }

    const layout = {
      ...route.layout,
      prepared: layoutPrepared
    }

    const shouldUpdate = route.shouldUpdate || defaultShouldUpdate

    return { component: route.component, layout: layout, prepared, routeData: matchData, scrollToTop, shouldUpdate }
  })
}

export default createRouter
