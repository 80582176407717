import PropTypes from 'prop-types'
import isUndefined from 'onyx-common/isUndefined'
import isDefined from 'onyx-common/isDefined'
import hasKey from 'onyx-common/hasKey'

const _parseJsonApiListSummary = (data) => {
  const { pagination } = data

  const ret = {
    page: parseInt(data.page),
    count: parseInt(pagination.items),
    total: parseInt(pagination.count),
    perPage: parseInt(data.perPage),
    pages: parseInt(pagination.pages),
    hasMore: !!pagination.next
  }

  return ret
}

const parseListSummary = (data) => {
  if (hasKey(data, 'pagination')) return _parseJsonApiListSummary(data)

  let {
    count,
    total,
    page = 0,
    perPage,
    pages,
    hasMore
  } = data

  if (isUndefined(total)) total = data?.totalCount
  if (isUndefined(page)) page = data?.currentPage
  if (isUndefined(hasMore)) hasMore = data?.has_more
  if (isUndefined(perPage)) perPage = 0

  if (isUndefined(hasMore)) {
    if (isDefined(pages) && (pages > page)) hasMore = true
  }

  if (isUndefined(pages)) {
    if (isDefined(hasMore) && hasMore) pages = page + 1
    else pages = page
  }

  // if count doesn't come back and isn't passed in we'll infer
  if (isUndefined(count)) count = perPage

  // we'll infer we have at least one more page if hasMore is
  if (isDefined(hasMore) && isUndefined(pages)) {
    pages = page + 1
  }

  // we're going to fake a total
  if (isDefined(hasMore) && isUndefined(total)) {
    total = hasMore ? total = perPage * (page + 1) : (perPage * (page - 1) + count)
  }

  if (isUndefined(pages)) {
    pages = Math.ceil(total / perPage)
  }

  return {
    count: parseInt(count),
    total: parseInt(total),
    page: parseInt(page),
    perPage: parseInt(perPage),
    pages: parseInt(pages)
  }
}

const listSummaryPropType = PropTypes.shape({
  count: PropTypes.number,
  total: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  pages: PropTypes.number,
  hasMore: PropTypes.bool
})

export {
  listSummaryPropType,
  parseListSummary
}

export default parseListSummary
