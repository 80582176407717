import generateShouldBakeByOnyxVariation from 'onyx-common/generateShouldBakeByOnyxVariation'
// import generateIngredientInstance from 'onyx-common/generateIngredientInstance'
// import {} from '../ingredients'

const ingredients = []
const shouldBake = generateShouldBakeByOnyxVariation('beta')

export default {
  id: 'stup.beta',
  ingredients,
  shouldBake
}
