import _emitEvent from 'onyx-common/emitEvent'
import isFunction from 'onyx-common/isFunction'

const createAuthable = (opts) => {
  const {
    store,
    emitEvent = _emitEvent,
    eventPrefix = '',
    callbacks = {}
  } = opts

  const getCredentials = store.getCredentials

  const EVENT_CODE = `${eventPrefix}AUTHABLE`

  const onAugmentPayload = (payload) => {
    return getCredentials()
      .then(credentials => {
        return onAugmentPayloadSync(payload, credentials)
      })
  }

  const onAugmentPayloadSync = (payload, credentials) => {
    if (!credentials || !credentials?.accessToken) return payload

    const finalPayload = {
      ...payload,
      headers: {
        ...payload?.headers,
        Authorization: `Bearer ${credentials.accessToken}`
      }
    }

    return finalPayload
  }

  const setCredentials = store.setCredentials

  const destroyCredentials = store.destroyCredentials

  const onLogin = (data) => {
    emitEvent(EVENT_CODE, { eventType: 'LOGIN', data })
    return setCredentials(data).then(res => {
      if (isFunction(callbacks?.login)) return callbacks.onLogin(res)
      return res
    })
  }

  const onRefresh = (data) => {
    emitEvent(EVENT_CODE, { eventType: 'REFRESH', data })
    return setCredentials(data).then(res => {
      if (isFunction(callbacks?.onRefresh)) return callbacks.onRefresh(res)
      return res
    })
  }

  const onLogout = () => {
    emitEvent(EVENT_CODE, { eventType: 'LOGOUT' })
    return destroyCredentials().then(res => {
      if (isFunction(callbacks?.onLogout)) return callbacks.onLogout(res)
      return res
    })
  }

  const forceLogout = (fetchPayload) => {
    return onLogout()
  }

  const hasCredentials = () => {
    return getCredentials()
      .then(credentials => {
        return hasCredentialsSync(credentials)
      })
  }

  const hasCredentialsSync = (credentials) => {
    return !!credentials
  }

  const setAccessToken = (accessToken) => {
    return getCredentials()
      .then(credentials => {
        if (!credentials) credentials = {}

        const finalCredentials = {
          ...credentials,
          accessToken
        }

        return setCredentials(finalCredentials)
      })
  }

  const setRefreshToken = (refreshToken) => {
    return getCredentials()
      .then(credentials => {
        if (!credentials) credentials = {}

        const finalCredentials = {
          ...credentials,
          refreshToken
        }

        return setCredentials(finalCredentials)
      })
  }

  const getAccessToken = () => {
    return getCredentials()
      .then(credentials => {
        if (!credentials || !credentials?.accessToken) return undefined

        return credentials.accessToken
      })
  }

  const getRefreshToken = () => {
    return getCredentials()
      .then(credentials => {
        if (!credentials || !credentials?.refreshToken) return undefined

        return credentials.refreshToken
      })
  }

  const isAuthenticated = () => {
    return getAccessToken()
      .then(accessToken => {
        return isAuthenticatedSync(accessToken)
      })
  }

  const isAdmin = () => {
    return getCredentials()
      .then(credentials => {
        if (!credentials || !credentials?.isAdmin) return undefined
        return credentials.isAdmin
      })
  }

  const isAuthenticatedSync = (accessToken) => {
    return !!accessToken
  }

  const isInvalidAccessTokenFailure = (response) => {
    // 401 is the first check
    if (response.status !== 401) return false

    return response.headers.has('www-authenticate')
  }

  const authable = {
    isInvalidAccessTokenFailure,
    EVENT_CODE,
    setAccessToken,
    setRefreshToken,
    setCredentials,
    getCredentials,
    destroyCredentials,
    hasCredentials,
    forceLogout,
    onAugmentPayload,
    onLogin,
    onRefresh,
    onLogout,
    getRefreshToken,
    getAccessToken,
    isAuthenticated,
    isAdmin,
    onAugmentPayloadSync,
    hasCredentialsSync,
    isAuthenticatedSync
  }

  return authable
}

export default createAuthable
