import { action, computed, persist } from 'onyx-common/store'
import findFirst from 'onyx-common/findFirst'
import isString from 'onyx-common/isString'
import isDefined from 'onyx-common/isDefined'
import isPeItemObject from 'onyx-common/isPeItemObject'
import keyedArrToObj from 'onyx-common/keyedArrToObj'
import { default as getStoreDefinitionAvatarSpeedDial } from './components/AvatarSpeedDial/getStoreDefinition'
import { default as getStoreDefinitionKwivvrClient } from './components/KwivvrClient/getStoreDefinition'// {GENERATED_IMPORT}

import { default as getStoreDefinitionLMSViewer } from './components/LMSViewer/getStoreDefinition'// {GENERATED_IMPORT}

// import createPersistFunctionTransform from 'onyx-common/createPersistFunctionTransform'
// const rootItemsTransformer = createPersistFunctionTransform('pe.Root.items')

const decorateItem = item => {
  const {
    routes,
    instanceId,
    queries,
    levers,
    props,
    actions,
    isRendered
  } = item.instanceRef.current

  const ret = {
    ...item,
    instanceId,
    routes: keyedArrToObj(routes),
    queries,
    levers,
    props,
    actions,
    isRendered
  }

  return ret
}

const getStoreDefinition = (opts) => {
  const { config: { theme, defaultLocale } } = opts

  const rootModel = {
    theme,
    locale: defaultLocale,
    items: {},
    auth: {
      identifier: undefined,
      refreshToken: undefined,
      token: undefined
    },
    itemsArr: computed(state => Object.values(state.items)),
    setLocale: action((state, locale) => {
      state.locale = locale
    }),
    setTheme: action((state, theme) => {
      state.theme = theme
    }),
    setAuth: action((state, newAuth) => {
      state.auth = newAuth
    }),
    resetAuth: action(state => {
      state.auth = {
        identifier: undefined,
        refreshToken: undefined,
        token: undefined
      }
    }),
    isAuthenticated: computed(state => () => isDefined(state.auth.token)),
    updateAuthToken: action((state, newToken) => {
      state.auth = {
        ...state.auth,
        token: newToken
      }
    }),
    getAuth: computed(state => () => state.auth),
    getAuthToken: computed(state => () => state.auth.authToken),
    getAuthRefreshToken: computed(state => () => state.auth.refreshToken),
    getAuthIdentifier: computed(state => () => state.auth.identifier),
    getTheme: computed(state => () => state.theme),
    getLocale: computed(state => () => state.locale),
    addItem: action((state, item) => {
      state.items[item.instanceId] = item
    }),
    removeItem: action((state, item) => {
      delete state.items[item.instanceId]
    }),
    updateItem: action((state, item) => {
      state.items[item.instanceId] = item
    }),
    setItems: action((state, items) => {
      state.items = items
    }),
    clearItems: action(state => {
      state.items = {}
    }),
    getItemByInstanceId: computed(state => instanceId => {
      if (isDefined(state.items[instanceId])) return decorateItem(state.items[instanceId])
      return undefined
    }),
    getItemByComponent: computed(
      state => component => {
        const ret = findFirst(
          state.itemsArr,
          item => item.component === component
        )

        if (isDefined(ret)) return decorateItem(ret)
        return undefined
      }
    ),
    getItems: computed(state => () => {
      const ret = {}
      Object.values(state.items).forEach(item => {
        ret[item.instanceId] = decorateItem(item)
      })
      return ret
    }),
    getItem: computed(state => payload => {
      if (isPeItemObject(payload)) {
        // an item was passed in so we'll just pass it through it
        return payload
      }

      // otherwise we'll allow lookup by instanceId or component
      if (isString(payload)) {
        const tmp = state.getItemByInstanceId(payload)
        return isDefined(tmp) ? tmp : state.getItemByComponent(payload)
      }

      return undefined
    }),
    isItem: computed(state => payload => {
      return isDefined(state.getItem(payload))
    }),
    getRoutes: computed(state => () => {
      const ret = {}
      Object.values(state.getItems()).forEach(item => {
        ret[item.instanceId] = item.routes
      })
      return ret
    }),
    getQueries: computed(state => () => {
      const ret = {}
      Object.values(state.getItems()).forEach(item => {
        ret[item.instanceId] = item.queries
      })
      return ret
    }),
    getActions: computed(state => () => {
      const ret = {}
      Object.values(state.getItems()).forEach(item => {
        ret[item.instanceId] = item.actions
      })
      return ret
    }),
    getLevers: computed(state => () => {
      const ret = {}
      Object.values(state.getItems()).forEach(item => {
        ret[item.instanceId] = item.levers
      })
      return ret
    }),
    getProps: computed(state => () => {
      const ret = {}
      Object.values(state.getItems()).forEach(item => {
        ret[item.instanceId] = item.props
      })
      return ret
    }),
    getQueriesByItem: computed(state => (payload) => {
      const item = state.getItem(payload)
      return item?.queries
    }),
    getRoutesByItem: computed(state => (payload) => {
      const item = state.getItem(payload)
      return item?.routes
    }),
    getLeversByItem: computed(state => payload => {
      const item = state.getItem(payload)
      return item?.levers
    }),
    getActionsByItem: computed(state => (payload) => {
      const item = state.getItem(payload)
      return item?.actions
    }),
    getPropsByItem: computed(state => payload => {
      const item = state.getItem(payload)
      return item?.props
    }),
    getLever: computed(state => (itemPayload, leverId) => {
      const levers = state.getLeversByItem(itemPayload)
      return levers?.[leverId]
    }),
    getProp: computed(state => (itemPayload, propKey) => {
      const props = state.getPropsByItem(itemPayload)
      return props?.[propKey]
    }),
    getRoute: computed(state => (itemPayload, routeId) => {
      const routes = state.getRoutesByItem(itemPayload)
      return routes?.[routeId]
    }),
    getQuery: computed(state => (itemPayload, queryId) => {
      const queries = state.getQueriesByItem(itemPayload)
      return queries?.[queryId]
    }),
    getAction: computed(state => (itemPayload, actionId) => {
      const actions = state.getActionsByItem(itemPayload)
      return actions?.[actionId]
    }),
    isLever: computed(state => (...payload) => {
      return isDefined(state.getLever(...payload))
    }),
    isRoute: computed(state => (...payload) => {
      return isDefined(state.getRoute(...payload))
    }),
    isQuery: computed(state => (...payload) => {
      return isDefined(state.getQuery(...payload))
    }),
    isAction: computed(state => (...payload) => {
      return isDefined(state.getAction(...payload))
    }),
    isProp: computed(state => (...payload) => {
      return isDefined(state.getProp(...payload))
    })
  }

  return {
    pe: {
      Root: persist(rootModel, { allow: ['theme', 'auth'] }),
      AvatarSpeedDial: getStoreDefinitionAvatarSpeedDial(opts),
      KwivvrClient: getStoreDefinitionKwivvrClient(opts),
      LMSViewer: getStoreDefinitionLMSViewer(opts)// {GENERATED_ENTRY}
    }
  }
}

export default getStoreDefinition
