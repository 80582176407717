import React, { Suspense } from 'react'

import useTranslation from 'onyx-hooks/useTranslation'
import useCurrentLocale from 'onyx-hooks/useCurrentLocale'
import childrenPropType from 'onyx-common/childrenPropType'
import PropTypes from 'prop-types'

const WrappedLocaleWatcher = (props) => {
  let {
    children,
    locale
  } = props

  const { i18n } = useTranslation()
  const currentLocale = useCurrentLocale()
  if (!locale) locale = currentLocale

  if (i18n.language !== locale) i18n.changeLanguage(locale)

  return children
}

const LocaleWatcher = (props) => {
  return (
    <Suspense fallback={null}>
      <WrappedLocaleWatcher {...props} />
    </Suspense>
  )
}

LocaleWatcher.propTypes = {
  children: childrenPropType,
  locale: PropTypes.string
}

export default LocaleWatcher
