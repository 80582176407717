import { action, persist } from 'onyx-common/store'

const getStoreDefinition = () => persist({
  isDarkMode: false,
  isInTheaterMode: false,
  setIsInTheaterMode: action((state, status) => {
    state.isInTheaterMode = !!status
  }),
  setIsDarkMode: action((state, status) => {
    state.isDarkMode = !!status
  })
}, { allow: ['isInTheaterMode', 'isDarkMode'] })

export default getStoreDefinition
