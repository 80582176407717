import Context from './context'
import PropTypes from 'prop-types'
import React from 'react'

const LayoutVarsProvider = ({ children, layoutVars }) => (
  <Context.Provider value={layoutVars}>{children}</Context.Provider>
)

LayoutVarsProvider.propTypes = {
  layoutVars: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default LayoutVarsProvider
