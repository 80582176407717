import { action, persist, computed } from 'onyx-common/store'
import isDefined from 'onyx-common/isDefined'
import keyedArrToObj from 'onyx-common/keyedArrToObj'
import isArray from 'onyx-common/isArray'

const resetState = state => {
  state.hasTouched = false
  state.position = {
    x: 0,
    y: 0
  }
  state.actions = {}
  state.avatarUrl = undefined
  state.clientId = undefined
}

const getStoreDefinition = () => {
  return (
    persist({
      actions: {},
      avatarUrl: undefined,
      clientId: undefined,
      hasTouched: false,
      position: {
        x: 0,
        y: 0
      },
      actionsArr: computed(state => Object.values(state.actions)),
      updateAvatarUrl: action((state, avatarUrl) => {
        state.avatarUrl = avatarUrl
      }),
      updateClientId: action((state, clientId) => {
        if (isDefined(state.clientId) && state.clientId !== clientId) resetState(state)
        state.clientId = clientId
      }),
      setActions: action((state, actions) => {
        state.actions = isArray(actions) ? keyedArrToObj(actions, 'id') : actions
      }),
      addAction: action((state, action) => {
        state.hasTouched = true
        state.actions[action.id] = action
      }),
      removeAction: action((state, action) => {
        state.hasTouched = true
        delete state.actions[action.id]
      }),
      removeAllActions: action(state => {
        state.hasTouched = true
        state.actions = {}
      }),
      updatePosition: action((state, position) => {
        state.position = position
      }),
      reset: action(resetState)
    }, { storage: 'localStorage' })
  )
}

export default getStoreDefinition
