import React, { useEffect, useState } from 'react'
import useRouter from './useRouter'
import childrenPropType from 'onyx-common/childrenPropType'

const LayoutRenderer = ({ children }) => {
  const router = useRouter()
  const [layout, setLayout] = useState(router.get().entry.layout)

  useEffect(() => {
    const dispose = router.subscribe(nextEntry => {
      const newLayout = nextEntry.entry.layout

      if (layout.id !== newLayout.id) setLayout(newLayout)
    })
    return dispose

    // Note: this hook updates routeEntry manually; we exclude that variable
    // from the hook deps to avoid recomputing the effect after each change
    // triggered by the effect itself.
  }, [router, layout])

  // Access the router
  const LayoutComponent = layout.component.read()
  return <LayoutComponent {...layout.prepared}>{children}</LayoutComponent>
}

LayoutRenderer.propTypes = {
  children: childrenPropType.isRequired
}

export default LayoutRenderer
