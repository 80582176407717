import buildUrl from 'onyx-common/buildUrl'
import formurlencoded from 'onyx-common/form-urlencoded'
// import hasKey from 'onyx-common/hasKey'

const _getFetchOptionsAndUrl = opts => {
  let { url, urlMerge, data = {}, queryData = {}, method = 'GET', deleteMethod = 'DELETE', requestType = 'form', headers = {}, fetchOptions = {} } = opts

  const options = {
    headers,
    method
  }

  // rails convention
  if (method === 'DELETE' && deleteMethod !== 'DELETE') {
    data._method = 'delete'
    options.method = deleteMethod
  }

  switch (method) {
    case 'POST':
    case 'PUT':
    case 'DELETE':
      if (data) {
        switch (requestType) {
          case 'json':
            options.headers = {
              ...options.headers,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
            options.body = JSON.stringify(data)
            break
          case 'form':
          default:
            options.headers = {
              ...options.headers,
              Accept: 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded'
            }
            options.body = formurlencoded(data)
        }
      }

      // if we want to pack in some url changes as well on a non-get, we use queryData
      url = buildUrl({ url, urlMerge, ...(queryData ? { data: queryData } : {}) })
      break
    case 'GET':
    default:
      url = buildUrl({ url, data, urlMerge })
      break
  }

  const finalOptions = {
    ...options,
    ...fetchOptions
  }

  return {
    url,
    fetchOptions: finalOptions
  }
}

const fetchWrap = (opts) => {
  const { url, fetchOptions } = _getFetchOptionsAndUrl(opts)

  return fetch(url, fetchOptions)
    .catch(error => Promise.reject(error))
    .then(res => {
      return res
        .text()
        .then(text => {
          return { response: res, text }
        })
    })
    .then(({ response, text }) => {
      if (!text) text = ''

      let data = {}

      try {
        data = JSON.parse(text)
      } catch (e) {
        data = JSON.parse(JSON.stringify(text))
      }

      const payload = { response, data }
      return response.ok ? Promise.resolve(payload) : Promise.reject(payload)
    })
}

export default fetchWrap
