import React from 'react'
import getDisplayName from 'onyx-common/react-display-name'
import PropTypes from 'prop-types'

const _getPayload = (props) => {
  const payload = {
    ...props
  }
  const preventDefault = (typeof props.preventDefault === 'undefined' ? true : !!props.preventDefault)
  const stopPropagation = (typeof props.stopPropagation === 'undefined' ? true : !!props.stopPropagation)

  if (props.onClick) {
    const onClick = (e) => {
      if (preventDefault) e.preventDefault()
      if (stopPropagation) e.stopPropagation()
      props.onClick(e)
    }
    payload.onClick = onClick
  }

  delete payload.preventDefault
  delete payload.stopPropagation

  return payload
}

const wrapOnClick = (WrappedComponent) => {
  const wrapped = props => {
    const payload = _getPayload(props)
    return <WrappedComponent {...payload} />
  }
  wrapped.displayName = getDisplayName(WrappedComponent)

  wrapped.propTypes = {
    ...(WrappedComponent.propTypes || {}),
    preventDefault: PropTypes.bool,
    stopPropagation: PropTypes.bool
  }

  return wrapped
}

export default wrapOnClick
