import generateAppWindowHelpers from 'onyx-common/generateAppWindowHelpers'
import generateRecipe from 'onyx-common/generateRecipe'

const createEntree = ({ recipes, config, ...payload }) => {
  const appWindowHelpers = generateAppWindowHelpers({ config })

  const finalPayload = {
    ...appWindowHelpers,
    ...payload
  }

  const hydratedRecipes = recipes.map(recipe => generateRecipe(recipe)(finalPayload))

  const recipesToBake = hydratedRecipes.filter(recipe => recipe.shouldBake())

  const prepareEntree = () => {
    recipesToBake.forEach(recipe => {
      recipe.prep()
      recipe.bake()
    })
  }

  const preheatEntree = () => {
    recipesToBake.forEach(recipe => {
      recipe.preheat()
    })
  }

  return { prepareEntree, preheatEntree, hydratedRecipes }
}

export default createEntree
