import React, { useCallback, useEffect } from 'react'
import ThemeProvider from 'onyx-common/ThemeProvider'
import createTheme from 'onyx-common/createTheme'
import { responsiveFontSizes } from '@material-ui/core/styles'
import getTheme from 'onyx-common/getTheme'
import isFunction from 'onyx-common/isFunction'
import PropTypes from 'prop-types'

const ThemeWrapper = ({ children, theme, themePayload, themeOverride }) => {
  if (typeof theme === 'string') theme = getTheme(theme).default

  // Why are we doing this?  So the onyx-app root component has an opportunity to override themes if need be
  const { getThemeSetup, getThemeDefinition } = isFunction(themeOverride) ? themeOverride(theme, themePayload) : theme

  const themeSetup = getThemeSetup(themePayload)
  const themeDefinition = getThemeDefinition(themePayload)

  // useCallback caches the function call itself to return a non-changing reference to the same function for the same dependencies
  // this is critical because it prevents this component and children from unnecessarily re-rendering
  const runSetup = useCallback(themeSetup, [theme])

  // useEffect runs once then whenever it's dependencies change [runSetup] in this case
  // we only want to run it once per theme to prevent loading up fonts and what not multiple times
  useEffect(runSetup, [runSetup])

  const themeObj = createTheme(themeDefinition)

  return (
    <ThemeProvider theme={responsiveFontSizes(themeObj)}>
      {children}
    </ThemeProvider>
  )
}

ThemeWrapper.propTypes = {
  themePayload: PropTypes.object.isRequired,
  themeOverride: PropTypes.func.isRequired,
  theme: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    getThemeSetup: PropTypes.func.isRequired,
    getThemeDefinition: PropTypes.func.isRequired
  })]).isRequired,
  children: PropTypes.node.isRequired
}

ThemeWrapper.defaultProps = {
  themePayload: {},
  themeOverride: theme => theme
}

export default ThemeWrapper
