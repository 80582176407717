import isStateSlicer from 'onyx-common/isStateSlicer'
import isFunction from 'onyx-common/isFunction'
import parseStateSlicer from 'onyx-common/parseStateSlicer'
import isPlainObject from 'onyx-common/isPlainObject'

const normalizeResult = (result, stateSlicer) => {
  if (!isStateSlicer(stateSlicer)) return result

  const [finalStateSlicer] = parseStateSlicer(stateSlicer)

  return {
    data: finalStateSlicer(result.data)
  }
}

const wrapPromise = (prm, handleError) => {
  let promise = prm
  let cancelFunc
  if (isPlainObject(prm)) {
    promise = prm.promise
    cancelFunc = prm.cancel
  }

  let status = 'pending'
  let isComplete = false
  let result
  const suspender = promise
    .then(r => {
      status = 'success'
      result = {
        data: r.data
      }
      isComplete = true
      return result
    })
    .catch(e => {
      status = 'error'
      result = e
      isComplete = true
      if (isFunction(handleError)) handleError(e)
    })

  return {
    isComplete () {
      return isComplete
    },
    isError () {
      return status === 'error'
    },
    isSuccess () {
      return status === 'success'
    },
    isPending () {
      return status === 'pending'
    },
    getStatus () {
      return status
    },
    read (stateSlicer) {
      if (status === 'pending') {
        throw suspender
      } else if (status === 'error') {
        throw result
      } else if (status === 'success') {
        return normalizeResult(result, stateSlicer)
      }
    },
    getResult (stateSlicer) {
      return normalizeResult(result, stateSlicer)
    },
    getData (stateSlicer) {
      const normalizedResult = normalizeResult(result, stateSlicer)
      return normalizedResult?.data
    },
    updateResult (newResult) {
      result = newResult
      promise = Promise.resolve(result)
    },
    updateData (newData) {
      result = {
        ...result,
        data: newData
      }
      promise = Promise.resolve(result)
    },
    getPromise (stateSlicer) {
      return promise
        .then(res => normalizeResult(res, stateSlicer))
    },
    cancel () {
      if (isFunction(cancelFunc)) {
        return cancelFunc()
      }
    }
  }
}

export default wrapPromise
