import React from 'react'
import Context from './context'
import PropTypes from 'prop-types'

const AuthableProvider = ({ children, authable }) => (
  <Context.Provider value={authable}>{children}</Context.Provider>
)

AuthableProvider.propTypes = {
  authable: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default AuthableProvider
