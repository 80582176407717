import { useLayoutEffect } from 'react'

const useSetupRecipePlayground = (hydratedRecipe) => {
  useLayoutEffect(() => {
    hydratedRecipe.preheatPlayground()
    hydratedRecipe.bakePlayground()
  }, [hydratedRecipe])
}

export default useSetupRecipePlayground
