import React from 'react'
import getDisplayName from 'onyx-common/react-display-name'

const generateContextHOC = (ConsumerComponent, propName) => (WrappedComponent) => {
  const wrapped = props => (
    <ConsumerComponent>{value => {
      const finalProps = {
        ...props,
        [propName]: value
      }
      return <WrappedComponent {...finalProps} />
    }}</ConsumerComponent>
  )

  wrapped.displayName = `wrapped(${getDisplayName(WrappedComponent)})`
  return wrapped
}

export default generateContextHOC
