import lmsV2Playground from '../ingredients/lmsV2' // why on earth does this work and ./lmsV2 doesn't?

const getItem = (payload) => {
  const { currentUser } = window.getOnyxExternalData()

  const displayName = `${currentUser.firstName} ${currentUser.lastName}`
  const rankTitle = currentUser.rank
  const avatarUrl = currentUser.avatarUrl.large

  const onModuleClick = ({ courseId, id }) => {
    if (id) window.location.href = `/pyr_core/training_users/viewer#courseId=${courseId}&startModuleId=${id}`
    else window.location.href = `/pyr_core/training_users/viewer#courseId=${courseId}`
  }

  const item = {
    onModuleClick,
    paramPrefix: '',
    component: 'LMSV2',
    lzSelector: '.training_users_index #lms-lz',
    adaptorId: 'vibeCoreAdaptor',
    displayName,
    avatarUrl,
    rankTitle,
    withDrawer: true,
    withDrawerBadgeRibbon: false,
    withDrawerRankTitle: true,
    withModuleCountInCard: true,
    withRatingInCard: false,
    with3dSlider: true,
    stackEnabled: true,
    categoriesEnabled: true,
    courseCardVariant: 4,
    playAsQuickView: false,
    withQuickViewIcon: true,
    LMSViewerProps: {
      paramPrefix: '',
      adaptorId: 'vibeCoreAdaptor'
    }
  }

  return item
}

const getItemPlayground = (payload) => {
  const item = {
    ...payload._getItemPlayground(payload),
    adaptorId: ['playgroundAdaptor']
  }

  return item
}

export default {
  ...lmsV2Playground,
  id: 'stup.lmsV2Prod',
  getItem,
  getItemPlayground
}
