export default {
  taxons: [
    {
      id: 1,
      name: 'Categories',
      pretty_name: 'Categories',
      permalink: 'categories',
      parent_id: null,
      taxonomy_id: 1,
      taxons: [
        {
          id: 121,
          name: '50% Off Sale',
          pretty_name: 'Categories -> 50% Off Sale',
          permalink: 'categories/50-percent-off-sale',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 126,
          name: 'Rejuveniqe Promo',
          pretty_name: 'Categories -> Rejuveniqe Promo',
          permalink: 'categories/rejuveniqe-promo',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 87,
          name: 'Colour Enhance Duos + £10 Rejuvabeads',
          pretty_name: 'Categories -> Colour Enhance Duos + £10 Rejuvabeads',
          permalink: 'categories/colour-enhance-duos-plus-10-rejuvabeads',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 83,
          name: 'Build Your Travel Size Kit',
          pretty_name: 'Categories -> Build Your Travel Size Kit',
          permalink: 'categories/build-your-travel-size-kit',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 113,
          name: 'Power is in Your Hands',
          pretty_name: 'Categories -> Power is in Your Hands',
          permalink: 'categories/get-your-styling-on',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 115,
          name: 'Promo Qualifier',
          pretty_name: 'Categories -> Promo Qualifier',
          permalink: 'categories/promo-qualifier-shampoo',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 117,
          name: 'Promotional Styling Product',
          pretty_name: 'Categories -> Promotional Styling Product',
          permalink: 'categories/promotional-styling-product',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 119,
          name: 'Promotional Conditioner',
          pretty_name: 'Categories -> Promotional Conditioner',
          permalink: 'categories/promotional-conditioner',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 2,
          name: '<strong>Promotions</strong>',
          pretty_name: 'Categories -> <strong>Promotions</strong>',
          permalink: 'categories/promotions',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 108,
          name: 'Mix and Match Madness!',
          pretty_name: 'Categories -> Mix and Match Madness!',
          permalink: 'categories/Mix-Match',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 30,
          name: 'All Products',
          pretty_name: 'Categories -> All Products',
          permalink: 'categories/all-products',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 127,
          name: 'Skincare',
          pretty_name: 'Categories -> Skincare',
          permalink: 'categories/skincare',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 102,
          name: 'Cyber Monday',
          pretty_name: 'Categories -> Cyber Monday',
          permalink: 'categories/cyber-monday',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 118,
          name: 'Promotional Shampoo',
          pretty_name: 'Categories -> Promotional Shampoo',
          permalink: 'categories/promotional-shampoo',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 97,
          name: 'Black Friday Sets',
          pretty_name: 'Categories -> Black Friday Sets',
          permalink: 'categories/black-friday-sets',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 122,
          name: 'not valid - 50% Off Sale',
          pretty_name: 'Categories -> not valid - 50% Off Sale',
          permalink: 'categories/50-percent-off-sale',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 120,
          name: 'Promotional Conditioner',
          pretty_name: 'Categories -> Promotional Conditioner',
          permalink: 'categories/promotional-conditioner',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 88,
          name: 'Colour Enhance Duos Combinations + Free Rejuvabeads',
          pretty_name: 'Categories -> Colour Enhance Duos Combinations + Free Rejuvabeads',
          permalink: 'categories/colour-enhance-duos-combinations-plus-free-rejuvabeads',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 32,
          name: 'Flash Sale - Single Shampoo',
          pretty_name: 'Categories -> Flash Sale - Single Shampoo',
          permalink: 'categories/1-shampoo-sale',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 33,
          name: 'Flash Sale - Double Shampoo',
          pretty_name: 'Categories -> Flash Sale - Double Shampoo',
          permalink: 'categories/2-shampoo-sale',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 34,
          name: 'Flash Sale - One Conditioner',
          pretty_name: 'Categories -> Flash Sale - One Conditioner',
          permalink: 'categories/flash-sale-one-conditioner',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 35,
          name: 'Flash Sale - Two Conditioners',
          pretty_name: 'Categories -> Flash Sale - Two Conditioners',
          permalink: 'categories/flash-sale-two-conditioners',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 31,
          name: 'Holiday Sets',
          pretty_name: 'Categories -> Holiday Sets',
          permalink: 'categories/holiday-catalog',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 5,
          name: 'Product Pack',
          pretty_name: 'Categories -> Product Pack',
          permalink: 'categories/product-pack',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 6,
          name: 'Starter Kit',
          pretty_name: 'Categories -> Starter Kit',
          permalink: 'categories/starter-kit',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 7,
          name: 'VIP Customer Registr',
          pretty_name: 'Categories -> VIP Customer Registr',
          permalink: 'categories/vip-customer-registr',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 8,
          name: 'Packaging',
          pretty_name: 'Categories -> Packaging',
          permalink: 'categories/packaging',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 96,
          name: 'Collection',
          pretty_name: 'Categories -> Collection',
          permalink: 'categories/collection',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 23,
          name: 'Systems',
          pretty_name: 'Categories -> Systems',
          permalink: 'categories/systems',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 21,
          name: 'Styling',
          pretty_name: 'Categories -> Styling',
          permalink: 'categories/styling-products',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 37,
          name: 'Duos',
          pretty_name: 'Categories -> Duos',
          permalink: 'categories/duos',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 19,
          name: 'Shampoos',
          pretty_name: 'Categories -> Shampoos',
          permalink: 'categories/shampoos',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 20,
          name: 'Conditioners',
          pretty_name: 'Categories -> Conditioners',
          permalink: 'categories/conditioners',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 27,
          name: 'Treatment',
          pretty_name: 'Categories -> Treatment',
          permalink: 'categories/treatment',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 28,
          name: 'Wellness',
          pretty_name: 'Categories -> Wellness',
          permalink: 'categories/wellness',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 29,
          name: 'Grooming',
          pretty_name: 'Categories -> Grooming',
          permalink: 'categories/grooming',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 112,
          name: 'Pet',
          pretty_name: 'Categories -> Pet',
          permalink: 'categories/pet',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 22,
          name: 'Finishing Product',
          pretty_name: 'Categories -> Finishing Product',
          permalink: 'categories/finishing-product',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 24,
          name: 'Samples & Marketing Materials',
          pretty_name: 'Categories -> Samples & Marketing Materials',
          permalink: 'categories/samples-and-pop',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 95,
          name: 'Festive',
          pretty_name: 'Categories -> Festive',
          permalink: 'categories/festive',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 26,
          name: 'Renewal Category',
          pretty_name: 'Categories -> Renewal Category',
          permalink: 'categories/renewal-category',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 36,
          name: 'Flexship Bonus Items',
          pretty_name: 'Categories -> Flexship Bonus Items',
          permalink: 'categories/flexship-bonus-items',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 38,
          name: 'Choose Your Only For You',
          pretty_name: 'Categories -> Choose Your Only For You',
          permalink: 'categories/choose-your-only-for-you',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 39,
          name: 'Only For You Products',
          pretty_name: 'Categories -> Only For You Products',
          permalink: 'categories/only-for-your-products',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 40,
          name: 'More Than a Hand Cream',
          pretty_name: 'Categories -> More Than a Hand Cream',
          permalink: 'categories/more-than-a-hand-cream',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 41,
          name: 'Back pack',
          pretty_name: 'Categories -> Back pack',
          permalink: 'categories/back-pack',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 42,
          name: 'Summer Boost',
          pretty_name: 'Categories -> Summer Boost',
          permalink: 'categories/free',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 84,
          name: 'Free Sample',
          pretty_name: 'Categories -> Free Sample',
          permalink: 'categories/free-sample',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 85,
          name: 'New node',
          pretty_name: 'Categories -> New node',
          permalink: 'categories/new-node',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 86,
          name: 'Colour Enhance Duos',
          pretty_name: 'Categories -> Colour Enhance Duos',
          permalink: 'categories/colour-enhance-duos',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 99,
          name: 'Test',
          pretty_name: 'Categories -> Test',
          permalink: 'categories/test',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        },
        {
          id: 116,
          name: 'Promo Qualifier Conditioner',
          pretty_name: 'Categories -> Promo Qualifier Conditioner',
          permalink: 'categories/promo-qualifier-conditioner',
          parent_id: 1,
          taxonomy_id: 1,
          taxons: []
        }
      ],
      product_count: 131
    },
    {
      id: 121,
      name: '50% Off Sale',
      pretty_name: 'Categories -> 50% Off Sale',
      permalink: 'categories/50-percent-off-sale',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 126,
      name: 'Rejuveniqe Promo',
      pretty_name: 'Categories -> Rejuveniqe Promo',
      permalink: 'categories/rejuveniqe-promo',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 87,
      name: 'Colour Enhance Duos + £10 Rejuvabeads',
      pretty_name: 'Categories -> Colour Enhance Duos + £10 Rejuvabeads',
      permalink: 'categories/colour-enhance-duos-plus-10-rejuvabeads',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 83,
      name: 'Build Your Travel Size Kit',
      pretty_name: 'Categories -> Build Your Travel Size Kit',
      permalink: 'categories/build-your-travel-size-kit',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 113,
      name: 'Power is in Your Hands',
      pretty_name: 'Categories -> Power is in Your Hands',
      permalink: 'categories/get-your-styling-on',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 9
    },
    {
      id: 115,
      name: 'Promo Qualifier',
      pretty_name: 'Categories -> Promo Qualifier',
      permalink: 'categories/promo-qualifier-shampoo',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 117,
      name: 'Promotional Styling Product',
      pretty_name: 'Categories -> Promotional Styling Product',
      permalink: 'categories/promotional-styling-product',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 119,
      name: 'Promotional Conditioner',
      pretty_name: 'Categories -> Promotional Conditioner',
      permalink: 'categories/promotional-conditioner',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 2,
      name: '<strong>Promotions</strong>',
      pretty_name: 'Categories -> <strong>Promotions</strong>',
      permalink: 'categories/promotions',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 108,
      name: 'Mix and Match Madness!',
      pretty_name: 'Categories -> Mix and Match Madness!',
      permalink: 'categories/Mix-Match',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 30,
      name: 'All Products',
      pretty_name: 'Categories -> All Products',
      permalink: 'categories/all-products',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 81
    },
    {
      id: 127,
      name: 'Skincare',
      pretty_name: 'Categories -> Skincare',
      permalink: 'categories/skincare',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 13
    },
    {
      id: 102,
      name: 'Cyber Monday',
      pretty_name: 'Categories -> Cyber Monday',
      permalink: 'categories/cyber-monday',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 118,
      name: 'Promotional Shampoo',
      pretty_name: 'Categories -> Promotional Shampoo',
      permalink: 'categories/promotional-shampoo',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 97,
      name: 'Black Friday Sets',
      pretty_name: 'Categories -> Black Friday Sets',
      permalink: 'categories/black-friday-sets',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 122,
      name: 'not valid - 50% Off Sale',
      pretty_name: 'Categories -> not valid - 50% Off Sale',
      permalink: 'categories/50-percent-off-sale',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 120,
      name: 'Promotional Conditioner',
      pretty_name: 'Categories -> Promotional Conditioner',
      permalink: 'categories/promotional-conditioner',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 88,
      name: 'Colour Enhance Duos Combinations + Free Rejuvabeads',
      pretty_name: 'Categories -> Colour Enhance Duos Combinations + Free Rejuvabeads',
      permalink: 'categories/colour-enhance-duos-combinations-plus-free-rejuvabeads',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 32,
      name: 'Flash Sale - Single Shampoo',
      pretty_name: 'Categories -> Flash Sale - Single Shampoo',
      permalink: 'categories/1-shampoo-sale',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 33,
      name: 'Flash Sale - Double Shampoo',
      pretty_name: 'Categories -> Flash Sale - Double Shampoo',
      permalink: 'categories/2-shampoo-sale',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 34,
      name: 'Flash Sale - One Conditioner',
      pretty_name: 'Categories -> Flash Sale - One Conditioner',
      permalink: 'categories/flash-sale-one-conditioner',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 35,
      name: 'Flash Sale - Two Conditioners',
      pretty_name: 'Categories -> Flash Sale - Two Conditioners',
      permalink: 'categories/flash-sale-two-conditioners',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 31,
      name: 'Holiday Sets',
      pretty_name: 'Categories -> Holiday Sets',
      permalink: 'categories/holiday-catalog',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 5,
      name: 'Product Pack',
      pretty_name: 'Categories -> Product Pack',
      permalink: 'categories/product-pack',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 4
    },
    {
      id: 6,
      name: 'Starter Kit',
      pretty_name: 'Categories -> Starter Kit',
      permalink: 'categories/starter-kit',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 7,
      name: 'VIP Customer Registr',
      pretty_name: 'Categories -> VIP Customer Registr',
      permalink: 'categories/vip-customer-registr',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 8,
      name: 'Packaging',
      pretty_name: 'Categories -> Packaging',
      permalink: 'categories/packaging',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 96,
      name: 'Collection',
      pretty_name: 'Categories -> Collection',
      permalink: 'categories/collection',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 23,
      name: 'Systems',
      pretty_name: 'Categories -> Systems',
      permalink: 'categories/systems',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 7
    },
    {
      id: 21,
      name: 'Styling',
      pretty_name: 'Categories -> Styling',
      permalink: 'categories/styling-products',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 13
    },
    {
      id: 37,
      name: 'Duos',
      pretty_name: 'Categories -> Duos',
      permalink: 'categories/duos',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 5
    },
    {
      id: 19,
      name: 'Shampoos',
      pretty_name: 'Categories -> Shampoos',
      permalink: 'categories/shampoos',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 14
    },
    {
      id: 20,
      name: 'Conditioners',
      pretty_name: 'Categories -> Conditioners',
      permalink: 'categories/conditioners',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 12
    },
    {
      id: 27,
      name: 'Treatment',
      pretty_name: 'Categories -> Treatment',
      permalink: 'categories/treatment',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 12
    },
    {
      id: 28,
      name: 'Wellness',
      pretty_name: 'Categories -> Wellness',
      permalink: 'categories/wellness',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 1
    },
    {
      id: 29,
      name: 'Grooming',
      pretty_name: 'Categories -> Grooming',
      permalink: 'categories/grooming',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 3
    },
    {
      id: 112,
      name: 'Pet',
      pretty_name: 'Categories -> Pet',
      permalink: 'categories/pet',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 3
    },
    {
      id: 22,
      name: 'Finishing Product',
      pretty_name: 'Categories -> Finishing Product',
      permalink: 'categories/finishing-product',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 24,
      name: 'Samples & Marketing Materials',
      pretty_name: 'Categories -> Samples & Marketing Materials',
      permalink: 'categories/samples-and-pop',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 44
    },
    {
      id: 95,
      name: 'Festive',
      pretty_name: 'Categories -> Festive',
      permalink: 'categories/festive',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 26,
      name: 'Renewal Category',
      pretty_name: 'Categories -> Renewal Category',
      permalink: 'categories/renewal-category',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 1
    },
    {
      id: 36,
      name: 'Flexship Bonus Items',
      pretty_name: 'Categories -> Flexship Bonus Items',
      permalink: 'categories/flexship-bonus-items',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 38,
      name: 'Choose Your Only For You',
      pretty_name: 'Categories -> Choose Your Only For You',
      permalink: 'categories/choose-your-only-for-you',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 39,
      name: 'Only For You Products',
      pretty_name: 'Categories -> Only For You Products',
      permalink: 'categories/only-for-your-products',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 40,
      name: 'More Than a Hand Cream',
      pretty_name: 'Categories -> More Than a Hand Cream',
      permalink: 'categories/more-than-a-hand-cream',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 41,
      name: 'Back pack',
      pretty_name: 'Categories -> Back pack',
      permalink: 'categories/back-pack',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 42,
      name: 'Summer Boost',
      pretty_name: 'Categories -> Summer Boost',
      permalink: 'categories/free',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 84,
      name: 'Free Sample',
      pretty_name: 'Categories -> Free Sample',
      permalink: 'categories/free-sample',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 85,
      name: 'New node',
      pretty_name: 'Categories -> New node',
      permalink: 'categories/new-node',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 86,
      name: 'Colour Enhance Duos',
      pretty_name: 'Categories -> Colour Enhance Duos',
      permalink: 'categories/colour-enhance-duos',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 99,
      name: 'Test',
      pretty_name: 'Categories -> Test',
      permalink: 'categories/test',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 116,
      name: 'Promo Qualifier Conditioner',
      pretty_name: 'Categories -> Promo Qualifier Conditioner',
      permalink: 'categories/promo-qualifier-conditioner',
      parent_id: 1,
      taxonomy_id: 1,
      taxons: [],
      product_count: 0
    },
    {
      id: 61,
      name: 'Products For...',
      pretty_name: 'Products For...',
      permalink: 'products-for-dot-dot-dot',
      parent_id: null,
      taxonomy_id: 2,
      taxons: [
        {
          id: 65,
          name: 'Hydration - [65] - [65]',
          pretty_name: 'Products For... -> Hydration - [65] - [65]',
          permalink: 'products-for-dot-dot-dot/hydration',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 67,
          name: 'Frizz',
          pretty_name: 'Products For... -> Frizz',
          permalink: 'products-for-dot-dot-dot/Frizz',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 82,
          name: 'Nourishment',
          pretty_name: 'Products For... -> Nourishment',
          permalink: 'products-for-dot-dot-dot/nourishment',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 68,
          name: 'Damage',
          pretty_name: 'Products For... -> Damage',
          permalink: 'products-for-dot-dot-dot/Damage',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 69,
          name: 'Oily Scalp/Hair',
          pretty_name: 'Products For... -> Oily Scalp/Hair',
          permalink: 'products-for-dot-dot-dot/oily hair',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 70,
          name: 'Density',
          pretty_name: 'Products For... -> Density',
          permalink: 'products-for-dot-dot-dot/Density',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 62,
          name: 'Volume',
          pretty_name: 'Products For... -> Volume',
          permalink: 'products-for-dot-dot-dot/volume',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 71,
          name: 'Curls',
          pretty_name: 'Products For... -> Curls',
          permalink: 'products-for-dot-dot-dot/Curls',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 72,
          name: 'Finishing',
          pretty_name: 'Products For... -> Finishing',
          permalink: 'products-for-dot-dot-dot/finishing',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 73,
          name: 'Styling',
          pretty_name: 'Products For... -> Styling',
          permalink: 'products-for-dot-dot-dot/styling',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 74,
          name: 'Blow Dry Styling',
          pretty_name: 'Products For... -> Blow Dry Styling',
          permalink: 'products-for-dot-dot-dot/blow-dry-styling',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 76,
          name: 'Color Toning',
          pretty_name: 'Products For... -> Color Toning',
          permalink: 'products-for-dot-dot-dot/color-toning',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 77,
          name: "Men's",
          pretty_name: "Products For... -> Men's",
          permalink: 'products-for-dot-dot-dot/mens',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 78,
          name: 'Shaving',
          pretty_name: 'Products For... -> Shaving',
          permalink: 'products-for-dot-dot-dot/shaving',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        },
        {
          id: 79,
          name: 'Kids',
          pretty_name: 'Products For... -> Kids',
          permalink: 'products-for-dot-dot-dot/kids',
          parent_id: 61,
          taxonomy_id: 2,
          taxons: []
        }
      ],
      product_count: 43
    },
    {
      id: 65,
      name: 'Hydration - [65] - [65]',
      pretty_name: 'Products For... -> Hydration - [65] - [65]',
      permalink: 'products-for-dot-dot-dot/hydration',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 9
    },
    {
      id: 67,
      name: 'Frizz',
      pretty_name: 'Products For... -> Frizz',
      permalink: 'products-for-dot-dot-dot/Frizz',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 6
    },
    {
      id: 82,
      name: 'Nourishment',
      pretty_name: 'Products For... -> Nourishment',
      permalink: 'products-for-dot-dot-dot/nourishment',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 3
    },
    {
      id: 68,
      name: 'Damage',
      pretty_name: 'Products For... -> Damage',
      permalink: 'products-for-dot-dot-dot/Damage',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 1
    },
    {
      id: 69,
      name: 'Oily Scalp/Hair',
      pretty_name: 'Products For... -> Oily Scalp/Hair',
      permalink: 'products-for-dot-dot-dot/oily hair',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 2
    },
    {
      id: 70,
      name: 'Density',
      pretty_name: 'Products For... -> Density',
      permalink: 'products-for-dot-dot-dot/Density',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 4
    },
    {
      id: 62,
      name: 'Volume',
      pretty_name: 'Products For... -> Volume',
      permalink: 'products-for-dot-dot-dot/volume',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 5
    },
    {
      id: 71,
      name: 'Curls',
      pretty_name: 'Products For... -> Curls',
      permalink: 'products-for-dot-dot-dot/Curls',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 5
    },
    {
      id: 72,
      name: 'Finishing',
      pretty_name: 'Products For... -> Finishing',
      permalink: 'products-for-dot-dot-dot/finishing',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 3
    },
    {
      id: 73,
      name: 'Styling',
      pretty_name: 'Products For... -> Styling',
      permalink: 'products-for-dot-dot-dot/styling',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 4
    },
    {
      id: 74,
      name: 'Blow Dry Styling',
      pretty_name: 'Products For... -> Blow Dry Styling',
      permalink: 'products-for-dot-dot-dot/blow-dry-styling',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 5
    },
    {
      id: 76,
      name: 'Color Toning',
      pretty_name: 'Products For... -> Color Toning',
      permalink: 'products-for-dot-dot-dot/color-toning',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 3
    },
    {
      id: 77,
      name: "Men's",
      pretty_name: "Products For... -> Men's",
      permalink: 'products-for-dot-dot-dot/mens',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 5
    },
    {
      id: 78,
      name: 'Shaving',
      pretty_name: 'Products For... -> Shaving',
      permalink: 'products-for-dot-dot-dot/shaving',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 0
    },
    {
      id: 79,
      name: 'Kids',
      pretty_name: 'Products For... -> Kids',
      permalink: 'products-for-dot-dot-dot/kids',
      parent_id: 61,
      taxonomy_id: 2,
      taxons: [],
      product_count: 3
    },
    {
      id: 103,
      name: 'Systems and Collections',
      pretty_name: 'Systems and Collections',
      permalink: 'home-hair-spas',
      parent_id: null,
      taxonomy_id: 3,
      taxons: [
        {
          id: 105,
          name: 'Volume',
          pretty_name: 'Systems and Collections -> Volume',
          permalink: 'home-hair-spas/volume',
          parent_id: 103,
          taxonomy_id: 3,
          taxons: []
        },
        {
          id: 128,
          name: 'Effortless Style',
          pretty_name: 'Systems and Collections -> Effortless Style',
          permalink: 'home-hair-spas/effortless-style',
          parent_id: 103,
          taxonomy_id: 3,
          taxons: []
        },
        {
          id: 104,
          name: 'Hydration',
          pretty_name: 'Systems and Collections -> Hydration',
          permalink: 'home-hair-spas/hydration',
          parent_id: 103,
          taxonomy_id: 3,
          taxons: []
        },
        {
          id: 129,
          name: 'Classic Confidence',
          pretty_name: 'Systems and Collections -> Classic Confidence',
          permalink: 'home-hair-spas/classic-confidence',
          parent_id: 103,
          taxonomy_id: 3,
          taxons: []
        },
        {
          id: 130,
          name: 'Magnify',
          pretty_name: 'Systems and Collections -> Magnify',
          permalink: 'home-hair-spas/magnify',
          parent_id: 103,
          taxonomy_id: 3,
          taxons: []
        },
        {
          id: 131,
          name: 'Advanced Hydrating',
          pretty_name: 'Systems and Collections -> Advanced Hydrating',
          permalink: 'home-hair-spas/advanced-hydrating',
          parent_id: 103,
          taxonomy_id: 3,
          taxons: []
        },
        {
          id: 106,
          name: 'Restructuring',
          pretty_name: 'Systems and Collections -> Restructuring',
          permalink: 'home-hair-spas/restructuring',
          parent_id: 103,
          taxonomy_id: 3,
          taxons: []
        }
      ],
      product_count: 20
    },
    {
      id: 105,
      name: 'Volume',
      pretty_name: 'Systems and Collections -> Volume',
      permalink: 'home-hair-spas/volume',
      parent_id: 103,
      taxonomy_id: 3,
      taxons: [],
      product_count: 3
    },
    {
      id: 128,
      name: 'Effortless Style',
      pretty_name: 'Systems and Collections -> Effortless Style',
      permalink: 'home-hair-spas/effortless-style',
      parent_id: 103,
      taxonomy_id: 3,
      taxons: [],
      product_count: 3
    },
    {
      id: 104,
      name: 'Hydration',
      pretty_name: 'Systems and Collections -> Hydration',
      permalink: 'home-hair-spas/hydration',
      parent_id: 103,
      taxonomy_id: 3,
      taxons: [],
      product_count: 3
    },
    {
      id: 129,
      name: 'Classic Confidence',
      pretty_name: 'Systems and Collections -> Classic Confidence',
      permalink: 'home-hair-spas/classic-confidence',
      parent_id: 103,
      taxonomy_id: 3,
      taxons: [],
      product_count: 2
    },
    {
      id: 130,
      name: 'Magnify',
      pretty_name: 'Systems and Collections -> Magnify',
      permalink: 'home-hair-spas/magnify',
      parent_id: 103,
      taxonomy_id: 3,
      taxons: [],
      product_count: 3
    },
    {
      id: 131,
      name: 'Advanced Hydrating',
      pretty_name: 'Systems and Collections -> Advanced Hydrating',
      permalink: 'home-hair-spas/advanced-hydrating',
      parent_id: 103,
      taxonomy_id: 3,
      taxons: [],
      product_count: 4
    },
    {
      id: 106,
      name: 'Restructuring',
      pretty_name: 'Systems and Collections -> Restructuring',
      permalink: 'home-hair-spas/restructuring',
      parent_id: 103,
      taxonomy_id: 3,
      taxons: [],
      product_count: 4
    }
  ],
  count: 77,
  total_count: 77,
  current_page: 0,
  per_page: '1000',
  pages: 1
}
