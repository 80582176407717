import ifPromise from 'onyx-common/ifPromise'
import hasKey from 'onyx-common/hasKey'

const ZEventsApiFeaturedItems = ({ prototype }) => {
  prototype.normalizeFeaturedItem = function (_data) {
    const data = this.normalizeData(_data)
    const mapProps = {
      id: 'id',
      name: 'title',
      url: ['url', val => this.normalizeUrl(val)],
      image_url: 'imageUrl',
      sorting_weight: 'sortingWeight',
      price: 'price'
    }

    return this.filterAndMapProps(data, mapProps)
  }

  prototype._createFeaturedItem = function ({ userId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/featured_items`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} FeaturedItem
   * @property {number} id - featured item id
   * @property {string} title - title
   * @property {string} url -  url to link to
   * @property {number} sortingWeight - priority of featured item (lighter is earlier in list)
   * @property {string} imageUrl - product image url
   * @property {number} price - product price
   */

  /**
   * @function createFeaturedItem
   * @param {object} payload - The featured item payload
   * @param {string} [payload.userId='me'] - userid to assign
   * @param {string} payload.title - title
   * @param {string} [payload.sortingWeight=0] - lighter is earlier in list
   * @param {string} [payload.description='n/a'] - description (we don't use it)
   * @param {number} payload.price - price of item
   * @param {string} payload.url - url to link to
   * @param {string} [payload.imageUrl] - product image url
   * @param {string} [payload.imageBase64] - product image base64 (one of this or imageUrl is required)
   * @returns {FeaturedItem}
   * @example
   *
   * createFeaturedItem({
   *   title: 'Brown Socks',
   *   price: 100,
   *   url: 'https://amazon.com/blah',
   *   price: 20.00,
   *   imageUrl: 'https://reallycoolimage.com/home.jpg'
   * })
   */

  prototype.createFeaturedItem = function (payload) {
    const {
      userId,
      title,
      sortingWeight = 0,
      url,
      imageUrl,
      imageBase64,
      price
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      name: title,
      sorting_weight: sortingWeight,
      description: 'n/a',
      url,
      price: parseInt(price)
    }

    if (hasKey(payload, 'imageUrl')) finalPayload.image_url = imageUrl
    if (hasKey(payload, 'imageBase64')) finalPayload.image_base64 = imageBase64

    const raw = ifPromise(payload, () => this._createFeaturedItem(finalPayload))

    return raw
      .then(res => this.normalizeFeaturedItem(res.data))
      .catch(error => this.onError('createFeaturedItem', error))
  }

  prototype._getFeaturedItem = function ({ userId, id }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/featured_items/${id}`),
      method: 'GET'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function getFeaturedItem
   * @param {object} payload - The featured item payload
   * @param {string} [payload.userId='me'] - userid to assign this echeck
   * @param {string} payload.id - featured item id
   * @returns {FeaturedItem}
   * @example
   *
   * getFeaturedItem({
   *   id: 34
   * })
   */

  prototype.getFeaturedItem = function (payload) {
    const {
      userId,
      id
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      id
    }

    const raw = ifPromise(payload, () => this._getFeaturedItem(finalPayload))
    return raw
      .then(res => this.normalizeFeaturedItem(res.data))
      .catch(error => this.onError('getFeaturedItem', error))
  }

  prototype._getFeaturedItems = function ({ userId, page }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/featured_items`),
      method: 'GET',
      data: { page }
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function getFeaturedItems
   * @param {object} [payload] - The featured item payload
   * @param {string} [payload.userId='me'] - userid to assign this echeck
   * @returns {FeaturedItem[]}
   * @example
   *
   * getFeaturedItem({
   *   id: 34
   * })
   */

  prototype.getFeaturedItems = function (payload) {
    const {
      userId,
      page
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      page
    }

    const raw = ifPromise(payload, () => this._getFeaturedItems(finalPayload))
    return raw
      .then(res => {
        return this.normalizeListData(res.data, this.normalizeFeaturedItem)
      })
      .catch(error => this.onError('getFeaturedItems', error))
  }

  prototype._updateFeaturedItem = function ({ userId, id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/featured_items/${id}`),
      method: 'PUT',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function updateFeaturedItem
   * @param {object} payload - The featured item payload
   * @param {number} payload.id - The featured item id
   * @param {string} [payload.userId='me'] - userid to assign this echeck
   * @param {string} [payload.title] - title
   * @param {string} [payload.sortingWeight=0] - lighter is earlier in list
   * @param {string} [payload.description='n/a'] - description (we don't use it)
   * @param {string} [payload.url] - url to link to
   * @param {string} [payload.imageUrl] - product image url
   * @param {string} [payload.imageBase64] - product image base64
   * @param {string} [payload.price] - product price
   * @returns {FeaturedItem} - featured item
   * @example
   *
   * updateFeaturedItem({
   *   title: 'Brown Socks',
   *   url: 'https://amazon.com/blah',
   *   imageUrl: 'https://reallycoolimage.com/home.jpg',
   *   price: 100
   * })
   */
  prototype.updateFeaturedItem = function (payload) {
    const {
      id,
      userId,
      title,
      sortingWeight = 0,
      url,
      imageUrl,
      imageBase64,
      price
    } = payload

    const finalPayload = {
      id,
      userId: this.normalizeUserId(userId),
      name: title,
      sorting_weight: sortingWeight,
      url,
      imageUrl,
      imageBase64,
      price
    }

    const raw = ifPromise(payload, () => this._updateFeaturedItem(finalPayload))
    return raw
      .then(res => this.normalizeFeaturedItem(res.data))
      .catch(error => this.onError('updateFeaturedItem', error))
  }

  prototype._deleteFeaturedItem = function ({ userId, id }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/featured_items/${id}`),
      method: 'DELETE'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function deleteFeaturedItem
   * @param {object} payload - The featured item payload
   * @param {string} [payload.userId='me'] - user id
   * @param {number} payload.id - featured item id
   * @returns {void}
   * @example
   *
   * deleteFeaturedItem({
   *   id: 123
   * })
   */

  prototype.deleteFeaturedItem = function (payload) {
    const finalPayload = {
      id: payload.id,
      userId: this.normalizeUserId(payload.userId)
    }
    const raw = ifPromise(payload, () => this._deleteFeaturedItem(finalPayload))
    return raw
      .catch(error => this.onError('deleteFeaturedItem', error))
  }
}

export default ZEventsApiFeaturedItems
