import React, { useCallback } from 'react'
import ErrorOverlay from 'onyx-ui/ErrorOverlay'
import { ErrorBoundary as ReactErrorBoundary } from 'onyx-common/react-error-boundary'
import componentPropType from 'onyx-common/componentPropType'
import childrenPropType from 'onyx-common/childrenPropType'
import noop from 'onyx-common/noop'
import PropTypes from 'prop-types'

const ErrorBoundary = (props) => {
  let {
    fallback: Fallback,
    FallbackProps = {},
    loadingComponent: LoadingComponent,
    LoadingComponentProps,
    showLoadingComponentOnError = false,
    retryCountRef,
    maxRetries = 0,
    onReset = noop,
    ...rest
  } = props

  if (!Fallback) Fallback = ErrorOverlay

  const finalOnReset = useCallback(() => {
    if (retryCountRef) ++retryCountRef.current
    return onReset()
  }, [onReset, retryCountRef])

  const fallbackRender = useCallback(payload => {
    return (
      <Fallback
        showLoadingComponent={showLoadingComponentOnError}
        loadingComponent={LoadingComponent}
        LoadingComponentProps={{ ...LoadingComponentProps, isLoading: true }}
        maxRetries={maxRetries}
        retryCount={retryCountRef?.current || 0}
        {...payload}
        {...FallbackProps}
      />
    )
  }, [Fallback, maxRetries, FallbackProps, LoadingComponent, LoadingComponentProps, retryCountRef, showLoadingComponentOnError])

  return (
    <ReactErrorBoundary {...rest} onReset={finalOnReset} fallbackRender={fallbackRender} />

  )
}

ErrorBoundary.propTypes = {
  children: childrenPropType,
  fallback: componentPropType,
  FallbackProps: PropTypes.object,
  loadingComponent: componentPropType,
  LoadingComponentProps: PropTypes.object,
  showLoadingComponentOnError: PropTypes.bool,
  maxRetries: PropTypes.number,
  onReset: PropTypes.func,
  retryCountRef: PropTypes.object
}

export default ErrorBoundary
