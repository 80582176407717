import Context from './context'
import ServicesProvider from './ServicesProvider'
import ServicesConsumer from './ServicesConsumer'
import withServices from './withServices'
import useServices from './useServices'

const ServicesContext = Context

export {
  ServicesProvider,
  ServicesConsumer,
  withServices,
  ServicesContext,
  useServices
}

export default ServicesProvider
