import ifPromise from 'onyx-common/ifPromise'

const ZEventsApiCreditBalance = ({ prototype }) => {
  prototype.normalizeCreditBalance = function (data) {
    return data.credit_balance
  }

  prototype._getCreditBalance = function ({ id }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${id}/credit_balance`),
      method: 'GET'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function getCreditBalance
   * @param {object} payload - The eChecks payload
   * @param {string} [payload.id='me'] - userid to assign this echeck
   * @returns {number} - credit balance
   * @example
   *
   * getCreditBalance()
   */
  prototype.getCreditBalance = function (payload = {}) {
    const finalPayload = {
      id: this.normalizeUserId(payload?.id)
    }

    const raw = ifPromise(payload, () => this._getCreditBalance(finalPayload))
    return raw
      .then(res => this.normalizeCreditBalance(res.data))
      .catch(error => this.onError('getCreditBalance', error))
  }
}

export default ZEventsApiCreditBalance
