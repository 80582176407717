import React, { useEffect, useState } from 'react'
import RouteComponent from './RouteComponent'
import useRouter from './useRouter'

/**
 * A component that accesses the current route entry from RoutingContext and renders
 * that entry.
 */
const RouterRenderer = () => {
  // Access the router
  const router = useRouter()

  const [routeEntry, setRouteEntry] = useState(router.get())
  // On mount subscribe for route changes
  useEffect(() => {
    const dispose = router.subscribe(nextEntry => {
      setRouteEntry(nextEntry)
    })
    return dispose

    // Note: this hook updates routeEntry manually; we exclude that variable
    // from the hook deps to avoid recomputing the effect after each change
    // triggered by the effect itself.
  }, [router])

  const entry = routeEntry.entry

  return (
    <RouteComponent
      component={entry.component}
      componentProps={entry.componentProps}
      prepared={entry.prepared}
      routeData={entry.routeData}
      scrollToTop={entry.scrollToTop}
    />
  )
}

export default RouterRenderer
