import stringify from 'onyx-common/stringify'
import unstringify from 'onyx-common/unstringify'

const DEFAULT_STORAGE_KEY = 'credentials'

const createLocalStorageAuthableStore = (storageKey) => {
  if (!storageKey) storageKey = DEFAULT_STORAGE_KEY

  const setCredentials = (creds) => {
    localStorage.setItem(storageKey, stringify(creds))
    return Promise.resolve(creds)
  }

  const getCredentials = () => {
    try {
      return Promise.resolve(unstringify(localStorage.getItem(storageKey)))
    } catch (e) {
      return Promise.resolve(undefined)
    }
  }

  const destroyCredentials = () => {
    localStorage.removeItem(storageKey)
    return Promise.resolve(undefined)
  }

  return {
    setCredentials,
    getCredentials,
    destroyCredentials
  }
}

export default createLocalStorageAuthableStore
