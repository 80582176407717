import Context from './context'
import QueriesProvider from './QueriesProvider'
import QueriesConsumer from './QueriesConsumer'
import withQueries from './withQueries'

import useQueries from './useQueries'

const QueriesContext = Context

export {
  QueriesProvider,
  QueriesConsumer,
  withQueries,
  QueriesContext,
  useQueries
}

export default QueriesProvider
