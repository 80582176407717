import React from 'react'
import AppWrapper from 'onyx-common/AppWrapper'
import getAppWrapperPayload from 'onyx-common/getAppWrapperPayload'
import _getServices from 'onyx-common/getServices'
import _getAuthable from 'onyx-common/getAuthable'
import { createRouter } from 'onyx-common/concurrent-router'
import getRoutesFromApps from 'onyx-common/getRoutesFromApps'
import getLayoutsFromApps from 'onyx-common/getLayoutsFromApps'
import getQueriesFromApps from 'onyx-common/getQueriesFromApps'
import getLayoutVarsFromApps from 'onyx-common/getLayoutVarsFromApps'
import getStoreFromApps from 'onyx-common/getStoreFromApps'
import associateServices from 'onyx-common/associateServices'
import createQueryHelper from 'onyx-common/createQueryHelper'
import useSetupPeApp from 'onyx-hooks/useSetupPeApp'

import ThemeFromPeRootStoreWrapper from 'onyx-common/ThemeFromPeRootStoreWrapper'

const generatePeAppComponent = (payload) => {
  const {
    layoutsOverride,
    storeDefinitionOverride,
    themeOverride,
    queriesOverride,
    routesOverride,
    layoutVarsOverride,
    themeWrapperComponent = ThemeFromPeRootStoreWrapper,
    config,
    recipes,
    apps,
    getServices = _getServices,
    getAuthable = _getAuthable
  } = payload

  const authablePayload = {
    callbacks: {
      onLogin: null,
      onLogout: null
    },
    config
  }

  const authable = getAuthable(authablePayload)
  const services = getServices({ config, authable })

  const storePayload = { services, config }
  const store = getStoreFromApps({ apps, storePayload, storeDefinitionOverride })

  associateServices(services, { store })

  const queryHelper = createQueryHelper({ config, services })

  const resetCacheHelper = (res) => {
    queryHelper.resetCache()
    return res
  }

  authablePayload.callbacks.onLogin = resetCacheHelper
  authablePayload.callbacks.onLogout = resetCacheHelper

  const queriesPayload = { config, store }
  const queries = getQueriesFromApps({ apps, queriesPayload, queriesOverride })

  const layoutsPayload = {
    queries,
    config,
    queryHelper
  }

  const layouts = getLayoutsFromApps({ apps, layoutsPayload, layoutsOverride })

  const routesPayload = {
    config,
    layouts,
    queries,
    queryHelper,
    store
  }

  const routes = getRoutesFromApps({ apps, routesPayload, routesOverride })
  const router = createRouter({ routes })

  const layoutVarsPayload = {}
  const layoutVars = getLayoutVarsFromApps({ apps, layoutVarsPayload, layoutVarsOverride })

  const appWrapperPayload = getAppWrapperPayload({
    authable,
    layoutVars,
    layouts,
    config,
    apps,
    services,
    router,
    queries,
    queryHelper,
    store,
    themeOverride,
    themeWrapperComponent,
    recipes
  })

  const setupPeAppPayload = {
    store,
    recipes,
    config,
    queryHelper,
    authable
  }

  const App = () => {
    useSetupPeApp(setupPeAppPayload)

    return (
      <AppWrapper {...appWrapperPayload} />
    )
  }

  return App
}

export default generatePeAppComponent
