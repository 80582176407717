import React from 'react'
import useSetupRecipePlayground from 'onyx-hooks/useSetupRecipePlayground'
import hydratedRecipePropType from 'onyx-common/hydratedRecipePropType'

const generateRecipePlaygroundComponent = (ViewComponent) => {
  const RecipePlaygroundComponent = (props) => {
    const { hydratedRecipe } = props

    useSetupRecipePlayground(hydratedRecipe)

    return (
      <ViewComponent hydratedRecipe={hydratedRecipe} />
    )
  }

  RecipePlaygroundComponent.propTypes = {
    hydratedRecipe: hydratedRecipePropType
  }
  RecipePlaygroundComponent.displayName = 'RecipePlaygroundComponent'

  return RecipePlaygroundComponent
}

export default generateRecipePlaygroundComponent
