import normalizeDate from 'onyx-common/normalizeDate'

const isPastDate = (test, compare = new Date()) => {
  const dt = normalizeDate(test)
  const dtCompare = normalizeDate(compare)

  return dt < dtCompare
}

export default isPastDate
