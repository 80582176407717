import React, { useRef, useMemo } from 'react'
import Box from 'onyx-ui/Box'
import Button from 'onyx-ui/Button'
import styleClasses from './styles'
import makeStyles from 'onyx-common/makeStyles'
import useComponentSize from 'onyx-hooks/useComponentSize'
import ErrorOutlineIcon from 'onyx-icons/ErrorOutline'
import componentPropType from 'onyx-common/componentPropType'
import PropTypes from 'prop-types'
import isUndefined from 'onyx-common/isUndefined'

const useStyles = makeStyles(styleClasses)

const ErrorOverlay = (props) => {
  const {
    // error,
    // componentStack,
    resetErrorBoundary,
    loadingComponent: LoadingComponent,
    LoadingComponentProps = {},
    showLoadingComponent = false,
    maxRetries,
    retryCount
  } = props

  const classes = useStyles()

  const parentRef = useRef(null)
  const { width } = useComponentSize(parentRef)

  const fontSize = useMemo(() => {
    switch (width) {
      case width < 300: return 'small'
      case width >= 300 && width < 600: return 'medium'
      case width >= 600: return 'large'
    }
  }, [width])

  const canRetry = useMemo(() => isUndefined(maxRetries) || maxRetries > retryCount, [maxRetries, retryCount])

  return (
    <Box ref={parentRef}>
      <Box className={classes.container}>
        <ErrorOutlineIcon
          color="primary"
          fontSize={fontSize}
          className={classes.icon}
        />
        {canRetry && <Button onClick={resetErrorBoundary} size={fontSize}>Retry?</Button>}
      </Box>
      {showLoadingComponent && <LoadingComponent {...LoadingComponentProps} />}
    </Box>
  )
}

ErrorOverlay.propTypes = {
  error: PropTypes.any,
  componentStack: PropTypes.any,
  resetErrorBoundary: PropTypes.func,
  loadingComponent: componentPropType,
  LoadingComponentProps: PropTypes.object,
  showLoadingComponent: PropTypes.bool,
  maxRetries: PropTypes.number,
  retryCount: PropTypes.number
}

export default ErrorOverlay
