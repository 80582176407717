import ifPromise from 'onyx-common/ifPromise'

const ZEventsApiEventInterest = ({ prototype }) => {
  prototype._createEventInterest = function ({ userId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/event_interests`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function createEventInterest
   * @param {object} payload - The event interest payload
   * @param {string} [payload.userId='me'] - userid to assign
   * @param {number} payload.id - event id
   * @returns {void}
   * @example
   *
   * createEventInterest({
   *   eventId: 100
   * })
   */

  prototype.createEventInterest = function (payload) {
    const {
      userId,
      eventId
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      event_id: eventId
    }

    const raw = ifPromise(payload, () => this._createEventInterest(finalPayload))
    return raw
      .catch(error => this.onError('createEventInterest', error))
  }

  prototype._deleteEventInterest = function ({ userId, eventId }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/event_interests/${eventId}`),
      method: 'DELETE'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function deleteEventInterest
   * @param {object} payload - The event interest payload
   * @param {string} [payload.userId='me'] - userid to assign
   * @param {number} payload.id - event id
   * @returns {void}
   * @example
   *
   * deleteEventInterest({
   *   eventId: 100
   * })
   */

  prototype.deleteEventInterest = function (payload) {
    const {
      userId,
      eventId
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      eventId
    }

    const raw = ifPromise(payload, () => this._deleteEventInterest(finalPayload))
    return raw
      .catch(error => this.onError('deleteEventInterest', error))
  }
}

export default ZEventsApiEventInterest
