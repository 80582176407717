const styles = theme => {
  return {
    container: {
      position: 'absolute',
      backgroundColor: 'rgba(242, 242, 242, .8)',
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      left: 0,
      zIndex: 10000
    },
    icon: {
      margin: 'initial !important'
    }
  }
}

export default styles
