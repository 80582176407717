const createEphermeralAuthableStore = () => {
  let cache

  const setCredentials = (creds) => {
    cache = creds
    return Promise.resolve(cache)
  }

  const getCredentials = () => {
    return Promise.resolve(cache)
  }

  const destroyCredentials = () => {
    cache = undefined
    return Promise.resolve()
  }
  return {
    setCredentials,
    getCredentials,
    destroyCredentials
  }
}

export default createEphermeralAuthableStore
