import deepEqual from 'onyx-common/deep-equal'
import isStateSlicer from 'onyx-common/isStateSlicer'

const defaultShouldUpdate = (prev, curr) => {
  return !deepEqual(prev, curr)
}

const defaultStateSlicer = (data) => {
  return data
}

const parseStateSlicer = stateSlicer => {
  if (!isStateSlicer(stateSlicer)) {
    return [
      defaultStateSlicer,
      defaultShouldUpdate
    ]
  }

  const finalStateSlicer = (data) => {
    return stateSlicer[0](data)
  }

  if (stateSlicer.length === 1) {
    // simple stateSlicer
    const shouldUpdate = (prevData, currData) => {
      return !deepEqual(finalStateSlicer(prevData), finalStateSlicer(currData))
    }
    return [
      finalStateSlicer,
      shouldUpdate
    ]
  }

  return [
    finalStateSlicer,
    stateSlicer[1]
  ]
}

export default parseStateSlicer
