import ifPromise from 'onyx-common/ifPromise'

const ZEventsApi = ({ prototype }) => {
  prototype._createSurvey = function ({ ...rest }) {
    const payload = {
      url: this.getUrl('/api/v1/surveys'),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }
  prototype.createSurvey = function (payload) {
    const raw = ifPromise(payload, () => this._createSurvey(payload))
    return raw
      .then(res => Promise.resolve(this.normalizeAndCamelize(res)))
      .catch(error => {
        Promise.reject(Error('loginError', error))
      })
  }

  prototype._deleteSurvey = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/surveys/${id}`),
      method: 'DELETE',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }
  prototype.deleteSurvey = function (payload) {
    const raw = ifPromise(payload, () => this._deleteSurvey(payload))
    return raw
      .then(res => Promise.resolve(this.normalizeAndCamelize(res)))
      .catch(error => {
        Promise.reject(Error('loginError', error))
      })
  }

  prototype._getSurvey = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/surveys/${id}`),
      method: 'GET',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }
  prototype.getSurvey = function (payload) {
    const raw = ifPromise(payload, () => this._getSurvey(payload))
    return raw
      .then(res => Promise.resolve(this.normalizeAndCamelize(res)))
      .catch(error => {
        Promise.reject(Error('loginError', error))
      })
  }

  prototype._getSurveys = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl('/api/v1/surveys'),
      method: 'GET',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }
  prototype.getSurveys = function (payload) {
    const raw = ifPromise(payload, () => this._getSurveys(payload))
    return raw
      .then(res => Promise.resolve(this.normalizeAndCamelize(res)))
      .catch(error => {
        Promise.reject(Error('loginError', error))
      })
  }

  prototype._updateSurvey = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/surveys/${id}`),
      method: 'PUT',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }
  prototype.updateSurvey = function (payload) {
    const raw = ifPromise(payload, () => this._updateSurvey(payload))
    return raw
      .then(res => Promise.resolve(this.normalizeAndCamelize(res)))
      .catch(error => {
        Promise.reject(Error('loginError', error))
      })
  }
}

export default ZEventsApi
