import isFunction from 'onyx-common/isFunction'

const getLayoutVarsFromApps = ({ apps, layoutVarsPayload, layoutVarsOverride }) => {
  const arr = apps.map(a => a.getLayoutVars)

  const layoutVars = arr
    .map(func => func(layoutVarsPayload))
    .reduce((accumulator, obj, key) => {
      return {
        ...accumulator,
        ...obj
      }
    }, {})

  const finalLayoutVars = isFunction(layoutVarsOverride) ? layoutVarsOverride(layoutVars, layoutVarsPayload) : layoutVars

  return finalLayoutVars
}

export default getLayoutVarsFromApps
