import ifPromise from 'onyx-common/ifPromise'

const ZEventsApi = ({ prototype }) => {
  prototype.normalizeAccountManager = function (_data) {
    const data = this.normalizeData(_data)

    const mapProps = {
      id: 'id',
      type: 'type',
      email: 'email',
      name: 'name',
      avatar_url: 'avatarUrl',
      role: 'role'
    }

    return this.filterAndMapProps(data, mapProps)
  }

  /**
   * @typedef {object} AccountManager
   * @property {number} id - id,
   * @property {string} type - type,
   * @property {string} name - name,
   * @property {string} email - email,
   * @property {string} avatarUrl - avatarUrl,
   * @property {string} role - role
   */

  prototype._createAccountManager = function ({ userId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/account_managers`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function createAccountManager
   * @param {object} payload - The event wait list payload
   * @param {string} [payload.userId='me'] - userid to assign
   * @returns {AccountManager} accountManager object
   * @example
   *
   * createEventWaitList({
   *   userId: 'me',
   *   eventId: 100
   * })
   */
  prototype.createAccountManager = function (payload) {
    const {
      userId
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId)
    }

    const raw = ifPromise(payload, () => this._createAccountManager(finalPayload))
    return raw
      .then(res => this.normalizeAccountManager(res.data))
      .catch(error => this.onError('createAccountManager', error))
  }

  prototype._deleteAccountManager = function ({ userId, id }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/account_managers/${id}`),
      method: 'DELETE'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function deleteAccountManager
   * @param {object} payload - The account manager payload
   * @param {string} [payload.userId='me'] - userid to assign
   * @param {object} payload.id - The account manager id
   * @returns {void}
   * @example
   *
   * deleteAccountManager({
   *   userId: 'me',
   *   id: 100
   * })
   */
  prototype.deleteAccountManager = function (payload) {
    const {
      userId,
      id
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      id: id
    }

    const raw = ifPromise(payload, () => this._deleteAccountManager(finalPayload))
    return raw
      .catch(error => this.onError('deleteAccountManager', error))
  }

  prototype._getAccountManagers = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${id}/account_managers`),
      method: 'GET',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} getAccountManagersSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} getAccountManagersReturn
   * @property {AccountManager[]} accountManagers - list of account managers returned
   * @property {getAccountManagersSummary} listSummary - list summary object
   * @property {object} criteriaSummary - list summary object (empty)
   */

  /**
   * @function getAccountManagers
   * @param {object} payload - The payload
   * @param {string} [payload.userId='me'] - userid to assign
   * @param {number} [payload.page=1] - page
   * @returns {getAccountManagersReturn} getAccountManagersReturn
   * @example
   *
   * getAccountManagers({
   *   page: 2
   * })
   */
  prototype.getAccountManagers = function (payload) {
    const {
      userId,
      page = 1
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      page
    }

    const raw = ifPromise(payload, () => this._getAccountManagers(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeAccountManager))
      .catch(error => this.onError('getAccountManagers', error))
  }

  prototype._updateAccountManager = function ({ userId, id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/account_managers/${id}`),
      method: 'PUT',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function updateAccountManager
   * @param {object} payload - The user payload
   * @param {string} [payload.userId='me'] - user id
   * @param {string} payload.id - account manager id
   * @param {string} payload.role - role - moderator, ticket_taker
   * @returns {AccountManager} - account manager
   * @example
   *
   * updateAccountManager({
   *   userId: me,
   *   id: 123,
   *   role: 'ticket_taker'
   * })
   */
  prototype.updateAccountManager = function (payload) {
    const raw = ifPromise(payload, () => this._updateAccountManager(payload))
    return raw
      .then(res => this.normalizeAccountManager(res.data))
      .catch(error => this.onError('updateAccountManager', error))
  }
}

export default ZEventsApi
