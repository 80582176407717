import createAuthable from 'onyx-common/createAuthable'
import createLocalStorageAuthableStore from 'onyx-common/createLocalStorageAuthableStore'

const getAuthable = (payload) => {
  const store = createLocalStorageAuthableStore(payload.config?.authableStoreKey)
  const authable = createAuthable({ ...payload, store })
  return authable
}

export default getAuthable
