import normalizeQueryParams from 'onyx-common/normalizeQueryParams'
import urlencode from 'onyx-common/urlencode'
import format from 'onyx-common/string-template'
import isString from 'onyx-common/isString'
import isDefined from 'onyx-common/isDefined'
import isUndefined from 'onyx-common/isUndefined'
import qs from 'onyx-common/qs'

const buildUrl = (opts) => {
  // passthrough if a string is sent in
  if (isString(opts)) return opts

  let {
    contextUrl = window?.location?.href,
    url,
    data,
    query,
    queryReplace,
    urlMerge,
    hash,
    hashMerge,
    hashReplace
  } = opts

  if (isUndefined(url)) {
    const parsedUrl = qs.parseUrl(contextUrl)
    url = parsedUrl.url
  }

  // we want to move to using "query" going forwards
  if (isDefined(query)) data = query

  if (queryReplace) {
    const { query: existingQuery } = qs.parseUrl(contextUrl)

    data = {
      ...existingQuery,
      ...data
    }
  }

  if (data) {
    const joiner = url.includes('?') ? '&' : '?'

    if (isString(data)) {
      if (data.length > 0) {
        const finalJoiner = data.startsWith('?') ? '' : joiner
        url = [url, finalJoiner, data].join('')
      }
    } else {
      const normalizedQueryParams = normalizeQueryParams(data)
      const qstring = normalizedQueryParams.join('&')

      if (qstring.length > 0) {
        const finalJoiner = qstring.startsWith('?') ? '' : joiner
        url = [url, finalJoiner, qstring].join('')
      }
    }
  }

  if (urlMerge) {
    const normalizedUrlMerge = {}
    Object.keys(urlMerge).map(k => {
      normalizedUrlMerge[k] = urlencode(urlMerge[k])
    })

    url = format(url, normalizedUrlMerge)
  }

  if (hashReplace) {
    const { fragmentIdentifier } = qs.parseUrl(contextUrl, { parseFragmentIdentifier: true })
    const existingHashParams = qs.parse(fragmentIdentifier)

    hash = {
      ...existingHashParams,
      ...hash
    }
  }

  if (hash) {
    const hashJoiner = url.endsWith('#') ? '' : '#'

    if (isString(hash)) {
      if (hash.length > 0) {
        const finalHashJoiner = hash.startsWith('#') ? '' : hashJoiner
        url = [url, finalHashJoiner, data].join('')
      }
    } else {
      const normalizedHashParams = normalizeQueryParams(hash)
      const hashString = normalizedHashParams.join('&')

      if (hashString.length > 0) {
        const finalHashJoiner = hashString.startsWith('#') ? '' : hashJoiner
        url = [url, finalHashJoiner, hashString].join('')
      }
    }
  }

  if (hashMerge) {
    const normalizeHashMerge = {}
    Object.keys(hashMerge).map(k => {
      normalizeHashMerge[k] = urlencode(hashMerge[k])
    })

    url = format(url, normalizeHashMerge)
  }

  return url
}

export default buildUrl
