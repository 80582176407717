import { useEffect } from 'react'
import listenForEvent from 'onyx-common/listenForEvent'
import stopListeningForEvent from 'onyx-common/stopListeningForEvent'

const useListenForEvent = (eventCode, cb) => {
  useEffect(() => {
    listenForEvent(eventCode, cb)
    return () => {
      stopListeningForEvent(eventCode)
    }
  }, [])
}

export default useListenForEvent
