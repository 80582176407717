import JSResource from 'onyx-common/JSResource'

const getLayouts = () => {
  return {
    PassthroughLayout: {
      component: JSResource('PassthroughLayout', () => import('./layouts/PassthroughLayout')),
      prepare: () => {}
    },
    AdminLayout: {
      component: JSResource('AdminLayout', () => import('./layouts/AdminLayout')),
      prepare: () => {}
    },
    GalleryLayout: {
      component: JSResource('GalleryLayout', () => import('./layouts/GalleryLayout')),
      prepare: () => {}
    }
  }
}

export default getLayouts
