import React, { Fragment } from 'react'
import useStoreState from 'onyx-hooks/useStoreState'

import { getKeyedComponents } from '../../getAvailableComponents'

const availableComponents = getKeyedComponents()

const RootScene = () => {
  const items = useStoreState(state => state.pe.Root.itemsArr)

  return (
    <Fragment>
      {items.map((item, idx) => {
        const { component, ...rest } = item
        const entry = availableComponents[component]
        if (!entry) return null

        const Component = entry.Component
        return <Component key={idx} {...rest} />
      })}
    </Fragment>
  )
}

export default RootScene
