import emitEvent from 'onyx-common/emitEvent'
import isDefined from 'onyx-common/isDefined'

const emitOptimisticQueryDataUpdate = (eventCode, payload, actionPayload) => {
  const finalPayload = {
    eventCode,
    payload,
    actionPayload: isDefined(actionPayload) ? actionPayload : payload,
    isOptimistic: true
  }

  return emitEvent('QUERY_DATA_UPDATE', finalPayload)
}

export default emitOptimisticQueryDataUpdate
