import { PreloadJSResource, JSResource } from 'onyx-common/JSResource'
import components from './getAvailableComponents'
import RootScene from './scenes/RootScene'
PreloadJSResource('RootScene', RootScene)

const getRoutes = ({ layouts }) => {
  const PassthroughLayout = layouts['PassthroughLayout']
  const AdminLayout = layouts['AdminLayout']
  const GalleryLayout = layouts['GalleryLayout']

  let routes = {}
  components.forEach(comp => {
    routes[comp.playgroundPath] = {
      component: JSResource('PlaygroundScene', () => import('./scenes/PlaygroundScene')),
      prepare: () => comp,
      layout: PassthroughLayout,
      exact: true
    }
  })

  routes = {
    ...routes,
    '/admin': {
      component: JSResource('AdminScene', () => import('./scenes/AdminScene')),
      prepare: () => { },
      layout: AdminLayout,
      exact: true
    },
    '/gallery': {
      component: JSResource('GalleryScene', () => import('./scenes/GalleryScene')),
      prepare: () => { },
      layout: GalleryLayout,
      exact: true
    },
    '/recipe/:recipeId': {
      component: JSResource('RecipeScene', () => import('./scenes/RecipeScene')),
      prepare: ({ params }) => params,
      layout: PassthroughLayout,
      exact: false
    },
    '/': {
      component: JSResource('RootScene'),
      prepare: () => {},
      layout: PassthroughLayout,
      exact: false
    }
  }

  return routes
}

export default getRoutes
