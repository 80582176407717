import React, { Suspense, useRef } from 'react'
import PropTypes from 'prop-types'
import ConfigProvider from 'onyx-common/ConfigProvider'
import AuthableProvider from 'onyx-common/AuthableProvider'
import ServicesProvider from 'onyx-common/ServicesProvider'
import QueryHelperProvider from 'onyx-common/QueryHelperProvider'
import QueriesProvider from 'onyx-common/QueriesProvider'
import LayoutVarsProvider from 'onyx-common/LayoutVarsProvider'
import RecipesProvider from 'onyx-common/RecipesProvider'
import LightboxProvider from 'onyx-common/LightboxProvider'
import { RouterProvider, RouteRenderer, LayoutRenderer } from 'onyx-common/concurrent-router'
import ModalProvider from 'onyx-common/ModalProvider'
import CssReset from 'onyx-ui/CssReset'
import { SnackbarProvider } from 'onyx-common/snackbar'
import { StoreProvider } from 'onyx-common/store'
import BaseThemeWrapper from 'onyx-common/ThemeWrapper'
import RootRefProvider from 'onyx-common/RootRefProvider'
import ErrorBoundary from 'onyx-ui/ErrorBoundary'
import LocaleWatcher from 'onyx-common/LocaleWatcher'
import componentPropType from 'onyx-common/componentPropType'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Box from 'onyx-ui/Box'

import DateFnsUtils from 'onyx-common/date-fns'

const defaultLoader = () => <Box></Box>

const AppWrapper = (props) => {
  const {
    routeLoadingComponent: RouteLoader,
    authable,
    services,
    router,
    config,
    queryHelper,
    i18nSetup,
    setup,
    store,
    themeOverride,
    loadingComponent: Loader,
    layoutVars,
    queries,
    themeWrapperComponent: ThemeWrapper,
    recipes
  } = props

  const themeWrapperPayload = {
    themeOverride
  }

  const retryCountRef = useRef(0)

  i18nSetup(props)
  setup(props)

  const rootRef = useRef()
  rootRef.current = {
    levers: {}
  }

  return (
    <RootRefProvider rootRef={rootRef}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ConfigProvider config={config}>
          <RecipesProvider recipes={recipes}>
            <AuthableProvider authable={authable}>
              <ServicesProvider services={services}>
                <QueryHelperProvider queryHelper={queryHelper}>
                  <QueriesProvider queries={queries}>
                    <RouterProvider router={router}>
                      <StoreProvider store={store}>
                        <LocaleWatcher>
                          <LayoutVarsProvider layoutVars={layoutVars}>
                            <ThemeWrapper {...themeWrapperPayload}>
                              <CssReset>
                                <ErrorBoundary retryCountRef={retryCountRef} maxRetries={3}>
                                  <Suspense fallback={<Loader />}>
                                    <SnackbarProvider maxSnack={1}>
                                      <ModalProvider>
                                        <LightboxProvider>
                                          <LayoutRenderer>
                                            <Suspense fallback={<RouteLoader />}>
                                              <RouteRenderer />
                                            </Suspense>
                                          </LayoutRenderer>
                                        </LightboxProvider>
                                      </ModalProvider>
                                    </SnackbarProvider>
                                  </Suspense>
                                </ErrorBoundary>
                              </CssReset>
                            </ThemeWrapper>
                          </LayoutVarsProvider>
                        </LocaleWatcher>
                      </StoreProvider>
                    </RouterProvider>
                  </QueriesProvider>
                </QueryHelperProvider>
              </ServicesProvider>
            </AuthableProvider>
          </RecipesProvider>
        </ConfigProvider>
      </MuiPickersUtilsProvider>
    </RootRefProvider>
  )
}

AppWrapper.propTypes = {
  authable: PropTypes.object,
  routeLoadingComponent: componentPropType,
  services: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  queryHelper: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  i18nSetup: PropTypes.func.isRequired,
  setup: PropTypes.func.isRequired,
  theme: BaseThemeWrapper.propTypes.theme,
  themeOverride: BaseThemeWrapper.propTypes.themeOverride,
  layoutVars: PropTypes.object.isRequired,
  queries: PropTypes.object.isRequired,
  loadingComponent: componentPropType,
  themeWrapperComponent: componentPropType,
  recipes: PropTypes.array
}

AppWrapper.defaultProps = {
  maxSnack: 1,
  themeOverride: BaseThemeWrapper.defaultProps.themeOverride,
  loadingComponent: defaultLoader,
  routeLoadingComponent: defaultLoader
}

export default AppWrapper
