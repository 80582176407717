/* eslint-disable no-unused-vars */
import fetchWrap from 'onyx-common/fetchWrap'
import camelize from 'onyx-common/camelize'
import decamelize from 'onyx-common/decamelize'
import omitEmpty from 'onyx-common/omit-empty'
import ifPromise from 'onyx-common/ifPromise'
import stringify from 'onyx-common/stringify'
import log from 'onyx-common/logInspect'
import PreloadImage from 'onyx-ui/PreloadImage'
import categoriesData from 'onyx-services-vibe/VibeApi/VibeApiShop/responses/_getCategories/200-success/data'
import delay from 'onyx-common/delay'
// this is a temporary workaround for calls that need the header
const DEFAULT_SPREE_HEADERS = {
  'X-SPREE-TOKEN': 'e9c6b9b45376d18901d4a9e4a0d788e5b150dfd1d4738df4'
}

const VibeApiShop = ({ prototype }) => {
  const that = this

  prototype.getSpreeHeaders = function () {
    const store = this.getStore()
    if (!store) return DEFAULT_SPREE_HEADERS

    const spreeToken = store.getState()?.session?.spreeToken
    if (!spreeToken) return DEFAULT_SPREE_HEADERS

    return {
      'X-SPREE-TOKEN': spreeToken
    }
  }

  prototype._getProducts = function (criteria = {}) {
    const defaultCriteria = {
      page: 0,
      per_page: 10,
      taxons: null,
      keywords: null,
      sort_scope: 'default'
      // sku_keywords: null
    }

    const normalizedCriteria = omitEmpty({
      page: criteria?.page,
      per_page: criteria?.perPage,
      taxons: criteria?.categoryId,
      keywords: criteria?.search,
      sort_scope: criteria?.sort
      // sku_keywords: criteria?.skuKeywords
    })

    const finalCriteria = omitEmpty({
      ...defaultCriteria,
      ...normalizedCriteria
    })

    const payload = {
      url: this.getUrl('/shop/api/v1/products'),
      method: 'GET',
      headers: this.getSpreeHeaders(),
      data: finalCriteria
    }

    return fetchWrap(payload)
  }

  prototype.getProducts = function (payload) {
    const raw = ifPromise(payload, () => this._getProducts(payload))

    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)
      const { products } = camelized

      const normalizedProducts = products.map(item => {
        const ret = {
          id: item.id,
          name: item.name,
          price: item.displayPrice,
          imageUrl: this.wrapRelativeUrl(item.master.images[0].productUrl),
          imageUrl2: this.wrapRelativeUrl(item.master.images[0].largeUrl),
          avgRating: item.avgRating,
          reviewsCount: item.reviewsCount,
          topReview: item.reviews.find(review => review.rating === 5),
          description: item.description || '<<need to add description>>'
        }

        PreloadImage(ret.imageUrl)
        PreloadImage(ret.imageUrl2)

        return ret
      })

      const normalized = {
        products: normalizedProducts,
        listSummary: this.parseListSummary(camelized)
      }

      return {
        ...rest,
        data: normalized
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => Promise.reject(Error('getProductsError', error)))
  }

  // prototype._getRelatedProducts = function (criteria = {}) {
  // }

  prototype.getRelatedProducts = function (payload) {
    const raw = ifPromise(payload, () => this._getProducts(payload))

    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)
      const { products } = camelized

      const normalizedProducts = products.map(item => {
        const ret = {
          id: item.id,
          name: item.name,
          price: item.displayPrice,
          imageUrl: this.wrapRelativeUrl(item.master.images[0].productUrl),
          imageUrl2: this.wrapRelativeUrl(item.master.images[0].largeUrl),
          avgRating: item.avgRating,
          reviewsCount: item.reviewsCount,
          topReview: item.reviews.find(review => review.rating === 5)
        }

        PreloadImage(ret.imageUrl)
        PreloadImage(ret.imageUrl2)

        return ret
      })

      return {
        ...rest,
        data: normalizedProducts
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => Promise.reject(Error('getRelatedProductsError', error)))
  }

  prototype._getProduct = function ({ productId }) {
    const payload = {
      url: this.getUrl('/shop/api/v1/products/{productId}'),
      method: 'GET',
      headers: this.getSpreeHeaders(),
      urlMerge: {
        productId
      }
    }
    return fetchWrap(payload)
  }

  prototype.getProduct = function (payload) {
    const raw = ifPromise(payload, () => this._getProduct(payload))

    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)
      const product = camelized

      const normalized = {
        id: product.id,
        name: product.name,
        price: product.displayPrice,
        imageUrl: this.wrapRelativeUrl(product.master.images[0].productUrl),
        imageUrl2: this.wrapRelativeUrl(product.master.images[0].largeUrl),
        sku: product.master.sku,
        retailPrice: product.displayRetailPrice,
        size: null,
        ingredients: product.description.tabs.benefits,
        directions: product.description.tabs.direction,
        doesNotContain: product.description.tabs.doesNotContain,
        clinicalStudyInformation: product.description.tabs.clinicalStudyInformation,
        learnMore: product.productLearnMore,
        details: product.description.shortDescription,
        avgRating: product.avgRating,
        reviewsCount: product.reviewsCount
      }

      return {
        ...rest,
        data: normalized
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => {
        return Promise.reject(Error('getProductError', error))
      }
      )
  }

  prototype._getCategories = function (criteria = {}) {
    const {
      marketId = null,
      page = 0,
      perPage = 1000
    } = criteria

    const payload = {
      url: this.getUrl('/shop/api/v1/taxons?per_page={perPage}&page={page}'),
      method: 'GET',
      headers: this.getSpreeHeaders(),
      urlMerge: {
        perPage,
        page
      }
    }

    return fetchWrap(payload)
  }

  prototype.getCategories = function (payload) {
    const raw = ifPromise(payload, () => this._getCategories(payload))

    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)

      const categories = camelized.taxons.map((taxon, index) => {
        const { name, id, productCount } = taxon
        return {
          name,
          id,
          productCount
        }
      })

      const normalized = {
        categories
      }
      return {
        ...rest,
        data: normalized
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => Promise.reject(Error('getCategoriesError', error)))
  }

  prototype._getCategoriesForBar = function () {
    const data = categoriesData

    const payload = { response: { status: 200 }, data }

    return delay(payload, 0)
  }

  prototype.getCategoriesForBar = function (payload) {
    const raw = ifPromise(payload, () => this._getCategoriesForBar(payload))

    const normalize = ({ data, ...rest }) => {
      const filteredCategories = data.taxons.filter(taxon => taxon.product_count && taxon.taxons.length)
      const categoriesWithSubs = data.taxons.filter(taxon => taxon.product_count)
      const categoriesWithSubsIds = categoriesWithSubs.map(category => category.id)

      const categories = filteredCategories.map((category, index) => {
        return {
          id: category.id,
          name: category.name,
          subcategories: category.taxons.reduce((acc, subTaxon) => {
            if (categoriesWithSubsIds.includes(subTaxon.id)) {
              acc.push({
                id: subTaxon.id,
                title: subTaxon.name
              })
            }
            return acc
          }, [])
        }
      })

      const ret = {
        ...rest,
        data: categories
      }

      return ret
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => Promise.reject(Error('getCategoriesForBarError', error)))
  }

  prototype._getCountries = function () {
    const payload = {
      url: this.getUrl('/api/v1/countries'),
      method: 'GET',
      headers: this.getSpreeHeaders()
    }
    return fetchWrap(payload)
  }

  prototype.getCountries = function (payload) {
    const raw = ifPromise(payload, () => this._getCountries(payload))
    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)

      const countries = camelized.map((country, index) => {
        const { name, code, url } = country
        return {
          name,
          code,
          url
        }
      })

      const normalized = {
        countries
      }
      return {
        ...rest,
        data: normalized
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => Promise.reject(Error('getCountriesError', error)))
  }

  prototype._getStates = function ({ countryId }) {
    const payload = {
      url: this.getUrl('/api/v1/countries/{countryId}/states'),
      method: 'GET',
      headers: this.getSpreeHeaders(),
      urlMerge: {
        countryId
      }
    }

    return delay(() => fetchWrap(payload), 0)
  }

  prototype.getStates = function (payload) {
    const raw = ifPromise(payload, () => this._getStates(payload))
    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)

      const states = camelized.map((state, index) => {
        const { countryCode, name, code, url } = state
        return {
          countryCode,
          name,
          code,
          url
        }
      })

      const normalized = {
        states
      }
      return {
        ...rest,
        data: normalized
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => Promise.reject(Error('getStatesError', error)))
  }

  prototype._getProductReviews = function ({ productId }) {
    const payload = {
      url: this.getUrl('/shop/api/v1/products/{productId}/reviews'),
      method: 'GET',
      headers: this.getSpreeHeaders(),
      urlMerge: {
        productId
      }
    }
    return fetchWrap(payload)
  }

  prototype.getProductReviews = function (payload) {
    const raw = ifPromise(payload, () => this._getProductReviews(payload))

    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)
      const reviews = camelized

      return {
        ...rest,
        data: reviews
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => {
        return Promise.reject(Error('getProductReviewsError', error))
      })
  }

  prototype._getSavedAddresses = function () {
    const payload = {
      url: this.getUrl('/shop/api/v1/users/prev_addresses'),
      method: 'GET',
      headers: this.getSpreeHeaders()
    }

    return delay(() => fetchWrap(payload), 0)
    // return fetchWrap(payload)
  }

  prototype.getSavedAddresses = function () {
    const raw = ifPromise(null, () => this._getSavedAddresses())

    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)
      const addresses = camelized.addresses

      const normalizedAddresses = addresses.map(address => {
        return {
          id: address.id,
          firstname: address.firstname,
          lastname: address.lastname,
          address1: address.address1,
          address2: address.address2,
          zipcode: address.zipcode,
          city: address.city,
          county: address.county,
          countryId: address.countryName.iso,
          // stateId: 'ID',
          phone: address.phone,
          default: address.default
        }
      })

      return {
        ...rest,
        data: normalizedAddresses
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => {
        console.log(error)
        return Promise.reject(Error('getSavedAddressesError', error))
      })
  }

  prototype._getSavedCreditCards = function () {
    const payload = {
      url: this.getUrl('/shop/api/v1/users/prev_credit_cards'),
      method: 'GET',
      headers: this.getSpreeHeaders()
    }
    return fetchWrap(payload)
  }

  prototype.getSavedCreditCards = function () {
    const raw = ifPromise(null, () => this._getSavedCreditCards())

    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)
      const creditCards = camelized.creditCards

      return {
        ...rest,
        data: creditCards
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => {
        return Promise.reject(Error('getSavedCreditCardsError', error))
      })
  }

  prototype._deleteCreditCard = function (id) {
    const payload = {
      url: this.getUrl('/shop/api/v1/users/delete_existing_creditcard'),
      method: 'DELETE',
      headers: this.getSpreeHeaders(),
      data: {
        id,
        from: 'credit_card'
      }
    }
    return fetchWrap(payload)
  }

  prototype.deleteCreditCard = function (payload) {
    const raw = ifPromise(null, () => this._deleteCreditCard(payload))

    return raw
      .then(res => Promise.resolve())
      .catch(error => {
        return Promise.reject(Error('deleteCreditCardError', error))
      })
  }

  prototype._createShippingAddress = function (values) {
    const payload = {
      url: this.getUrl('/shop/api/v1/users/create_shipping_address'),
      method: 'POST',
      headers: this.getSpreeHeaders(),
      fetchOptions: { redirect: 'error' },
      requestType: 'test',
      data: {
        shipping_address_params: {
          firstname: values.firstname,
          lastname: values.lastname,
          address1: values.address1,
          address2: values.address2,
          zipcode: values.zipcode,
          city: values.city,
          county: values.county,
          country_id: values.countryId,
          state_id: values.stateId,
          phone: values.phone,
          company: values.company
        }
      }
    }

    // payload = { response: { status: 200 }, data: {} }
    // return delay(payload, 2000)

    return fetchWrap(payload)
  }

  prototype.createShippingAddress = function (values) {
    const raw = ifPromise(null, () => this._createShippingAddress(values))

    const normalize = (res) => res

    return raw
      .then(res => {
        console.log('CreateShippingResult', res)
        return Promise.resolve(normalize(res))
      })
      .catch(error => {
        return Promise.reject(Error('createShippingAddressError', error))
      })
  }

  prototype._deleteShippingAddress = function (id) {
    const payload = {
      url: this.getUrl('/shop/api/v1/users/delete_shipping_address'),
      method: 'DELETE',
      headers: this.getSpreeHeaders(),
      data: {
        id,
        from: 'shipping'
      }
    }
    return fetchWrap(payload)
  }

  prototype.deleteShippingAddress = function (payload) {
    const raw = ifPromise(null, () => this._deleteShippingAddress(payload))

    return raw
      .then(res => Promise.resolve())
      .catch(error => {
        return Promise.reject(Error('deleteShippingAddressError', error))
      })
  }

  prototype._createPaymentMethod = function (values) {
    let payload = {
      url: this.getUrl('/shop/api/v1/users/create_payment_method'),
      method: 'POST',
      headers: this.getSpreeHeaders(),
      fetchOptions: { redirect: 'error' },
      requestType: 'test',
      data: {
      }
    }

    payload = { response: { status: 200 }, data: {} }
    return delay(payload, 0)

    // return fetchWrap(payload)
  }

  prototype.createPaymentMethod = function (values) {
    const raw = ifPromise(null, () => this._createPaymentMethod(values))

    const normalize = (res) => res

    return raw
      .then(res => {
        console.log('CreatePaymentMethodResult', res)
        return Promise.resolve(normalize(res))
      })
      .catch(error => {
        return Promise.reject(Error('createPaymentMethodError', error))
      })
  }

  prototype._deletePaymentMethod = function (id) {
    const payload = {
      url: this.getUrl('/shop/api/v1/users/delete_shipping_address'),
      method: 'DELETE',
      headers: this.getSpreeHeaders(),
      data: {
        id,
        from: 'shipping'
      }
    }
    return fetchWrap(payload)
  }

  prototype.deletePaymentMethod = function (payload) {
    const raw = ifPromise(null, () => this._deletePaymentMethod(payload))

    return raw
      .then(res => Promise.resolve())
      .catch(error => {
        return Promise.reject(Error('deletePaymentMethodError', error))
      })
  }

  prototype._getCurrentOrder = function () {
    const payload = {
      url: this.getUrl('/shop/api/v1/orders/current'),
      method: 'GET',
      headers: this.getSpreeHeaders()
    }

    return fetchWrap(payload)
  }

  prototype.getCurrentOrder = function () {
    const raw = ifPromise(null, () => this._getCurrentOrder())

    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)

      return {
        ...rest,
        data: camelized
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => Promise.reject(Error('getCurrentOrderError', error)))
  }

  /* prototype._getOrders = function (criteria = {}) {
    const defaultCriteria = {
      page: 0,
      per: 25
    }

    const normalizedCriteria = omitEmpty({
      page: criteria?.page,
      per_page: criteria?.perPage
    })

    const finalCriteria = omitEmpty({
      ...defaultCriteria,
      ...normalizedCriteria
    })

    const payload = {
      url: this.getUrl('/shop/api/v1/orders/current'),
      method: 'GET',
      headers: this.getSpreeHeaders(),
      data: finalCriteria
    }

    return fetchWrap(payload)
  }

  */
  prototype._getOrders = function () {
    return this.getCurrentOrder()
  }

  prototype.getOrders = function (payload) {
    const raw = ifPromise(payload, () => this._getOrders(payload))

    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)
      const ordersArray = [camelized]
      const orders = ordersArray.map(order => (
        {
          id: order.id,
          number: order.number,
          date: order.createdAt,
          status: order.shipmentState,
          statusInfo: '',
          total: order.total,
          lineItems: order.lineItems.map(lineItem => (
            {
              id: lineItem.id,
              name: lineItem.variant.name,
              price: lineItem.price,
              quantity: lineItem.quantity,
              imageUrl: this.getUrl(lineItem.variant.images[0].productUrl)
            }
          ))
        }
      ))

      return {
        ...rest,
        data: orders
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => Promise.reject(Error('getOrdersError', error)))
  }

  prototype._getFlexshipOrders = function () {
    return this.getCurrentOrder()
  }

  prototype.getFlexshipOrders = function (payload) {
    const raw = ifPromise(payload, () => this._getFlexshipOrders(payload))

    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)
      const ordersArray = [camelized]
      const orders = ordersArray.map(order => (
        {
          id: order.id,
          number: order.number,
          frequency: '1',
          nextShipDate: new Date(),
          total: order.total,
          lineItems: order.lineItems.map(lineItem => (
            {
              id: lineItem.id,
              name: lineItem.variant.name,
              price: lineItem.price,
              quantity: lineItem.quantity,
              imageUrl: this.getUrl(lineItem.variant.images[0].productUrl)
            }
          ))
        }
      ))

      return {
        ...rest,
        data: orders
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => Promise.reject(Error('getFlexshipOrdersError', error)))
  }

  prototype._getFlexshipOrder = function () {
    return this.getCurrentOrder()
  }

  prototype.getFlexshipOrder = function (payload) {
    const raw = ifPromise(payload, () => this._getFlexshipOrder(payload))

    const normalize = ({ data, ...rest }) => {
      const camelized = camelize(data)
      const ordersArray = [camelized]
      const orders = ordersArray.map(order => (
        {
          id: order.id,
          number: order.number,
          frequency: '1',
          nextShipDate: new Date(),
          total: order.total,
          lineItems: order.lineItems.map(lineItem => (
            {
              id: lineItem.id,
              name: lineItem.variant.name,
              price: lineItem.price,
              quantity: lineItem.quantity,
              imageUrl: this.getUrl(lineItem.variant.images[0].productUrl)
            }
          ))
        }
      ))

      return {
        ...rest,
        data: orders
      }
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => Promise.reject(Error('getFlexshipOrderError', error)))
  }

  prototype._createReview = function (values) {
    const payload = {
      url: this.getUrl('/shop/api/v1/products/{product_id}/reviews'),
      method: 'POST',
      headers: this.getSpreeHeaders(),
      fetchOptions: { redirect: 'error' },
      urlMerge: {
        productId: values.productId
      },
      data: {
        product_id: values.productId,
        review: {
          name: values.reviewerName,
          title: values.title,
          rating: values.rating,
          review: values.review
        }
      }
    }

    // payload = { response: { status: 200 }, data: {} }
    // return delay(payload, 2000)

    return fetchWrap(payload)
  }

  prototype.createReview = function (values) {
    const raw = ifPromise(null, () => this._createReview(values))

    const normalize = (res) => res

    return raw
      .then(res => {
        console.log('CreateReviewResult', res)
        return Promise.resolve(normalize(res))
      })
      .catch(error => {
        return Promise.reject(Error('createReviewError', error))
      })
  }

  prototype._getCarouselBanners = function () {
    const data = [{
      id: 1,
      src: '',
      to: '/shop/join'
    }, {
      id: 2,
      src: '',
      to: '/shop/product/17'
    }, {
      id: 3,
      src: '',
      to: '/shop/product/17'
    }, {
      id: 4,
      src: '',
      to: '/shop/product/2'
    }]

    const payload = { response: { status: 200 }, data }

    return delay(payload, 0)
  }

  prototype.getCarouselBanners = function (payload) {
    const raw = ifPromise(payload, () => this._getCarouselBanners(payload))

    const normalize = ({ data, ...rest }) => {
      const banners = data

      const ret = {
        ...rest,
        data: banners
      }

      return ret
    }

    return raw
      .then(res => Promise.resolve(normalize(res)))
      .catch(error => Promise.reject(Error('getCarouselBannersError', error)))
  }
}

export default VibeApiShop
