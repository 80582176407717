import wrapRelativeUrl from 'onyx-common/wrapRelativeUrl'
import parseListSummary from 'onyx-common/parseListSummary'
// Every service must have at least a constructor
// Just to re-iterate, USE THE `function ()` SYNTAX HERE.
// NO ARROW FUNCTIONS.

// This is how the constructor must be handled.
const VibeApiBase = function ({ baseUrl }) {
  this.baseUrl = baseUrl
}

VibeApiBase.prototype.NAME = 'vibeApi'
VibeApiBase.prototype.store = null

VibeApiBase.prototype.getUrl = function (path) {
  return this.baseUrl + path
}

VibeApiBase.prototype.setStore = function (store) {
  this.store = store
}

VibeApiBase.prototype.getStore = function (store) {
  return this.store
}

VibeApiBase.prototype.wrapRelativeUrl = function (path) {
  return wrapRelativeUrl(path, this.baseUrl)
}

VibeApiBase.prototype.parseListSummary = parseListSummary

export default VibeApiBase
