import React from 'react'
import RootRefContext from './context'
import refPropType from 'onyx-common/refPropType'
import PropTypes from 'prop-types'

const RootRefProvider = ({ children, rootRef }) => {
  return (
    <RootRefContext.Provider value={rootRef}>{children}</RootRefContext.Provider>
  )
}

RootRefProvider.propTypes = {
  rootRef: refPropType.isRequired,
  children: PropTypes.node.isRequired
}

export default RootRefProvider
