import ifPromise from 'onyx-common/ifPromise'

const ZEventsApiPaymentChoices = ({ prototype }) => {
  prototype._getPaymentChoices = function ({ userId }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/payment_choices`),
      method: 'GET'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} getPaymentChoicesReturn
   * @property {CreditCard[]} creditCards - credit card entities
   * @property {BankAccount[]} bankAccounts - bank account entities
   */

  /**
   * @typedef {object} CreditCard
   * @property {number} id - credit card id
   * @property {string} cardType - type of card, visa, discover, amex, mc
   * @property {string} lastFour - last four digits of card
   * @property {number} expirationMonth - expiration month (1-12)
   * @property {number} expirationYear - expiration year
   * @property {boolean} isDefault - is this the default payment method?
   */

  /**
   * @typedef {object} BankAccount
   * @property {number} id - credit card id
   * @property {string} accountHolderName - name of account holder
   * @property {string} lastFour - last four digits of account
   * @property {boolean} isDefault - is this the default payment method?
   */

  /**
   * @function getPaymentChoices
   * @param {object} payload - The payload object
   * @param {number} [payload.page=1] - page of result set
   * @returns {getPaymentChoicesReturn} searchUsersReturn
   * @example
   * getPaymentChoices({
   *   page: 1
   * })
   */

  prototype.getPaymentChoices = function (payload = {}) {
    const finalPayload = {
      userId: this.normalizeUserId(payload?.userId)
    }

    const raw = ifPromise(payload, () => this._getPaymentChoices(finalPayload))
    return raw
      .then(res => {
        // AS: To keep the `this` context, you HAVE to expand the map syntax
        const creditCards = this.normalizeData(res.data.data.credit_cards).data.map(creditCard => this.normalizeCreditCard(creditCard))
        const bankAccounts = this.normalizeData(res.data.data.echecks).data.map(echeck => this.normalizeBankAccount(echeck))

        return {
          creditCards,
          bankAccounts
        }
      })
      .catch(error => this.onError('getPaymentChoices', error))
  }

  prototype.getDefaultPaymentChoice = function (payload) {
    const raw = this.getPaymentChoices(payload)

    return raw.then(data => {
      let defaultPaymentChoice

      data.creditCards.forEach(card => {
        if (!defaultPaymentChoice && card.isDefault) defaultPaymentChoice = card
      })

      data.bankAccounts.forEach(card => {
        if (!defaultPaymentChoice && card.isDefault) defaultPaymentChoice = card
      })

      return defaultPaymentChoice
    })
  }
}

export default ZEventsApiPaymentChoices
