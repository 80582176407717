// import generateShouldBakeByOnyxVariation from 'onyx-common/generateShouldBakeByOnyxVariation'
import generateIngredientInstance from 'onyx-common/generateIngredientInstance'

import {
  base,
  lmsV2Prod,
  lmsViewer
} from '../ingredients'

const ingredients = [
  generateIngredientInstance(base),
  generateIngredientInstance(lmsV2Prod),
  generateIngredientInstance(lmsViewer)
]

// const shouldBake = generateShouldBakeByOnyxVariation(['deployed', 'all'])
const shouldBake = () => true

export default {
  id: 'stup.deployed',
  ingredients,
  shouldBake
}
