import isPlainObject from 'onyx-common/isPlainObject'
import parseListSummary from 'onyx-common/parseListSummary'

// The intent for this file is to run api calls or scrapes from the browser context
const ClientContext = function () {}
ClientContext.prototype.NAME = 'clientContext'

// the fetchers expect to get a `data` key back, so we wrap if raw data is returned
ClientContext.prototype.run = function (cb) {
  // special case where we want to load in some paylod for queryHelper

  if (isPlainObject(cb)) cb = cb.func

  return cb().then(res => {
    let finalRes = res
    if (!isPlainObject(res) || !('data' in res)) {
      finalRes = {
        data: res
      }
    }

    // solve for list handling, this thing is getting slowly more featured
    if (isPlainObject(finalRes.data) && 'listSummary' in finalRes.data) {
      finalRes.data.listSummary = parseListSummary(finalRes.data.listSummary)
    }

    return finalRes
  })
}

export default ClientContext
