import isFunction from 'onyx-common/isFunction'

const delayReject = (cb, ms = 1000) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const normalizeCb = isFunction(cb) ? cb : () => cb
      reject(normalizeCb())
    }, ms)
  })
}

export default delayReject
