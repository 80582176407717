import assign from 'onyx-common/assign'

import {
  getThemeSetup,
  lightThemeId,
  darkThemeId,
  getThemeDefinition as getBaseThemeDefinition
} from '../Kwivvr'

import resetDarkTheme from '../utils/resetDarkTheme'

const themeId = 'KwivvrDark'

const getThemeDefinition = (themePayload) => {
  const baseTheme = getBaseThemeDefinition(themePayload)
  const resetDark = resetDarkTheme(baseTheme)

  const dark = {
    maxWidth: 1000,
    isDark: true,
    palette: {
      background: {
        paper: '#252525',
        default: '#191919'
      },
      text: {
        primary: '#e5e5e5',
        secondary: '#cccccc'
      },
      shadow: {
        default: '#535354'
      },
      border: {
        default: '#535354'
      },
      primary: {
        main: '#EC453D',
        contrastText: '#fff'
      },
      primaryAlt: {
        main: '#F2BE48',
        contrastText: '#fff'
      },
      secondary: {
        main: '#3551A1',
        contrastText: '#fff'
      },
      secondaryAlt: {
        main: '#67BFDC',
        contrastText: '#fff'
      }
    }
  }

  const opts = assign(resetDark, dark)

  // opts.shadows[0] = 'none'
  // opts.shadows[1] = '0px 2px 1px -1px rgba(192,192,192,0.2), 0px 1px 1px 0px rgba(192,192,192,0.14), 0px 1px 3px 0px rgba(192,192,192,0.12)'

  console.log(opts)
  return opts
}

const theme = {
  themeId,
  lightThemeId,
  darkThemeId,
  getThemeSetup,
  getThemeDefinition
}

export {
  themeId,
  lightThemeId,
  darkThemeId,
  getThemeSetup,
  getThemeDefinition
}

export default theme
