// DO NOT USE ARROW FUNCTIONS FOR PROTOTYPE ADDITIONS
// They don't have access to the rest of the `this` context from the compiled class
import ZEventsApi from './ZEventsApiBase'
import ZEventsApiAuth from './ZEventsApiAuth'
import ZEventsApiAdmin from './ZEventsApiAdmin'
import ZEventsApiCreditBalance from './ZEventsApiCreditBalance'
import ZEventsApiCreditCards from './ZEventsApiCreditCards'
import ZEventsApiEventMessages from './ZEventsApiEventMessages'
import ZEventsApiEvents from './ZEventsApiEvents'
import ZEventsApiTicketingTypes from './ZEventsApiTicketingTypes'
import ZEventsApiAddons from './ZEventsApiAddons'
import ZEventsApiEventTickets from './ZEventsApiEventTickets'
import ZEventsApiFeaturedItems from './ZEventsApiFeaturedItems'
import ZEventsApiFeed from './ZEventsApiFeed'
import ZEventsApiNotification from './ZEventsApiNotification'
import ZEventsApiUser from './ZEventsApiUser'
import ZEventsApiUserPreferences from './ZEventsApiUserPreferences'
import ZEventsApiSpeakerProfiles from './ZEventsApiSpeakerProfiles'
import ZEventsApiEventManagerAssignments from './ZEventsApiEventManagerAssignments'
import ZEventsApiAccountManagerAssignments from './ZEventsApiAccountManagerAssignments'
import ZEventsApiAccountManagers from './ZEventsApiAccountManagers'
import ZEventsApiEventManager from './ZEventsApiEventManager'
import ZEventsApiSurveys from './ZEventsApiSurveys'
import ZEventsApiSurveyAttempts from './ZEventsApiSurveyAttempts'
import ZEventsApiEventAttendees from './ZEventsApiEventAttendees'
import ZEventsApiEChecks from './ZEventsApiEChecks'
import ZEventsApiEventWatches from './ZEventsApiEventWatches'
import ZEventsApiPaymentChoices from './ZEventsApiPaymentChoices'
import ZEventsApiPayoutMethods from './ZEventsApiPayoutMethods'
import ZEventsApiEventWaitList from './ZEventsApiEventWaitList'
import ZEventsApiEventGroups from './ZEventsApiEventGroups'
import ZEventsApiCart from './ZEventsApiCart'
import ZEventsApiLanguages from './ZEventsApiLanguages'
import ZEventsApiEventInterest from './ZEventsApiEventInterest'
import ZEventsApiPrivacySettings from './ZEventsApiPrivacySettings'

// decorate the prototype
ZEventsApiAuth(ZEventsApi)
ZEventsApiAdmin(ZEventsApi)
ZEventsApiCreditBalance(ZEventsApi)
ZEventsApiCreditCards(ZEventsApi)
ZEventsApiEventMessages(ZEventsApi)
ZEventsApiEvents(ZEventsApi)
ZEventsApiEventTickets(ZEventsApi)
ZEventsApiFeaturedItems(ZEventsApi)
ZEventsApiFeed(ZEventsApi)
ZEventsApiNotification(ZEventsApi)
ZEventsApiUser(ZEventsApi)
ZEventsApiUserPreferences(ZEventsApi)
ZEventsApiSpeakerProfiles(ZEventsApi)
ZEventsApiEventManagerAssignments(ZEventsApi)
ZEventsApiAccountManagerAssignments(ZEventsApi)
ZEventsApiAccountManagers(ZEventsApi)
ZEventsApiEventManager(ZEventsApi)
ZEventsApiSurveys(ZEventsApi)
ZEventsApiSurveyAttempts(ZEventsApi)
ZEventsApiEventAttendees(ZEventsApi)
ZEventsApiEChecks(ZEventsApi)
ZEventsApiEventWatches(ZEventsApi)
ZEventsApiPaymentChoices(ZEventsApi)
ZEventsApiPayoutMethods(ZEventsApi)
ZEventsApiEventWaitList(ZEventsApi)
ZEventsApiEventGroups(ZEventsApi)
ZEventsApiCart(ZEventsApi)
ZEventsApiLanguages(ZEventsApi)
ZEventsApiEventInterest(ZEventsApi)
ZEventsApiTicketingTypes(ZEventsApi)
ZEventsApiAddons(ZEventsApi)
ZEventsApiPrivacySettings(ZEventsApi)

export default ZEventsApi
