import React from 'react'
import { TransitionGroup } from 'onyx-common/react-transition-group'
import { ModalProvider as BaseModalProvider } from 'onyx-common/react-modal-hook'
import PropTypes from 'prop-types'

const ModalProvider = ({ children }) => {
  return <BaseModalProvider rootComponent={TransitionGroup}>{children}</BaseModalProvider>
}

ModalProvider.propTypes = {
  children: PropTypes.node
}

export default ModalProvider
