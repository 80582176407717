const handleLocal = () => {
  const config = require('./config.local').default
  return {
    appMode: 'dev',
    ...config
  }
}

const handleProd = () => {
  const config = require('./config.prod').default
  return {
    appMode: 'prod',
    ...config
  }
}

const config = (process.env.NODE_ENV === 'production') ? handleProd() : handleLocal()

export default config
