import { lazy } from 'react'

const availableComponents = [
  {
    id: 'AvatarSpeedDial',
    icon: 'account_circle',
    title: 'Avatar',
    playgroundPath: '/playground/AvatarSpeedDial',
    AdminComponent: lazy(() => import('./components/AvatarSpeedDial/Admin')),
    GalleryComponent: lazy(() => import('./components/AvatarSpeedDial/Gallery')),
    Component: lazy(() => import('./components/AvatarSpeedDial'))
  },
  {
    id: 'ConsultantPopup',
    icon: 'menu',
    title: 'Consultant Popup',
    playgroundPath: '/playground/ConsultantPopup',
    AdminComponent: lazy(() => import('./components/NavColumn/Admin')),
    GalleryComponent: lazy(() => import('./components/NavColumn/Gallery')),
    Component: lazy(() => import('./components/ConsultantPopup'))
  },
  {
    id: 'ContentTabs',
    icon: 'tabs',
    title: 'Content Tabs',
    playgroundPath: '/playground/ContentTabs',
    AdminComponent: lazy(() => import('./components/ContentTabs/Admin')),
    Component: lazy(() => import('./components/ContentTabs'))
  },
  {
    id: 'NavColumn',
    icon: 'menu',
    title: 'Navigation Column',
    playgroundPath: '/playground/NavColumn',
    AdminComponent: lazy(() => import('./components/NavColumn/Admin')),
    GalleryComponent: lazy(() => import('./components/NavColumn/Gallery')),
    Component: lazy(() => import('./components/NavColumn'))
  },
  {
    id: 'NavHeader',
    icon: 'web_asset',
    title: 'Navigation Header',
    playgroundPath: '/playground/NavHeader',
    AdminComponent: lazy(() => import('./components/NavHeader/Admin')),
    GalleryComponent: lazy(() => import('./components/NavHeader/Gallery')),
    Component: lazy(() => import('./components/NavHeader'))
  },
  {
    id: 'SleekSlider',
    icon: 'view_carousel',
    title: 'Sleek Slider',
    playgroundPath: '/playground/SleekSlider',
    AdminComponent: lazy(() => import('./components/SleekSlider/Admin')),
    GalleryComponent: lazy(() => import('./components/SleekSlider/Gallery')),
    Component: lazy(() => import('./components/SleekSlider'))
  },
  {
    id: 'NotificationsPage',
    icon: 'notifications',
    title: 'Notifications',
    playgroundPath: '/playground/NotificationsPage',
    AdminComponent: lazy(() => import('./components/NotificationsPage/Admin')),
    GalleryComponent: lazy(() => import('./components/NotificationsPage/Gallery')),
    Component: lazy(() => import('./components/NotificationsPage'))
  },
  {
    id: 'PDFViewer',
    icon: 'picture_as_pdf',
    title: 'PDF Viewer',
    playgroundPath: '/playground/pdfviewer',
    AdminComponent: lazy(() => import('./components/PDFViewer/Admin')),
    GalleryComponent: lazy(() => import('./components/PDFViewer/Gallery')),
    Component: lazy(() => import('./components/PDFViewer'))
  },
  {
    id: 'MasonryArticles',
    icon: 'dashboard',
    title: 'Masonry Articles',
    playgroundPath: '/playground/MasonryArticles',
    AdminComponent: lazy(() => import('./components/MasonryArticles/Admin')),
    GalleryComponent: lazy(() => import('./components/MasonryArticles/Gallery')),
    Component: lazy(() => import('./components/MasonryArticles'))
  },
  {
    id: 'ActionResponseChainPage',
    icon: 'phonelink_ring',
    title: 'Action Response Chain',
    playgroundPath: '/playground/ActionResponseChainPage',
    AdminComponent: lazy(() => import('./components/ActionResponseChainPage/Admin')),
    GalleryComponent: lazy(() => import('./components/ActionResponseChainPage/Gallery')),
    Component: lazy(() => import('./components/ActionResponseChainPage'))
  },
  {
    id: 'LMSV2',
    icon: 'school',
    title: 'Learning Management System Version 2',
    playgroundPath: '/playground/LMSV2',
    AdminComponent: lazy(() => import('./components/LMSV2/Admin')),
    GalleryComponent: lazy(() => import('./components/LMSV2/Gallery')),
    Component: lazy(() => import('./components/LMSV2'))
  },
  {
    id: 'LMSDashboard',
    icon: 'dashboard',
    title: 'Learning Management System Admin Dashboard',
    playgroundPath: '/playground/LMSDashboard',
    AdminComponent: lazy(() => import('./components/LMSDashboard/Admin')),
    GalleryComponent: lazy(() => import('./components/LMSDashboard/Gallery')),
    Component: lazy(() => import('./components/LMSDashboard'))
  },
  {
    id: 'LMSViewer',
    icon: 'subscriptions',
    title: 'Learning Management System Viewer',
    playgroundPath: '/playground/LMSViewer',
    AdminComponent: lazy(() => import('./components/LMSViewer/Admin')),
    GalleryComponent: lazy(() => import('./components/LMSViewer/Gallery')),
    Component: lazy(() => import('./components/LMSViewer'))
  },
  {
    id: 'UniformGrid',
    icon: 'view_module',
    title: 'Uniform Grid',
    playgroundPath: '/playground/UniformGrid',
    AdminComponent: lazy(() => import('./components/UniformGrid/Admin')),
    GalleryComponent: lazy(() => import('./components/UniformGrid/Gallery')),
    Component: lazy(() => import('./components/UniformGrid'))
  },
  {
    id: 'KeenSlider',
    icon: 'burst_mode',
    title: 'Keen Slider',
    playgroundPath: '/playground/KeenSlider',
    AdminComponent: lazy(() => import('./components/KeenSlider/Admin')),
    GalleryComponent: lazy(() => import('./components/KeenSlider/Gallery')),
    Component: lazy(() => import('./components/KeenSlider'))
  },
  {
    id: 'ReportPage',
    icon: 'assignment',
    title: 'Report',
    playgroundPath: '/playground/ReportPage',
    AdminComponent: lazy(() => import('./components/ReportPage/Admin')),
    Component: lazy(() => import('./components/ReportPage'))
  },
  {
    id: 'SmartMessagingCorporate',
    icon: 'assignment',
    title: 'Smart Messaging',
    playgroundPath: '/playground/SmartMessagingCorporate',
    AdminComponent: lazy(() => import('./components/SmartMessagingCorporate/Admin')),
    Component: lazy(() => import('./components/SmartMessagingCorporate'))
  },
  {
    id: 'MarketingCalendarPage',
    icon: 'today',
    title: 'Marketing Calendar',
    playgroundPath: '/playground/MarketingCalendarPage',
    AdminComponent: lazy(() => import('./components/MarketingCalendarPage/Admin')),
    Component: lazy(() => import('./components/MarketingCalendarPage'))
  },
  {
    id: 'SurveyPage',
    icon: 'assignment',
    title: 'Survey',
    playgroundPath: '/playground/SurveyPage',
    AdminComponent: lazy(() => import('./components/SurveyPage/Admin')),
    Component: lazy(() => import('./components/SurveyPage'))
  },
  {
    id: 'SurveyPageV2',
    icon: 'assignment',
    title: 'Survey V2',
    playgroundPath: '/playground/SurveyPageV2',
    AdminComponent: lazy(() => import('./components/SurveyPageV2/Admin')),
    Component: lazy(() => import('./components/SurveyPageV2'))
  },
  {
    id: 'StandaloneCard',
    icon: 'vertical_split',
    title: 'Standalone Card',
    playgroundPath: '/playground/StandaloneCard',
    AdminComponent: lazy(() => import('./components/StandaloneCard/Admin')),
    Component: lazy(() => import('./components/StandaloneCard'))
  },
  {
    id: 'LiveButtons',
    icon: 'view_quilt',
    title: 'Live Buttons',
    playgroundPath: '/playground/LiveButtons',
    AdminComponent: lazy(() => import('./components/LiveButtons/Admin')),
    Component: lazy(() => import('./components/LiveButtons'))
  },
  {
    id: 'BuyingWithFriends',
    icon: 'shopping_basket',
    title: 'Buying with Friends',
    playgroundPath: '/playground/BuyingWithFriends',
    AdminComponent: lazy(() => import('./components/BuyingWithFriends/Admin')),
    Component: lazy(() => import('./components/BuyingWithFriends'))
  },
  {
    id: 'KwivvrClient',
    icon: 'instagram',
    title: 'KwivvrClient',
    playgroundPath: '/playground/KwivvrClient',
    AdminComponent: lazy(() => import('./components/KwivvrClient/Admin')),
    Component: lazy(() => import('./components/KwivvrClient'))
  },
  {
    id: 'StreamGenerator',
    icon: 'burst_mode',
    title: 'Stream Viewer',
    playgroundPath: '/playground/StreamGenerator',
    AdminComponent: lazy(() => import('./components/StreamGenerator/Admin')),
    Component: lazy(() => import('./components/StreamGenerator'))
  },
  {
    id: 'Red5Testing',
    icon: 'instagram',
    title: 'Red5Testing',
    playgroundPath: '/playground/Red5Testing',
    AdminComponent: lazy(() => import('./components/Red5Testing/Admin')),
    Component: lazy(() => import('./components/Red5Testing'))
  },
  {
    id: 'TranslationsManager',
    icon: 'instagram',
    title: 'TranslationsManager',
    playgroundPath: '/playground/TranslationsManager',
    AdminComponent: lazy(() => import('./components/TranslationsManager/Admin')),
    Component: lazy(() => import('./components/TranslationsManager'))
  }// {GENERATED}
]

const getKeyedComponents = () => {
  return availableComponents.reduce((acc, item) => {
    acc[item.id] = item
    return acc
  }, {})
}

export {
  getKeyedComponents,
  availableComponents
}

export default availableComponents
