import ifPromise from 'onyx-common/ifPromise'

const ZEventsApiEventMessages = ({ prototype }) => {
  prototype.normalizeEventMessage = function (_data) {
    const data = this.normalizeData(_data)

    const mapProps = {
      id: 'id',
      avatar_url: 'avatarUrl',
      name: 'commenter',
      timestamp: 'dateCommented',
      message: 'commentText',
      event_room_member_id: 'eventRoomMemberId'
    }

    const ret = this.filterAndMapProps(data, mapProps)
    return ret
  }

  prototype._createEventMessage = function ({ eventId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/messages`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} EventMessage
   * @property {number} id - message id
   * @property {string} avatarUrl - User picture
   * @property {string} commenter - User display name
   * @property {string} dateCommented - Message created date
   * @property {string} commentText - User posted message
   */

  /**
   * @function createEventMessage
   * @param {object} payload - The event message payload
   * @param {number} payload.eventId - id of the event
   * @param {string} payload.message - message to send
   * @returns {void}
   * @example
   *
   * createEventMessage({
   *   eventId: 1000,
   *   message: "Hi Wington!"
   * })
   */

  prototype.createEventMessage = function (payload) {
    const finalPayload = {
      eventId: payload.eventId,
      message: payload.message
    }

    const raw = ifPromise(payload, () => this._createEventMessage(finalPayload))
    return raw
      .catch(error => this.onError('createEventMessage', error))
  }

  prototype._getEventMessages = function ({ eventId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/messages`),
      method: 'GET',
      requestType: 'json',
      data: rest
    }
    return this.authenticatedFetchWrap(payload)
  }
  /**
   * @typedef {object} getEventMessageListSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} getEventMessagesReturn
   * @property {EventMessage[]} entries - list of event message returned
   * @property {getEventMessageListSummary} listSummary - list summary object
   * @property {object} criteriaSummary - criteria summary object (empty)
   */

  /**
   * @function getEventMessages
   * @param {object} payload - The payload object
   * @param {number} payload.eventId - event id
   * @param {number} [payload.page=1] - page of result set
   * @returns {getEventMessagesReturn} eventMessagesReturn
   * @example
   * getEventMessages({
   *   eventId: 123
   * })
   */

  prototype.getEventMessages = function (payload) {
    const finalPayload = {
      eventId: payload.eventId,
      page: payload?.page ? payload.page : 1
    }

    const raw = ifPromise(payload, () => this._getEventMessages(finalPayload))
    return raw
      .then(res => {
        const ret = this.normalizeListData(res.data, this.normalizeEventMessage)
        return {
          ...ret,
          entries: ret.entries.reverse()
        }
      })
      .catch(error => this.onError('getEventMessages', error))
  }
}

export default ZEventsApiEventMessages
