import ifPromise from 'onyx-common/ifPromise'

const ZEventsApiEventManager = ({ prototype }) => {
  prototype.normalizeEventManager = function (_data) {
    const data = this.normalizeData(_data)

    const mapProps = {
      id: ['id', val => parseInt(val)],
      email: 'email',
      name: 'name',
      avatar_url: 'avatarUrl',
      role: 'role'
    }

    return this.filterAndMapProps(data, mapProps)
  }

  /**
   * @typedef {object} EventManager
   * @property {number} id - EventWatch join id
   * @property {string} type - type
   * @property {string} email -email
   * @property {string} avatarUrl -email
   * @property {string} role - role
   */

  prototype._createEventManager = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${id}/managers`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function createEventManager
   * @param {object} payload - The event watch payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.userId - userId of the manager to assign
   * @param {string} payload.role - enum('ticket_taker', 'basic_cs')
   * @returns {EventManager} - event manager object
   * @example
   *
   * createEventManager({
   *   eventId: 1127,
   *   userId: 2838,
   *   role: 'ticket_taker'
   * })
   */
  prototype.createEventManager = function (payload) {
    const finalPayload = {
      id: payload.eventId,
      account_id: payload.userId,
      role: payload.role
    }

    const raw = ifPromise(payload, () => this._createEventManager(finalPayload))
    return raw
      .then(res => this.normalizeEventManager(res.data))
      .catch(error => this.onError('createEventManager', error))
  }

  /**
   * @function deleteEventManager
   * @param {object} payload - The event manager payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.userId - userId to remove from event manager role
   * @returns {void}
   * @example
   *
   * deleteEventManager({
   *   eventId: 100,
   *   userId: 3838
   * })
   */
  prototype.deleteEventManager = function (payload) {
    const finalPayload = {
      eventId: payload.eventId,
      userId: payload.userId
    }
    console.log('##', finalPayload)

    const raw = ifPromise(payload, () => this._deleteEventManager(finalPayload))
    return raw
      .catch(error => this.onError('deleteEventManager', error))
  }

  prototype._getEventManagers = function ({ eventId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/managers`),
      method: 'GET',
      requestType: 'json',
      data: rest
    }
    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} getEventManagersSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} getEventManagersReturn
   * @property {EventManager[]} entries - list of event managers returned
   * @property {getEventManagersSummary} listSummary - list summary object
   * @property {object} criteriaSummary - list summary object (empty)
   */

  /**
   * @function getEventManagers
   * @param {object} payload - The payload
   * @param {string} payload.eventId - event id
   * @param {number} [payload.page=1] - page
   * @returns {getEventManagersReturn} - getEventManagersReturn
   * @example
   *
   * getEventManagers({
   *   eventId: 1
   * })
   */
  prototype.getEventManagers = function (payload) {
    const finalPayload = {
      eventId: payload.eventId,
      page: payload?.page || 1
    }

    const raw = ifPromise(payload, () => this._getEventManagers(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeEventManager))
      .catch(error => this.onError('getEventManagers', error))
  }

  prototype._deleteEventManager = function ({ eventId, userId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/events/${eventId}/managers/${userId}`),
      method: 'DELETE',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function deleteEventManager
   * @param {object} payload - The event group payload
   * @param {number} payload.eventId - event id
   * @param {number} payload.userId - event manager id
   * @returns {void}
   * @example
   *
   * deleteEventManager({
   *   eventId: 1,
   *   userId: 123
   * })
   */
  prototype.deleteEventManager = function (payload) {
    const finalPayload = {
      userId: payload.userId,
      eventId: payload.eventId
    }
    const raw = ifPromise(payload, () => this._deleteEventManager(finalPayload))
    return raw
      .catch(error => this.onError('deleteEventManager', error))
  }
}

export default ZEventsApiEventManager
