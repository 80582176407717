const associateQueryHelperContext = ({ ctx, query }) => {
  const associationHelper = (q) => {
    return associateQueryHelperContext({ ctx, query: q })
  }

  const ret = {
    ...ctx,
    getKey: () => ctx.getKey(query),
    hasCache: () => ctx.hasCache(query),
    getBaseKey: () => ctx.getBaseKey(query),
    getBaseCache: () => ctx.getBaseCache(query),
    getCache: () => ctx.getCache(query),
    getPromise: () => ctx.getPromise(query),
    getData: () => ctx.getData(query),
    getResult: () => ctx.getResult(query),
    loadQuery: (force, handleError) => ctx.loadQuery(query, force, handleError),
    preloadQuery: (force, handleError) => ctx.preloadQuery(query, force, handleError),
    retryQuery: (opts) => ctx.retryQuery(query, opts),
    isComplete: () => ctx.isComplete(query),
    isPending: () => ctx.isPending(query),
    isError: () => ctx.isError(query),
    isSuccess: () => ctx.isSuccess(query),
    deleteCache: () => ctx.deleteCache(query),
    refreshQuery: (opts) => ctx.refreshQuery(query, opts),
    revalidateQuery: (opts) => ctx.revalidateQuery(query, opts),
    updateQueryData: (data, opts) => ctx.updateQueryData(query, data, opts),
    updateAndRefreshQuery: (data, opts) => ctx.updateAndRefreshQuery(query, data, opts),
    updateAndRevalidateQuery: (data, opts) => ctx.updateAndRevalidateQuery(query, data, opts),
    updateQueryResult: (results, opts) => ctx.updateQueryResults(query, results, opts),
    useResourceHook: (opts) => ctx.useResourceHook(query, opts),
    useHook: (opts) => ctx.useHook(query, opts),
    usePromiseHook: (opts) => ctx.usePromiseHook(query, opts),
    useDataHook: (opts) => ctx.useDataHook(query, opts),
    useIsRefreshingHook: (initial) => ctx.useIsRefreshingHook(query, initial),
    getNextQuery: () => ctx.getNextQuery(query),
    getPreviousQuery: () => ctx.getPreviousQuery(query),
    getNextQueryHelperBag: () => ctx.getNextQueryHelperBag(query, associationHelper),
    getPreviousQueryHelperBag: () => ctx.getPreviousQueryHelperBag(query, associationHelper),
    getNextPayload: () => ctx.getNextPayload(query),
    getPreviousPayload: () => ctx.getPreviousPayload(query),
    getPayload: () => ctx.getPayload(query),
    getParams: () => ctx.getParams(query),
    query
  }

  return ret
}

export default associateQueryHelperContext
