import { createRef } from 'react'
import isUndefined from 'onyx-common/isUndefined'
import uniqueInstanceId from 'onyx-common/uniqueInstanceId'
import getInstancePrefix from 'onyx-common/getInstancePrefix'

const generateIngredientInstance = (ingredient, { instanceId, instanceRef, paramPrefix } = {}) => {
  if (isUndefined(instanceId)) instanceId = uniqueInstanceId(ingredient.id)
  if (isUndefined(instanceRef)) instanceRef = createRef()
  if (isUndefined(paramPrefix)) paramPrefix = getInstancePrefix(instanceId)

  const generatedIngredientInstance = {
    ...ingredient,
    instanceId,
    instanceRef,
    paramPrefix
  }

  return generatedIngredientInstance
}

export default generateIngredientInstance
