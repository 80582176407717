import React from 'react'
import Context from './context'
import PropTypes from 'prop-types'

const ServicesProvider = ({ children, services }) => (
  <Context.Provider value={services}>{children}</Context.Provider>
)

ServicesProvider.propTypes = {
  services: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default ServicesProvider
