import VibeApi from 'onyx-services-vibe'
import KwivrrApi from 'onyx-services-kwivrr'
import ClientContext from 'onyx-services-client-context'

const getServices = (config) => {
  const vibeApi = new VibeApi({ baseUrl: config.vibeApiUrl })
  const kwivrrApi = new KwivrrApi({
    baseUrl: config.kwivrrApiUrl,
    actionCableUrl: config.kwivrrActionCableUrl,
    augmentFetchPayload: config?.augmentFetchPayload
  })
  const clientContext = new ClientContext()

  return {
    vibeApi,
    kwivrrApi,
    clientContext
  }
}

export default getServices
