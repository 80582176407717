import React from 'react'
import BaseThemeWrapper from 'onyx-common/ThemeWrapper'
import useStoreState from 'onyx-hooks/useStoreState'

const ThemeFromPeRootStoreWrapper = (props) => {
  const {
    children,
    themeOverride
  } = props

  const theme = useStoreState(state => state.pe.Root.theme)

  const themePayload = {}

  const themeWrapperPayload = {
    theme,
    themePayload,
    themeOverride
  }

  return (
    <BaseThemeWrapper {...themeWrapperPayload}>
      {children}
    </BaseThemeWrapper>
  )
}

ThemeFromPeRootStoreWrapper.propTypes = {
  themeOverride: BaseThemeWrapper.propTypes.themeOverride,
  children: BaseThemeWrapper.propTypes.children
}

ThemeFromPeRootStoreWrapper.defaultProps = {
  themeOverride: BaseThemeWrapper.defaultProps.themeOverride
}

export default ThemeFromPeRootStoreWrapper
