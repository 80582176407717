import injectJss from 'onyx-common/injectJss'

const injectGlobalJss = (styles, opts) => {
  const finalStyles = {
    '@global': {
      ...styles
    }
  }

  return injectJss(finalStyles, opts)
}

export default injectGlobalJss
