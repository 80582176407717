import useOnyx from 'onyx-hooks/useOnyx'
import useStoreState from 'onyx-hooks/useStoreState'

const useCurrentLocale = () => {
  const { getScope } = useOnyx()
  const scope = getScope()
  const locale = useStoreState(arr => scope(arr).locale)
  return locale
}

export default useCurrentLocale
