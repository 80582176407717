import ifPromise from 'onyx-common/ifPromise'

const ZEventsApiEventGroups = ({ prototype }) => {
  prototype.normalizeEventGroup = function (_data) {
    const data = this.normalizeData(_data)

    const mapProps = {
      id: 'id',
      title: 'title'
    }

    const ret = this.filterAndMapProps(data, mapProps)
    return ret
  }

  prototype._getEventGroups = function ({ userId }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/event_groups`),
      method: 'GET'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} EventGroup
   * @property {number} id - event group id
   * @property {string} title - event group id
   */

  /**
   * @typedef {object} getEventGroupsReturn
   * @property {EventGroup[]} eventGroups - list of event groups returned
   */

  /**
   * @function getEventGroups
   * @returns {getEventGroupsReturn} getEventGroupsReturn
   * @example
   * getEventGroups()
   */

  prototype.getEventGroups = function (payload = {}) {
    const finalPayload = {
      userId: this.normalizeUserId(payload.userId)
    }
    const raw = ifPromise(payload, () => this._getEventGroups(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeEventGroup))
      .catch(error => this.onError('getEventGroups', error))
  }

  prototype._createEventGroup = function ({ userId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/event_groups`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} EventGroup
   * @property {number} id - event group id
   * @property {string} title - group title
   */

  /**
   * @function createEventGroup
   * @param {object} payload - The event group payload
   * @param {string} [payload.userId='me'] - user id
   * @param {string} payload.title - title of group
   * @returns {EventGroup} - event group
   * @example
   *
   * createEventGroup({
   *   title: 'test'
   * })
   */

  prototype.createEventGroup = function (payload) {
    const {
      userId,
      title
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      title
    }

    const raw = ifPromise(payload, () => this._createEventGroup(finalPayload))
    return raw
      .then(res => this.normalizeEventGroup(res.data))
      .catch(error => this.onError('createEventGroup', error))
  }

  prototype._updateEventGroup = function ({ userId, id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/event_groups/${id}`),
      method: 'PUT',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function updateEventGroup
   * @param {object} payload - The event group payload
   * @param {string} [payload.userId='me'] - user id
   * @param {string} payload.id - event group id
   * @param {string} payload.title - event group title
   * @returns {EventGroup} - event group
   * @example
   *
   * updateEventGroup({
   *   id: 123,
   *   title: 'test'
   * })
   */

  prototype.updateEventGroup = function (payload) {
    const raw = ifPromise(payload, () => this._updateEventGroup(payload))
    return raw
      .then(res => this.normalizeEventGroup(res.data))
      .catch(error => this.onError('updateEventGroup', error))
  }

  prototype._deleteEventGroup = function ({ userId, id }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/event_groups/${id}`),
      method: 'DELETE'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function deleteEventGroup
   * @param {object} payload - The event group payload
   * @param {string} [payload.userId='me'] - user id
   * @param {number} payload.id - event group id
   * @returns {void}
   * @example
   *
   * deleteEventGroup({
   *   id: 123
   * })
   */

  prototype.deleteEventGroup = function (payload) {
    const finalPayload = {
      id: payload.id,
      userId: this.normalizeUserId(payload.userId)
    }
    const raw = ifPromise(payload, () => this._deleteEventGroup(finalPayload))
    return raw
      .catch(error => this.onError('deleteEventGroup', error))
  }
}

export default ZEventsApiEventGroups
