import Context from './context'
import AuthableProvider from './AuthableProvider'
import AuthableConsumer from './AuthableConsumer'
import withAuthable from './withAuthable'
import useAuthable from './useAuthable'

const AuthableContext = Context

export {
  AuthableProvider,
  AuthableConsumer,
  withAuthable,
  AuthableContext,
  useAuthable
}

export default AuthableProvider
