import ifPromise from 'onyx-common/ifPromise'

const ZEventsApi = ({ prototype }) => {
  prototype._createSurveyAttempt = function ({ ...rest }) {
    const payload = {
      url: this.getUrl('/api/v1/attempts'),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }
  prototype.createSurveyAttempt = function (payload) {
    const raw = ifPromise(payload, () => this._createSurveyAttempt(payload))
    return raw
      .then(res => Promise.resolve(this.normalizeAndCamelize(res)))
      .catch(error => {
        Promise.reject(Error('loginError', error))
      })
  }

  prototype._getSurveyAttempts = function ({ ...rest }) {
    const payload = {
      url: this.getUrl('/api/v1/attempts'),
      method: 'GET',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }
  prototype.getSurveyAttempts = function (payload) {
    const raw = ifPromise(payload, () => this._getSurveyAttempts(payload))
    return raw
      .then(res => Promise.resolve(this.normalizeAndCamelize(res)))
      .catch(error => {
        Promise.reject(Error('loginError', error))
      })
  }

  prototype._getUserSurveyAttempts = function ({ ...rest }) {
    const payload = {
      url: this.getUrl('/api/v1/attempts/index_user_surveys'),
      method: 'GET',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }
  prototype.getUserSurveyAttempts = function (payload) {
    const raw = ifPromise(payload, () => this._getUserSurveyAttempts(payload))
    return raw
      .then(res => Promise.resolve(this.normalizeAndCamelize(res)))
      .catch(error => {
        Promise.reject(Error('loginError', error))
      })
  }
}

export default ZEventsApi
