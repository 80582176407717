import ifPromise from 'onyx-common/ifPromise'
import normalizeCurrency from 'onyx-common/normalizeCurrency'
import formatCurrency from 'onyx-common/formatCurrency'

const ZEventsApiAdmin = ({ prototype }) => {
  prototype.normalizeAttendanceReport = function (_data) {
    const data = this.normalizeData(_data)

    const ret = {
      id: data.id,
      purchasersFirst: data.buyer.firstname,
      purchasersLast: data.buyer.lastname,
      purchasersEmail: data.buyer.email,
      purchasersUserId: data.buyer.id,
      attendeesFirst: data.ticket_holder.firstname,
      attendeesLast: data.ticket_holder.lastname,
      attendeesEmail: data.ticket_holder.email,
      attendeesUserId: data.ticket_holder.id,
      hostName: `${data.event.host.firstname} ${data.event.host.lastname}`,
      hostEmail: data.event.host.email,
      eventId: data.event.id,
      eventName: data.event.name,
      ticketType: data.type,
      preferredLanguage: data.event_ticket_histories.preferred_language
    }

    ret.ticketPrice = ret.ticketType === 'general' ? data.event.general_ticket_price : data.event.vip_ticket_price
    ret.formattedTicketPrice = formatCurrency(normalizeCurrency(ret.ticketPrice))

    return ret
  }

  prototype._getAttendanceReport = function (data) {
    const payload = {
      url: this.getUrl('/api/v1/admin/reports/attendance'),
      method: 'GET',
      requestType: 'json',
      data
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} getAttendanceReportListSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} getAttendanceReportReturn
   * @property {Event[]} entries - list of users returned
   * @property {getAttendanceReportListSummary} listSummary - list summary object
   * @property {object} criteriaSummary - criteria summary object (empty)
   */

  /**
   * @function getAttendanceReport
   * @param {object} payload - The payload
   * @param {number} payload.searchTerm - search term
   * @param {number} payload.startDate - search start date
   * @param {number} payload.endDate - search end date
   * @param {number} [payload.page=1] - page of results
   * @returns {getAttendanceReportReturn}
   * @example
   *
   * getAttendanceReport({
   *   searchTerm: 'Sam',
   *   startDate: null,
   *   endDate: null,
   *   page: 2
   * })
   */

  prototype.getAttendanceReport = function (payload) {
    const { searchTerm, startDate, endDate, page } = payload
    const finalPayload = {
      page: page || 1
    }

    if (searchTerm) finalPayload.user_term = searchTerm
    if (startDate) finalPayload.start_datetime = startDate
    if (endDate) finalPayload.end_datetime = endDate

    const raw = ifPromise(payload, () => this._getAttendanceReport(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeAttendanceReport))
      .catch(error => this.onError('getAttendanceReport', error))
  }

  prototype.normalizeUsersReport = function (_data) {
    const data = this.normalizeData(_data)

    const ret = {
      userId: data.id,
      email: data.email,
      fullName: `${data.firstname} ${data.lastname}`,
      shareUrl: data.profile?.share_url || null
    }

    return ret
  }

  prototype._getUsersReport = function ({ searchTerm, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/admin/users?search=${searchTerm}`),
      method: 'GET',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} getUsersReportListSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} getUsersReportReturn
   * @property {Event[]} entries - list of users returned
   * @property {getUsersReportListSummary} listSummary - list summary object
   * @property {object} criteriaSummary - criteria summary object (empty)
   */

  /**
   * @function getUsersReport
   * @param {object} payload - The payload
   * @param {number} payload.term - search term
   * @param {number} [payload.page=1] - page of results
   * @returns {getUsersReportReturn}
   * @example
   *
   * getUsersReport({
   *   searchTerm: 'Sam',
   *   page: 2
   * })
   */

  prototype.getUsersReport = function (payload) {
    const { searchTerm, page } = payload
    const finalPayload = {
      searchTerm,
      page: page || 1
    }
    const raw = ifPromise(payload, () => this._getUsersReport(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeUsersReport))
      .catch(error => this.onError('getUsersReport', error))
  }
}

export default ZEventsApiAdmin
