import _formurlencoded from 'form-urlencoded'

const opts = {
  skipIndex: true
}

const formurlencoded = (args, localOpts) => {
  const finalOpts = {
    ...opts,
    localOpts
  }

  return _formurlencoded(args, finalOpts)
}

export default formurlencoded
