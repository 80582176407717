import fetchWrap from 'onyx-common/fetchWrap'

const fetchWrapAndForget = (payload) => {
  return fetchWrap(payload)
    .catch(e => {
      console.log(e)
    })
}

export default fetchWrapAndForget
