import { useEffect } from 'react'
import listenForEvent from 'onyx-common/listenForEvent'
import stopListeningForEvent from 'onyx-common/stopListeningForEvent'

const useAttachEventListeners = (eventListeners, getHelpers) => {
  const entries = eventListeners ? Object.entries(eventListeners) : []

  useEffect(() => {
    entries.forEach(([eventCode, cb]) => {
      listenForEvent(eventCode, (event) => {
        cb(event, getHelpers())
      })
    })
    return () => {
      entries.forEach(([eventCode, cb]) => {
        stopListeningForEvent(eventCode)
      })
    }
  }, [])
}

export default useAttachEventListeners
