const getTheme = (themeKey) => {
  const theme = require('onyx-themes/' + themeKey)

  theme.isLight = () => theme.default.themeId === theme.default.lightThemeId
  theme.isDark = () => theme.default.themeId === theme.default.darkThemeId
  theme.isToggleable = () => theme.default?.lightThemeId && theme.default?.darkThemeId && theme.default.lightThemeId !== theme.default?.darkThemeId

  return {
    default: theme.default,
    ...theme,
    ...theme.default
  }
}

export default getTheme
