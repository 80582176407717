import { useState, useCallback, useLayoutEffect } from 'react'
import debounce from 'onyx-common/debounce'
import deepEqual from 'onyx-common/deep-equal'
const getSize = (el) => {
  if (!el) {
    return {
      width: 0,
      height: 0
    }
  }

  const width = el.clientWidth
  const height = el.clientHeight

  return {
    width,
    height
  }
}

const useComponentSize = (ref, debounceDelay = 100, debounceOpts = { leading: true }) => {
  const [componentSize, setComponentSize] = useState(getSize(ref.current))

  const handleResize = useCallback(debounce(() => {
    if (ref.current) {
      const newSize = getSize(ref.current)
      if (!deepEqual(newSize, componentSize)) setComponentSize(newSize)
    }
  }, debounceDelay, debounceOpts), [ref, setComponentSize])

  useLayoutEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    if (ref.current) {
      if (typeof ResizeObserver === 'function') {
        let resizeObserver = new ResizeObserver(handleResize)
        resizeObserver.observe(ref.current)

        return () => {
          resizeObserver.disconnect(ref.current)
          resizeObserver = null
        }
      }
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref, handleResize])

  return componentSize
}

export default useComponentSize
