import React from 'react'
import RouterContext from './RouterContext'

import PropTypes from 'prop-types'

const RoutingProvider = ({ router: { context }, children }) => {
  return (
    <RouterContext.Provider value={context}>
      {children}
    </RouterContext.Provider>
  )
}

RoutingProvider.propTypes = {
  router: PropTypes.shape({
    context: PropTypes.object.isRequired
  }).isRequired,
  children: PropTypes.node
}

export default RoutingProvider
