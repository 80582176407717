// AS: Why are we doing this?
// The redux store when accessed outside of react context (ie: with window.Onyx)
// does not reflect state changes when accessing the store directly.  It's like we get the initial version
// but as things are changed, they do not get updated.  So we have to wrap our accessor methods for the window
// context to ask for the current state at execution time.
import associateQueryHelperContext from 'onyx-common/associateQueryHelperContext'
import isRouteObject from 'onyx-common/isRouteObject'
import _isLever from 'onyx-common/isLever'
import _isAction from 'onyx-common/isAction'
import isFunction from 'onyx-common/isFunction'

const generatePeRootStoreHelpers = ({ store, queryHelper }) => {
  const scope = arr => arr.pe.Root
  const getScope = () => scope
  const getScopedState = () => scope(store.getState())
  const getScopedActions = () => scope(store.getActions())

  const getTheme = () => getScopedState().getTheme()
  const getLocale = () => getScopedState().getLocale()
  const getAuth = () => getScopedState().getAuth()
  const getAuthToken = () => getScopedState().getAuthToken()
  const getAuthRefreshToken = () => getScopedState().getAuthRefreshToken()
  const getAuthIdentifier = () => getScopedState().getAuthIdentifier()
  const isAuthenticated = () => getScopedState().isAuthenticated()

  const getItems = () => getScopedState().getItems()
  const getItemByInstanceId = instanceId => getScopedState().getItemByInstanceId(instanceId)
  const getItemByComponent = component => getScopedState().getItemByComponent(component)

  const getItem = payload => getScopedState().getItem(payload)
  const getPeItem = payload => getScopedState().getItem(payload) // we run into a collision on ingredients helpers payload so we want to let this be available always

  const getRoutes = () => getScopedState().getRoutes()
  const getRoutesByItem = (payload) => getScopedState().getRoutesByItem(payload)

  const getLevers = () => getScopedState().getLevers()
  const getLeversByItem = (payload) => getScopedState().getLeversByItem(payload)

  const getQueries = () => getScopedState().getQueries()
  const getQueriesByItem = (payload) => getScopedState().getQueriesByItem(payload)

  const getActions = () => getScopedState().getActions()
  const getActionsByItem = (payload) => getScopedState().getActionsByItem(payload)

  const getProps = () => getScopedState().getProps()
  const getPropsByItem = (payload) => getScopedState().getPropsByItem(payload)

  const getQuery = (...payload) => getScopedState().getQuery(...payload)
  const getAction = (...payload) => getScopedState().getAction(...payload)
  const getRoute = (...payload) => getScopedState().getRoute(...payload)
  const getLever = (...payload) => getScopedState().getLever(...payload)
  const getProp = (...payload) => getScopedState().getProp(...payload)

  const isQuery = (...payload) => getScopedState().isQuery(...payload)
  const isAction = (...payload) => getScopedState().isAction(...payload)
  const isRoute = (...payload) => getScopedState().isRoute(...payload)
  const isLever = (...payload) => getScopedState().isLever(...payload)
  const isProp = (...payload) => getScopedState().isProp(...payload)

  const hydrateQuery = (...payload) => {
    if (payload.length === 1) return associateQueryHelperContext({ ctx: queryHelper, query: payload[0] })

    const [item, queryFunc, queryPayload] = payload

    const query = getQuery(item, queryFunc)
    return query && associateQueryHelperContext({ ctx: queryHelper, query: query(queryPayload) })
  }

  const preloadQuery = (...payload) => {
    const bag = hydrateQuery(...payload)
    return bag && bag.preloadQuery()
  }

  const navigate = (...payload) => {
    if (isRouteObject(payload[0])) {
      const [route, routePayload] = payload
      // a route was passed directly
      return route.navigate(routePayload)
    }

    const [item, routeId, routePayload] = payload
    const route = getRoute(item, routeId)
    return route && route.navigate(routePayload)
  }

  const navigateClose = (...payload) => {
    if (isRouteObject(payload[0])) {
      const [route, routePayload] = payload
      // a route was passed directly
      return route.navigate(routePayload)
    }

    const [item, routeId, routePayload] = payload
    const route = getRoute(item, routeId)
    return route && route.navigate(routePayload)
  }

  const buildUrl = (...payload) => {
    if (isRouteObject(payload[0])) {
      const [route, routePayload] = payload
      // a route was passed directly
      return route.buildUrl(routePayload)
    }

    const [item, routeId, routePayload] = payload
    const route = getRoute(item, routeId)
    return route && route.buildUrl(routePayload)
  }

  const buildCloseUrl = (...payload) => {
    if (isRouteObject(payload[0])) {
      const [route, routePayload] = payload
      // a route was passed directly
      return route.buildUrl(routePayload)
    }

    const [item, routeId, routePayload] = payload
    const route = getRoute(item, routeId)
    return route && route.buildCloseUrl(routePayload)
  }

  const pullLever = (...payload) => {
    if (_isLever(payload[0])) {
      const [lever, leverPayload] = payload
      // a lever was passed directly
      return lever(leverPayload)
    }

    const [item, leverId, leverPayload] = payload
    const lever = getLever(item, leverId)

    return lever && lever(leverPayload)
  }

  const runAction = (...payload) => {
    if (_isAction(payload[0])) {
      const [action, actionPayload] = payload
      // a lever was passed directly
      return action(actionPayload)
    }

    const [item, actionId, actionPayload] = payload
    const action = getAction(item, actionId)

    return action && action(actionPayload)
  }

  const fireProp = (...payload) => {
    if (isFunction(payload[0])) {
      const [cb, cbPayload] = payload

      return cb(cbPayload)
    }

    const [item, propKey, propPayload] = payload
    const prop = getProp(item, propKey)

    return isFunction(prop) && prop(propPayload)
  }

  const helpers = {
    ...getScopedActions(),
    isAuthenticated,
    getAuth,
    getAuthToken,
    getAuthRefreshToken,
    getAuthIdentifier,
    getItem,
    getPeItem,
    getItems,
    getRoute,
    getRoutes,
    getRoutesByItem,
    getItemByInstanceId,
    getItemByComponent,
    getLever,
    getLevers,
    getLeversByItem,
    getProp,
    getProps,
    getPropsByItem,
    getActions,
    getActionsByItem,
    getAction,
    getQueries,
    getQueriesByItem,
    getQuery,
    hydrateQuery,
    preloadQuery,
    navigate,
    navigateClose,
    buildUrl,
    buildCloseUrl,
    pullLever,
    isLever,
    isQuery,
    isAction,
    isRoute,
    isProp,
    fireProp,
    runAction,
    getLocale,
    getTheme,
    getScope
  }

  return helpers
}

export default generatePeRootStoreHelpers
