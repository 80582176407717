import isDateObject from 'onyx-common/isDateObject'
import isEmpty from 'onyx-common/isEmpty'

const normalizeDate = (str, platform) => {
  if (
    isDateObject(str) ||
    isEmpty(str)
  ) return str

  return new Date(str)
}

export default normalizeDate
