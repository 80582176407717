// import LoraFont from './static/Lora-Regular.ttf'
import LoraFont from './Lora-VariableFont_wght.ttf'
import LoraItalicFont from './Lora-Italic-VariableFont_wght.ttf'
import LoraBoldFont from './static/Lora-Bold.ttf'

const styles = {
  '@font-face': [
    {
      fontFamily: '"Lora", serif',
      fontWeight: 'normal',
      fontStyle: 'normal',
      src: `url(${LoraFont}) format('truetype')`
    },
    {
      fontFamily: '"Lora", serif',
      fontWeight: 'normal',
      fontStyle: 'normal',
      src: `url(${LoraItalicFont}) format('truetype')`
    },
    {
      fontFamily: '"Lora", serif',
      fontWeight: 'bold',
      fontStyle: 'normal',
      src: `url(${LoraBoldFont}) format('truetype')`
    }
  ]
}

export default styles
