import generateShouldBakeByOnyxVariation from 'onyx-common/generateShouldBakeByOnyxVariation'
// import generateIngredientInstance from 'onyx-common/generateIngredientInstance'
// import {} from '../ingredients'

const ingredients = []

const shouldBake = generateShouldBakeByOnyxVariation('alpha')

export default {
  id: 'stup.alpha',
  ingredients,
  shouldBake
}
