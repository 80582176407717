import currency from 'onyx-common/currency-js'
import isEmpty from 'onyx-common/isEmpty'

const normalizeCurrency = (val) => {
  if (isEmpty(val)) return val

  const obj = currency(val)
  return obj.value
}

export default normalizeCurrency
