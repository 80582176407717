import { useLayoutEffect } from 'react'
import uiDelay from 'onyx-common/uiDelay'

const useRunOnceAfterRender = (cb) => {
  useLayoutEffect(() => {
    uiDelay(() => {
      cb()
    })
  }, [])
}

export default useRunOnceAfterRender
