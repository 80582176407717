import { create } from 'onyx-common/jss'
import preset from 'onyx-common/jss-preset-default'

const createJss = (opts = {}) => {
  const jss = create(preset(opts))

  return jss
}

export default createJss
