const config = {
  clientId: 'gz',
  theme: 'Kwivvr',
  storeKey: 'onyx-app-pe',
  defaultService: 'clientContext',
  defaultServiceMethod: 'run',
  defaultCountryId: 'US',
  TRANSITION_CONFIG: {
    timeoutMs: 2000
  },
  scrollToElementOpts: {
    offset: -20,
    align: 'top',
    focusDelay: 100,
    ease: 'linear',
    duration: 100
  },
  useLivePlayground: false,
  corsAnywhereBaseUrl: 'https://cors-anywhere.herokuapp.com/'
}

const onyxReplacementUrl = document.documentElement.getAttribute('data-onyx-replacement-url')
const src = onyxReplacementUrl || document.currentScript.src
const a = document.createElement('a')
a.href = src

const baseUrl = a.origin

config.i18n = {
  baseUrl
}

let defaultLocale = 'en-US'
try {
  defaultLocale = document.querySelector("meta[name='onyx-locale']").getAttribute('content')
// eslint-disable-next-line no-empty
} catch (e) {}

config.defaultLocale = defaultLocale

export default config
