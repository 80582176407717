import ifPromise from 'onyx-common/ifPromise'

const ZEventsApiNotification = ({ prototype }) => {
  prototype.normalizeNotification = function (_data) {
    const data = this.normalizeData(_data)
    const mapToProps = {
      id: 'id',
      created_at: ['createdAt', val => this.normalizeDate(val)],
      type: 'template',
      message_vars: 'replacementVars',
      message: 'originalMessageTemplate',
      params: 'params'
    }
    const mapped = this.filterAndMapProps(data, mapToProps)
    const getTranslationVars = (replacementVars) => {
      const fullRepVars = {
        kwivrr_bug_avatar: replacementVars?.kwivrr_bug_avatar?.asset_path,
        host_avatar: replacementVars?.kwivrr_bug_avatar?.asset_path,
        host_name: replacementVars?.host_name,
        purchaser_avatar: replacementVars?.purchaser_avatar?.asset_path,
        purchaser_name: replacementVars?.purchaser_name,
        recipient_name: replacementVars?.recipient_name,
        recipient_avatar: replacementVars?.recipient_avatar?.asset_path,
        event_name: replacementVars?.event_link?.text
      }

      return Object.keys(replacementVars).reduce((acc, repVar) => {
        if (fullRepVars[repVar]) acc[repVar] = fullRepVars[repVar]
        return acc
      }, {})
    }

    const getEventNameColor = (type) => {
      switch (type) {
        case 'EventReadyToSellNotification': return 'primary'
        case 'EventLiveManagerNotification': return 'default'
        default: return 'green'
      }
    }

    const getAvatar = (type, replacementVars) => {
      return replacementVars?.host_avatar
    }

    const withAttendingIcon = Object.keys(mapped.replacementVars).includes('attending_icon')
    const ret = {
      ...mapped,
      eventId: mapped.params?.event_id,
      eventName: mapped.replacementVars?.event_name,
      translationVars: getTranslationVars(data.message_vars),
      avatar: getAvatar(mapped.template, data.message_vars),
      eventTextColor: getEventNameColor(mapped.template),
      withAttendingIcon,
      type: mapped.template
    }

    return ret
  }

  prototype._getNotification = function ({ id }) {
    const payload = {
      url: this.getUrl(`/api/v1/notifications/${id}`),
      method: 'GET'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function getNotification
   * @param {object} payload - The payload object
   * @param {number} payload.id - notification id
   * @returns {object} notifications
   * @example
   *
   * getNotification({
   *   page: 1
   * })
   */

  prototype.getNotification = function (payload) {
    const finalPayload = {
      id: payload.id
    }

    const raw = ifPromise(payload, () => this._getNotification(finalPayload))
    return raw
      .then(res => this.normalizeNotification(res.data))
      .catch(error => this.onError('getNotification', error))
  }

  prototype._getNotifications = function (data) {
    const payload = {
      url: this.getUrl('/api/v1/notifications'),
      method: 'GET',
      requestType: 'json',
      data
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function getNotifications
   * @param {object} payload - The payload object
   * @param {number} [payload.page=1] - page of results
   * @returns {object} notifications
   * @example
   *
   * getNotifications({
   *   page: 1
   * })
   */

  prototype.getNotifications = function (payload = {}) {
    const finalPayload = {
      page: payload?.page || 1
    }

    const raw = ifPromise(payload, () => this._getNotifications(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeNotification))
      .catch(error => this.onError('getNotifications', error))
  }
}

export default ZEventsApiNotification
