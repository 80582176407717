import deepCopy from 'onyx-common/deepcopy'
import noop from 'onyx-common/noop'
import isPlainObject from 'onyx-common/isPlainObject'
import isUndefined from 'onyx-common/isUndefined'
import escapeDOMId from 'onyx-common/escapeDOMId'

const defaults = {
  add: (payload) => {
    const { addItem, getItem, onAddComplete } = payload

    const item = getItem(payload)

    if (isPlainObject(item)) addItem(item)
    onAddComplete({ ...payload, item })
  },
  addPlayground: (payload) => {
    const { addItem, onAddCompletePlayground, getItemPlayground } = payload

    const item = getItemPlayground(payload)

    if (isPlainObject(item)) addItem(item)
    onAddCompletePlayground({ ...payload, item })
  },
  preheat: noop,
  preheatPlayground: noop,
  prep: noop,
  prepPlayground: noop,
  getItem: noop,
  getItemPlayground: (payload) => {
    const { getItem } = payload
    const item = getItem(payload)
    if (!isPlainObject(item)) return undefined

    const escapedLzSelector = escapeDOMId(`#onyx-${item.instanceId}`)

    item.lzSelector = escapedLzSelector
    item.lzCreateIfMissing = true
    item.adaptorId = 'playgroundAdaptor'

    return item
  },
  shouldAdd: (payload) => true,
  shouldAddPlayground: (payload) => true,
  onAddComplete: (payload) => noop,
  onAddCompletePlayground: (payload) => noop,
  onBakeComplete: (payload) => noop,
  onBakeCompletePlayground: (payload) => noop
}

const generateIngredient = _ingredient => payload => {
  const ingredient = deepCopy(_ingredient)

  const tmpIngredient = {
    ...defaults,
    ...ingredient
  }

  const finalPayload = {
    ...payload,
    id: tmpIngredient.id,
    instanceId: tmpIngredient.instanceId,
    instanceRef: tmpIngredient.instanceRef,
    routePrefix: tmpIngredient.routePrefix,
    _add: defaults.add,
    _addPlayground: defaults.addPlayground,
    _preheat: defaults.preheat,
    _preheatPlayground: defaults.preheatPlayground,
    _prep: defaults.prep,
    _prepPlayground: defaults.prepPlayground,
    _getItem: defaults.getItem,
    _getItemPlayground: defaults.getItemPlayground,
    _shouldAdd: defaults.shouldAdd,
    _shouldAddPlayground: defaults.shouldAddPlayground,
    _onAddComplete: defaults.onAddComplete,
    _onAddCompletePlayground: defaults.onAddCompletePlayground,
    _onBakeComplete: defaults.onBakeComplete,
    _onBakeCompletePlayground: defaults.onBakeCompletePlayground
  }

  const ret = {
    id: tmpIngredient.id,
    instanceId: tmpIngredient.instanceId,
    instanceRef: tmpIngredient.instanceRef
  }

  ret.getItem = () => {
    const item = tmpIngredient.getItem(finalPayload)
    if (!isPlainObject(item)) return undefined

    if (isUndefined(item.instanceId)) item.instanceId = tmpIngredient.instanceId
    if (isUndefined(item.instanceRef)) item.instanceRef = tmpIngredient.instanceRef
    if (isUndefined(item.paramPrefix)) item.paramPrefix = tmpIngredient.paramPrefix

    return item
  }

  // AS: This is a neat trick.  We want to be able to at runtime override the getItem tied to this entry, so if we explode
  // it inside of the closure, it will get it's current reference.
  // If instead we did finalPayload.getItem = getItem and just passed it down, it would not get taken over
  ret.shouldAdd = () => tmpIngredient.shouldAdd({ ...finalPayload, getItem: ret.getItem })
  ret.shouldAddPlayground = () => tmpIngredient.shouldAddPlayground({ ...finalPayload, shouldAdd: ret.shouldAdd, getItem: ret.getItem })
  ret.getItemPlayground = () => tmpIngredient.getItemPlayground({ ...finalPayload, getItem: ret.getItem })
  ret.prep = () => tmpIngredient.prep(finalPayload)
  ret.prepPlayground = () => tmpIngredient.prepPlayground({ ...finalPayload, prep: ret.prep, getItem: ret.getItem })
  ret.preheat = () => tmpIngredient.preheat(finalPayload)
  ret.preheatPlayground = () => tmpIngredient.preheatPlayground({ ...finalPayload, preheat: ret.preheat, getItem: ret.getItem })
  ret.onAddComplete = () => tmpIngredient.onAddComplete({ ...finalPayload, getItem: ret.getItem })
  ret.onAddCompletePlayground = () => tmpIngredient.onAddCompletePlayground({ ...finalPayload, getItemPlayground: ret.getItemPlayground, getItem: ret.getItem })
  ret.add = () => tmpIngredient.add({ ...finalPayload, getItem: ret.getItem, onAddComplete: ret.onAddComplete })
  ret.addPlayground = () => tmpIngredient.addPlayground({ ...finalPayload, getItemPlayground: ret.getItemPlayground, onAddCompletePlayground: ret.onAddCompletePlayground, getItem: ret.getItem })
  ret.onBakeComplete = () => tmpIngredient.onBakeComplete({ ...finalPayload, getItem: ret.getItem })
  ret.onBakeCompletePlayground = () => tmpIngredient.onBakeComplete({ ...finalPayload, onBakeComplete: ret.onBakeComplete, getItem: ret.getItem })

  return ret
}

export default generateIngredient
