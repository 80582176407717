import wrapRelativeUrl from 'onyx-common/wrapRelativeUrl'
import parseListSummary from 'onyx-common/parseListSummary'
import fetchWrap from 'onyx-common/fetchWrap'
import fetchWrapAndForget from 'onyx-common/fetchWrapAndForget'
import isFunction from 'onyx-common/isFunction'
import actionCable from 'onyx-common/actioncable'
import format from 'onyx-common/string-template'
import camelize from 'onyx-common/camelize'
import normalize from 'onyx-common/flattenJsonApiResponse'
import encrypt from 'onyx-common/encrypt'
import isUndefined from 'onyx-common/isUndefined'
import hasKey from 'onyx-common/hasKey'
import isArray from 'onyx-common/isArray'
import normalizeDate from 'onyx-common/normalizeDate'
import normalizeCurrency from 'onyx-common/normalizeCurrency'

// Every service must have at least a constructor
// Just to re-iterate, USE THE `function ()` SYNTAX HERE.
// NO ARROW FUNCTIONS.

// This is how the constructor must be handled.

const ZEventsApiBase = function (sentIn) {
  const {
    siteUrl = 'https://onyx.kwivrr.com',
    baseUrl,
    shareBaseUrl,
    actionCableUrl,
    clientId,
    clientSecret,
    augmentFetchPayload,
    authable,
    fetchWrap: _fetchWrap,
    fetchWrapAndForget: _fetchWrapAndForget,
    authenticatedFetchWrap,
    authenticatedFetchWrapAndForget,
    nexioPublicKey,
    platform = 'web'
  } = sentIn

  this.shareBaseUrl = shareBaseUrl || baseUrl
  this.siteUrl = siteUrl
  this.baseUrl = baseUrl
  this.actionCableUrl = actionCableUrl
  this.clientId = clientId
  this.clientSecret = clientSecret
  this.nexioPublicKey = nexioPublicKey
  this.platform = platform

  const getFetcher = (fetcher, baseFetcher) => {
    if (isFunction(fetcher)) return fetcher
    if (!isFunction(augmentFetchPayload)) return baseFetcher

    const ret = (payload) => {
      const finalPayload = augmentFetchPayload(payload)
      return baseFetcher(finalPayload)
    }

    return ret
  }

  this.authable = authable

  this.fetchWrap = getFetcher(_fetchWrap, fetchWrap)
  this.fetchWrapAndForget = getFetcher(_fetchWrapAndForget, fetchWrapAndForget)

  const self = this

  this.authenticatedFetchWrapSync = function (payload, credentials, runCount = 0) {
    if (!self.authable.hasCredentialsSync(credentials)) return Promise.reject(Error('NO_ACCESS_TOKEN', payload))

    const finalPayload = self.authable.onAugmentPayloadSync(payload, credentials)

    return self.fetchWrap(finalPayload)
      .catch(function (sentIn) {
        const { response } = sentIn

        if (runCount > 0 || !self.authable.isInvalidAccessTokenFailure(response)) return Promise.reject(sentIn)

        return self.refresh()
          .then(function (res) {
            return self.authable.onRefresh(res)
              .then(newCredentials => {
                const newRunCount = runCount + 1
                return self.authenticatedFetchWrapSync(payload, newCredentials, newRunCount)
              })
          })
          .catch(function (e) {
            runCount++
            return self.authable.forceLogout(() => self.authenticatedFetchWrap(payload))
          })
      })
  }

  this.authenticatedFetchWrap = isFunction(authenticatedFetchWrap) ? authenticatedFetchWrap : function (payload) {
    return self.authable.getCredentials()
      .then(credentials => {
        return self.authenticatedFetchWrapSync(payload, credentials)
      })
  }

  this.authenticatedFetchWrapAndForgetSync = isFunction(authenticatedFetchWrapAndForget) ? authenticatedFetchWrapAndForget : function (payload, credentials) {
    return this.authenticatedFetchWrapSync(payload, credentials)
      .catch(e => {
        console.log(e)
      })
  }

  this.authenticatedFetchWrapAndForget = isFunction(authenticatedFetchWrapAndForget) ? authenticatedFetchWrapAndForget : function (payload) {
    return this.authenticatedFetchWrap(payload)
      .catch(e => {
        console.log(e)
      })
  }

  this.optionallyAuthenticatedFetchWrap = function (payload) {
    return self.authable.hasCredentials()
      .then(hasCredentials => {
        if (hasCredentials) return self.authenticatedFetchWrap(payload)
        return self.fetchWrap(payload)
      })
  }
}

ZEventsApiBase.prototype.NAME = 'zeventsApi'
ZEventsApiBase.prototype.store = null

ZEventsApiBase.prototype.getUrl = function (path) {
  return this.baseUrl + path
}

ZEventsApiBase.prototype.getShareUrl = function (path) {
  return this.shareBaseUrl + path
}

ZEventsApiBase.prototype.getSiteUrl = function (path) {
  return this.siteUrl + path
}

ZEventsApiBase.prototype.setStore = function (store) {
  this.store = store
}

ZEventsApiBase.prototype.getStore = function (store) {
  return this.store
}

ZEventsApiBase.prototype.wrapRelativeUrl = function (path) {
  return wrapRelativeUrl(path, this.baseUrl)
}

ZEventsApiBase.prototype.parseListSummary = parseListSummary

// ACTION Cable additions
ZEventsApiBase.prototype.cable = null

ZEventsApiBase.prototype.getActionCableUrl = function () {
  const accessToken = this.authable.getAccessToken()
  return format(this.actionCableUrl, { accessToken })
}

ZEventsApiBase.prototype.connectActionCable = function () {
  this.cable = actionCable.createConsumer(this.getActionCableUrl)
}

ZEventsApiBase.prototype.disconnectActionCable = function () {
  if (!this.cable) return
  this.cable.disconnect()
  this.cable = null
}

ZEventsApiBase.prototype.getActionCable = function (force = false) {
  if (!this.cable || force) this.connectActionCable()
  return this.cable
}

ZEventsApiBase.prototype.getActionCableSubscriptions = function () {
  if (!this.cable) return []
  return this.cable.subscriptions
}

ZEventsApiBase.prototype.subscribeToActionCable = function (channelPayload, callbacks) {
  const cable = this.getCable()

  const finalCallbacks = {}
  if (isFunction(callbacks?.onReceived)) finalCallbacks.onReceived = callbacks.onReceived
  if (isFunction(callbacks?.onConnected)) finalCallbacks.onConnected = callbacks.onConnected
  if (isFunction(callbacks?.onDisconnected)) finalCallbacks.onDisconnected = callbacks.onDisconnected

  const subscription = cable.subscriptions.create(channelPayload, finalCallbacks)

  // this is important because it's how we unsubscribe later
  return subscription
}

ZEventsApiBase.prototype.normalizeAndCamelize = function ({ data, ...rest }) {
  const normalized = this.normalizeAndCamelizeData(data)
  return {
    ...rest,
    data: normalized
  }
}

ZEventsApiBase.prototype.normalizeData = function (data) {
  if (!hasKey(data, 'data')) return data // this is only intended to be used for JSONAPI unrolling

  // we want to only normalize what we have to
  const normalized = normalize(data)

  // console.log('##normalizeData', { data: JSON.stringify(data), normalized: JSON.stringify(normalized) })
  return normalized
}

ZEventsApiBase.prototype.normalizeListData = function (data, mapEntryFunc, bindThis = true) {
  const normalized = this.normalizeData(data)
  const entries = this.normalizeListEntries(normalized.data, mapEntryFunc, bindThis)
  return this.augmentListData(entries, data)
}

ZEventsApiBase.prototype.normalizeListEntries = function (entries, mapEntryFunc, bindThis = true) {
  let finalEntries = entries
  if (isFunction(mapEntryFunc)) {
    if (bindThis) mapEntryFunc = mapEntryFunc.bind(this)

    finalEntries = entries.map(mapEntryFunc)
  }

  return finalEntries
}

ZEventsApiBase.prototype.normalizeListDataDirect = function (data, mapEntryFunc, bindThis = true) {
  const entries = this.normalizeListEntries(data.data, mapEntryFunc, bindThis)

  return this.augmentListData(entries, data)
}

ZEventsApiBase.prototype.augmentListData = function (entries, data) {
  const ret = {
    _data: data,
    entries
  }

  if (hasKey(data, 'listSummary')) ret.listSummary = parseListSummary(data.listSummary)
  if (hasKey(data, 'criteriaSummary')) ret.criteriaSummary = data.criteriaSummary

  return ret
}

ZEventsApiBase.prototype.camelizeData = function (data) {
  const camelized = camelize(data)
  return camelized
}

ZEventsApiBase.prototype.normalizeAndCamelizeData = function (data) {
  return this.camelizeData(this.normalizeData(data))
}

ZEventsApiBase.prototype.normalizeUserId = function (userId) {
  if (isUndefined(userId)) userId = 'me'
  return userId
}

ZEventsApiBase.prototype.onError = function (title, res, mapErrors = {}) {
  console.log('##error', res)
  if (!res?.data?.error) return Promise.reject(new Error(title, { cause: res }))

  if (!hasKey(mapErrors, 'Internal Server Error')) mapErrors['Internal Server Error'] = 'INTERNAL_SERVER_ERROR'

  const arrArray = !isArray(res.data.error) ? [res.data.error] : res.data.error
  const finalErrors = arrArray.map(err => {
    return hasKey(mapErrors, err) ? mapErrors[err] : err
  })
  throw finalErrors
}

ZEventsApiBase.prototype.encrypt = function ({ publicKey, input }) {
  if (isUndefined(publicKey)) publicKey = this.nexioPublicKey

  encrypt.setKey(publicKey)
  return encrypt.encrypt(input)
}

ZEventsApiBase.prototype.filterAndMapProps = function (payload, mapObj) {
  const data = {}
  for (const [key, value] of Object.entries(mapObj)) {
    if (!hasKey(payload, key)) continue

    switch (true) {
      case isArray(value): {
        const [keyToUse, transform, bindThis = true] = value
        const finalTransform = bindThis ? transform.bind(this) : transform
        data[keyToUse] = finalTransform(payload[key], payload)
        break
      }
      default: {
        data[value] = payload[key]
      }
    }
  }

  return data
}

ZEventsApiBase.prototype.getLastFour = function (input) {
  return input.substring(input.length - 4)
}

ZEventsApiBase.prototype.normalizeDate = function (date) {
  return normalizeDate(date, this.platform)
}

ZEventsApiBase.prototype.normalizeCurrency = function (val) {
  return normalizeCurrency(val)
}
ZEventsApiBase.prototype.normalizeUrl = function (val) {
  if (!/^https?:\/\//i.test(val)) return 'https://' + val
  return val
}

ZEventsApiBase.prototype.getAuthParams = function () {
  return {
    client_id: this.clientId,
    client_secret: this.clientSecret
  }
}

ZEventsApiBase.prototype.API_STATUS_MAP = {
  api_cancelled: 'REVOKED',
  api_purchase: 'PURCHASED',
  api_transfer: 'PENDING_TRANSFER',
  api_force_transfer: 'FORCE_TRANSFERRED',
  api_reclaim: 'RECLAIMED',
  api_claimed: 'CLAIMED',
  api_credentials_set: 'CREDENTIALS_SET',
  api_sell: 'SOLD',
  api_sell_custom: 'SOLD_CUSTOM',
  api_partially_refunded: 'PARTIALLY_REFUNDED',
  api_fully_refunded: 'REFUNDED',
  api_check_in: 'CHECKED_IN',
  api_uncheckin: 'UNCHECKED_IN',
  api_upgrade: 'UPGRADED'
}

export default ZEventsApiBase
