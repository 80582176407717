import Context from './context'
import ConfigProvider from './ConfigProvider'
import ConfigConsumer from './ConfigConsumer'
import withConfig from './withConfig'
import useConfig from './useConfig'

const ConfigContext = Context

export {
  ConfigProvider,
  ConfigConsumer,
  withConfig,
  ConfigContext,
  useConfig
}

export default ConfigProvider
