const generateVideoEmbedUrl = (id, provider) => {
  const finalProvider = provider ? provider.trim().toLowerCase() : undefined

  switch (finalProvider) {
    case 'youtube':
      return `https://www.youtube.com/watch?v=${id}`
    case 'vimeo':
      return `https://player.vimeo.com/video/${id}`
  }

  return undefined
}

export default generateVideoEmbedUrl
