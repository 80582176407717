import isPlainObject from 'onyx-common/isPlainObject'
import hasKey from 'onyx-common/hasKey'
import hasSomeKeys from 'onyx-common/hasSomeKeys'

const isRouteObject = (route) => {
  if (!isPlainObject(route)) return false

  if (!hasKey(route, 'id')) return false

  if (!hasSomeKeys(route, 'view', 'modal')) return false

  return true
}

export default isRouteObject
