import ifPromise from 'onyx-common/ifPromise'

const ZEventsApiEventWaitList = ({ prototype }) => {
  prototype.normalizeEventWaitList = function (_data) {
    const data = this.normalizeData(_data)

    const mapProps = {
      eventId: 'eventId',
      title: 'title',
      cover_image_url: 'coverImageUrl',
      hostId: 'hostId',
      host_avatar_url: 'hostAvatarUrl',
      date_added: 'dateAdded',
      start_datetime: 'startDatetime',
      end_datetime: 'endDatetime',
      vip_tickets_available: 'vipTicketsAvailable',
      ga_tickets_available: 'gaTicketsAvailable',
      ga_ticket_price: 'gaTicketPrice',
      vip_ticket_price: 'vipTicketPrice'
    }

    return this.filterAndMapProps(data, mapProps)
  }

  prototype._getEventWaitList = function ({ userId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/wait_lists`),
      method: 'GET',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} getEventWaitListListSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} getEventWaitListReturn
   * @property {object[]} eventWaitList - list of users returned
   * @property {getEventWaitListListSummary} listSummary - list summary object
   * @property {object} criteriaSummary - list summary object (empty)
   */

  /**
   * @function getEventWaitList
   * @param {object} payload - The payload
   * @param {string} [payload.userId='me'] - userid to assign
   * @param {number} [payload.page=1] - page
   * @returns {getEventWaitListReturn} - get waitlist
   * @example
   *
   * getEventWaitList({
   *   page: 2
   * })
   */
  prototype.getEventWaitList = function (payload = {}) {
    const {
      userId,
      page = 1
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      page
    }

    const raw = ifPromise(payload, () => this._getEventWaitList(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeEventWaitList))
      .catch(error => this.onError('getEventWaitList', error))
  }

  prototype._createEventWaitList = function ({ userId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/wait_lists`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function createEventWaitList
   * @param {object} payload - The event wait list payload
   * @param {string} [payload.userId='me'] - userid to assign
   * @param {number} payload.id - event id
   * @returns {void}
   * @example
   *
   * createEventWaitList({
   *   eventId: 100
   * })
   */
  prototype.createEventWaitList = function (payload) {
    const {
      userId,
      id
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      event_id: id
    }

    const raw = ifPromise(payload, () => this._createEventWaitList(finalPayload))
    return raw
      .then(res => this.normalizeEventWaitList(res.data))
      .catch(error => this.onError('createEventWaitList', error))
  }

  prototype._deleteventWaitList = function ({ userId, id }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/wait_lists/${id}`),
      method: 'DELETE'
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function deleteEventWaitList
   * @param {object} payload - The event wait list payloadeventId
   *   userId: 'me',
   *   id: 100
   * })
   */

  prototype.deleteEventWaitList = function (payload) {
    const {
      userId,
      id
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      id
    }

    const raw = ifPromise(payload, () => this._deleteEventWaitList(finalPayload))
    return raw
      .catch(error => this.onError('deleteEventWaitList', error))
  }
}

export default ZEventsApiEventWaitList
