import isPromise from 'onyx-common/isPromise'
import isFunction from 'onyx-common/isFunction'

const ifPromise = (test, failCase) => {
  if (isPromise(test)) return test
  if (isFunction(failCase)) return failCase(test)

  return failCase
}

export default ifPromise
