import striptags from 'onyx-common/striptags'
import isString from 'onyx-common/isString'

const stripHtml = (str) => {
  if (!isString(str)) return str

  return striptags(str)
}

export default stripHtml
