import ifPromise from 'onyx-common/ifPromise'

const ZEventsApiFeed = ({ prototype }) => {
  prototype._getFeeds = function (data) {
    const payload = {
      url: this.getUrl('/api/v1/feed'),
      method: 'GET',
      requestType: 'json',
      data
    }

    return this.optionallyAuthenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} getFeedsListSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} getFeedsReturn
   * @property {Event[]} entries - list of users returned
   * @property {getFeedsListSummary} listSummary - list summary object
   * @property {object} criteriaSummary - criteria summary object (empty)
   */

  /**
   * @function getFeeds
   * @param {object} payload - The payload
   * @param {number} payload.scope - scope to limit returns to (completed, upcoming, attended, registeredFor)
   * @param {number} [payload.page=1] - page of results
   * @returns {getFeedsReturn}
   * @example
   *
   * getFeeds({
   *   page: 2
   * })
   */

  prototype.getFeeds = function (payload = {}) {
    const finalPayload = {
      page: payload?.page || 1
    }

    const raw = ifPromise(payload, () => this._getFeeds(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeEvent))
      .catch(error => this.onError('getFeeds', error))
  }

  prototype._getForMeEvents = function (data) {
    const payload = {
      url: this.getUrl('/api/v1/events_for_me'),
      method: 'GET',
      requestType: 'json',
      data
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} getForMeEventsListSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} getForMeEventsReturn
   * @property {Event[]} entries - list of users returned
   * @property {getForMeEventsListSummary} listSummary - list summary object
   * @property {object} criteriaSummary - criteria summary object (empty)
   */

  /**
   * @function getForMeEvents
   * @param {object} payload - The payload
   * @param {number} [payload.page=1] - page of results
   * @param {number} [payload.queryFilters] - filters options = [attending, hosting, live, interested]
   * @returns {getForMeEventsReturn}
   * @example
   *
   * getForMeEvents({
   *   filters: ['interested', 'attending']
   * })
   */

  prototype.getForMeEvents = function (payload) {
    const { filters, page = 1 } = payload
    let finalFilters = filters
    if (!finalFilters.length) finalFilters = ['attending', 'hosting', 'live', 'interested']

    const finalPayload = {
      'filter[]': finalFilters,
      page
    }

    const raw = ifPromise(payload, () => this._getForMeEvents(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeEvent))
      .catch(error => this.onError('getForMeEvents', error))
  }

  prototype.normalizeForMeEventsFilterCounts = function (_data) {
    const data = this.normalizeData(_data)
    return {
      attending: data.attending,
      interested: data.interested,
      live: data.live,
      hosting: data.my_events
    }
  }

  prototype._getForMeEventsFilterCounts = function (data) {
    const payload = {
      url: this.getUrl('/api/v1/events_for_me/filter_counts'),
      method: 'GET',
      requestType: 'json',
      data
    }

    return this.authenticatedFetchWrap(payload)
  }

  prototype.getForMeEventsFilterCounts = function (payload = {}) {
    const raw = ifPromise(payload, () => this._getForMeEventsFilterCounts(payload))
    return raw
      .then(res => this.normalizeForMeEventsFilterCounts(res.data))
      .catch(error => this.onError('getForMeEventsFilterCounts', error))
  }
}

export default ZEventsApiFeed
