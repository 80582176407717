import { useEffect } from 'react'
import isFunction from 'onyx-common/isFunction'
import isDefined from 'onyx-common/isDefined'
import createEntree from 'onyx-common/createEntree'
import generateAppWindowHelpers from 'onyx-common/generateAppWindowHelpers'
import generatePeRootStoreHelpers from 'onyx-common/generatePeRootStoreHelpers'
import useRunOnceAfterRender from 'onyx-hooks/useRunOnceAfterRender'
import useListenForQueryDataUpdate from 'onyx-hooks/useListenForQueryDataUpdate'
import useAttachEventListeners from 'onyx-hooks/useAttachEventListeners'

const useSetupPeApp = ({ store, recipes, authable, config, queryHelper }) => {
  const helpers = generatePeRootStoreHelpers({ store, queryHelper })
  const appWindowHelpers = generateAppWindowHelpers({ config })

  useEffect(() => {
    window.Onyx = {
      ...window.Onyx,
      queryHelper,
      authable,
      recipes,
      ...helpers,
      ...appWindowHelpers
    }

    const payload = {
      recipes,
      config,
      ...helpers
    }

    const { prepareEntree, preheatEntree, hydratedRecipes } = createEntree(payload)

    window.Onyx = {
      ...window.Onyx,
      prepareEntree,
      preheatEntree,
      hydratedRecipes
    }

    if (isFunction(window.onOnyxLoaded)) {
      window.onOnyxLoaded(payload)
    }

    const skipOnyxPrepareEntree = !!window.skipOnyxPrepareEntree

    let prepared = false
    if (!skipOnyxPrepareEntree && isFunction(prepareEntree)) {
      prepared = true
      prepareEntree()
    }

    window.Onyx.prepared = prepared
  }, [])

  useRunOnceAfterRender(() => {
    const onPreparedEvent = new CustomEvent('onyx.onPrepared', { details: { onyx: window.Onyx } })
    window.dispatchEvent(onPreparedEvent)

    // google optimize triggering
    if (isDefined(window.dataLayer)) {
      window.dataLayer.push({ event: 'optimize.activate' })
    }
  })

  useListenForQueryDataUpdate(queryHelper.listenForQueryDataUpdateCallback)
  useAttachEventListeners(config?.eventListeners, () => window.Onyx)
}

export default useSetupPeApp
