import Context from './context'
import PropTypes from 'prop-types'
import React from 'react'

const QueriesProvider = ({ children, queries }) => (
  <Context.Provider value={queries}>{children}</Context.Provider>
)

QueriesProvider.propTypes = {
  queries: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default QueriesProvider
