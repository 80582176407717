import ifPromise from 'onyx-common/ifPromise'

const ZEventsApi = ({ prototype }) => {
  prototype.normalizeAccountManagerAssignment = function (_data) {
    const data = this.normalizeData(_data)

    const mapProps = {
      id: 'name',
      type: 'about',
      email: 'email',
      name: 'name',
      avatar_url: 'avatarUrl',
      role: 'role'
    }

    return this.filterAndMapProps(data, mapProps)
  }

  /**
   * @typedef {object} AccountManagerAssignment
   * @property {number} id - credit card id
   * @property {string} name - name of account holder
   * @property {string} about - last four digits of account
   * @property {string} email - is this the default payment method?
   * @property {string} avatarUrl - is this the default payment method?
   */

  prototype._deleteAccountManagerAssignment = function ({ userId, id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/account_manager_assignments/${id}`),
      method: 'DELETE',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @function deleteAccountManagerAssignment
   * @param {object} payload - The event watch payload
   * @param {string} [payload.userId='me'] - userid to assign
   * @param {number} payload.id - account manager assignment id
   * @returns {void}
   * @example
   *
   * deleteAccountManagerAssignment({
   *   userId: 'me',
   *   id: 1
   * })
   */
  prototype.deleteAccountManagerAssignment = function (payload) {
    const raw = ifPromise(payload, () => this._deleteAccountManagerAssignment(payload))
    return raw
      .then(res => Promise.resolve(this.normalizeAndCamelize(res)))
      .catch(error => {
        Promise.reject(Error('loginError', error))
      })
  }

  prototype._getAccountManagerAssignments = function ({ userId, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${userId}/account_manager_assignments`),
      method: 'GET',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }

  /**
   * @typedef {object} getAccountManagerAssignmentsListSummary
   * @property {number} page - page of results
   * @property {number} perPage - results per page
   * @property {boolean} hasMore - are more pages available?
   */

  /**
   * @typedef {object} getAccountManagerAssignmentsReturn
   * @property {AccountManagerAssignment[]} accountManagerAssignments - list of account manager assignments returned
   * @property {getAccountManagerAssignmentsListSummary} listSummary - list summary object
   * @property {object} criteriaSummary - list summary object (empty)
   */

  /**
   * @function getAccountManagerAssignments
   * @param {object} payload - The payload
   * @param {string} [payload.userId='me'] - userid to assign
   * @param {number} [payload.page=1] - page
   * @returns {getAccountManagerAssignmentsReturn} - getAccountManagerAssignments return
   * @example
   *
   * getAccountManagerAssignments({
   *   userId: 'me',
   *   page: 2
   * })
   */
  prototype.getAccountManagerAssignments = function (payload) {
    const {
      userId,
      page = 1
    } = payload

    const finalPayload = {
      userId: this.normalizeUserId(userId),
      page
    }

    const raw = ifPromise(payload, () => this._getAccountManagerAssignments(finalPayload))
    return raw
      .then(res => this.normalizeListData(res.data, this.normalizeAccountManagerAssignment))
      .catch(error => this.onError('getAccountManagerAssignments', error))
  }

  prototype._createEventWatch = function ({ id, ...rest }) {
    const payload = {
      url: this.getUrl(`/api/v1/users/${id}/event_watches`),
      method: 'POST',
      requestType: 'json',
      data: rest
    }

    return this.authenticatedFetchWrap(payload)
  }
}

export default ZEventsApi
