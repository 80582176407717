import isFunction from 'onyx-common/isFunction'

const getLayoutsFromApps = ({ apps, layoutsPayload, layoutsOverride }) => {
  const arr = apps.map(a => a.getLayouts)
  const layouts = arr
    .map(func => func(layoutsPayload))
    .reduce((accumulator, obj, key) => {
      return {
        ...accumulator,
        ...obj
      }
    }, {})

  // if no id is specified, assume key
  Object.keys(layouts).map(key => {
    if (!('id' in layouts[key])) layouts[key].id = key
  })
  const finalLayouts = isFunction(layoutsOverride) ? layoutsOverride(layouts, layoutsPayload) : layouts

  return finalLayouts
}

export default getLayoutsFromApps
