import TriviaSansBookFont from './TriviaSansBook.otf'
import TriviaSansBookItalicFont from './TriviaSansBook-Italic.otf'

const styles = {
  '@font-face': [
    {
      fontFamily: 'Trivia Sans Book',
      fontWeight: 'normal',
      fontStyle: 'normal',
      src: `url(${TriviaSansBookFont}) format('woff2')`
    },
    {
      fontFamily: 'Trivia Sans Book',
      fontWeight: 'normal',
      fontStyle: 'italic',
      src: `url(${TriviaSansBookItalicFont}) format('woff2')`
    }
  ]
}

export default styles
