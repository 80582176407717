import styleInject from 'onyx-common/style-inject'

const importCssUrl = (url, opts) => {
  const css = `
    @import url(${url});
  `

  styleInject(css, opts)
}

export default importCssUrl
