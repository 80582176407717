import isFunction from 'onyx-common/isFunction'

const getRoutesFromApps = ({ apps, routesPayload, routesOverride }) => {
  const arr = apps.map(a => a.getRoutes)

  const routes = arr
    .map(func => func(routesPayload))
    .reduce((accumulator, obj, key) => {
      return {
        ...accumulator,
        ...obj
      }
    }, {})

  // if no path is specified, assume key
  Object.keys(routes).map(key => {
    if (!('path' in routes[key])) routes[key].path = key
  })

  const finalRoutes = isFunction(routesOverride) ? routesOverride(routes, routesPayload) : routes

  // and now flatten to array
  const routesArr = []
  Object.keys(finalRoutes).map(key => {
    routesArr.push(routes[key])
  })

  return routesArr
}

export default getRoutesFromApps
