
import format from 'onyx-common/format'

const requireOneOf = (keys) => {
  return ({ props }) => {
    keys.forEach(key => {
      if (key in props) return null
    })

    const message = format('One of {keys} is required', {
      keys: keys.map(key => format('`{key}`', { key })).join(', ')
    })
    return Error(message)
  }
}

export default requireOneOf
