import hasKey from 'onyx-common/hasKey'

const hasSomeKeys = (obj, keys) => {
  let valid = false

  keys.forEach(k => {
    if (hasKey(obj, k)) valid = true
  })

  return valid
}

export default hasSomeKeys
