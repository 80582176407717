import isFunction from 'onyx-common/isFunction'

const delay = (cb, ms) => {
  return new Promise(resolve => {
    setTimeout(() => {
      const normalizeCb = isFunction(cb) ? cb : () => cb
      resolve(normalizeCb())
    }, ms)
  })
}

export default delay
