import fetchWrap from 'onyx-common/fetchWrap'
import fetchWrapAndForget from 'onyx-common/fetchWrapAndForget'
import $ from 'onyx-common/jquery'
import getCookieValue from 'onyx-common/getCookieValue'
import getOnyxVariation from 'onyx-common/getOnyxVariation'
import getOnyxIsEmbedded from 'onyx-common/getOnyxIsEmbedded'
import decorateLocation from 'onyx-common/decorateLocation'
import injectJss from 'onyx-common/injectJss'
import injectGlobalJss from 'onyx-common/injectGlobalJss'
import delayResolve from 'onyx-common/delayResolve'
import delayReject from 'onyx-common/delayReject'
import importCssUrl from 'onyx-common/importCssUrl'
import isUndefined from 'onyx-common/isUndefined'
import isDefined from 'onyx-common/isDefined'
import uniqueInteger from 'onyx-common/uniqueInteger'
import hasKey from 'onyx-common/hasKey'
import strToDate from 'onyx-common/strToDate'
import stripHtml from 'onyx-common/stripHtml'
import toAbsoluteUrl from 'onyx-common/toAbsoluteUrl'
import emitter from 'onyx-common/emitter'
import emitEvent from 'onyx-common/emitEvent'
import emitQueryDataUpdate from 'onyx-common/emitQueryDataUpdate'
import emitOptimisticQueryDataUpdate from 'onyx-common/emitOptimisticQueryDataUpdate'

const appWindowHelpers = {
  $,
  fetchWrap,
  fetchWrapAndForget,
  _fetchWrap: fetchWrap,
  _fetchWrapAndForget: fetchWrapAndForget,
  getCookieValue,
  getOnyxVariation,
  getOnyxIsEmbedded,
  window,
  document,
  location: decorateLocation(window.location),
  injectJss,
  injectGlobalJss,
  delayResolve,
  delayReject,
  importCssUrl,
  isUndefined,
  isDefined,
  uniqueInteger,
  hasKey,
  strToDate,
  stripHtml,
  toAbsoluteUrl,
  emitter,
  emitQueryDataUpdate,
  emitOptimisticQueryDataUpdate,
  emitEvent
}

export default appWindowHelpers
