const ZEventsApiLanguages = ({ prototype }) => {
  /**
   * @typedef {object} Languages
   * @property {number} id - language id
   * @property {number} label - language label
   */

  /**
   * @function getLanguagesSync
   * @returns {Languages[]} -
   * @example
   *
   * getLanguagesSync()
   */

  prototype.getLanguagesSync = function () {
    return ([
      {
        id: 'en',
        label: 'English'
      },
      {
        id: 'es',
        label: 'Spanish'
      },
      {
        id: 'pt',
        label: 'Portuguese'
      },
      {
        id: 'zh',
        label: 'Mandarin'
      },
      {
        id: 'ko',
        label: 'Korean'
      },
      {
        id: 'fr',
        label: 'French'
      },
      {
        id: 'it',
        label: 'Italian'
      },
      {
        id: 'ro',
        label: 'Romanian'
      },
      {
        id: 'de',
        label: 'German'
      },
      {
        id: 'el',
        label: 'Greek'
      },
      {
        id: 'lt',
        label: 'Lithuanian'
      },
      {
        id: 'ja',
        label: 'Japanese'
      },
      {
        id: 'vi',
        label: 'Vietnamese'
      },
      {
        id: 'th',
        label: 'Thai'
      },
      {
        id: 'id',
        label: 'Bahasa'
      },
      {
        id: 'ru',
        label: 'Russian'
      }
    ])
  }

  /**
   * @typedef {Array} Languages
   * @property {number} id - language id
   * @property {number} label - language label
   */

  /**
   * @function getLanguages
   * @returns {Languages[]} - language return object
   * @example
   *
   * getLanguages()
   */

  prototype.getLanguages = function (payload) {
    const ret = this.getLanguagesSync()
    return Promise.resolve(ret)
  }
}

export default ZEventsApiLanguages
