const keyedArrToObj = (arr, keyField = 'id') => {
  const finalObj = {}
  arr.forEach(item => {
    finalObj[item[keyField]] = item
  })

  return finalObj
}

export default keyedArrToObj
