import Context from './context'
import RecipesProvider from './RecipesProvider'
import RecipesConsumer from './RecipesConsumer'
import withRecipes from './withRecipes'
import useRecipes from './useRecipes'

const RecipesContext = Context

export {
  RecipesProvider,
  RecipesConsumer,
  withRecipes,
  RecipesContext,
  useRecipes
}

export default RecipesProvider
