import React from 'react'
import PropTypes from 'prop-types'
import Box from 'onyx-ui/Box'

/**
 * A reusable component for handling errors in a React (sub)tree.
 */
class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError (error) {
    return {
      error
    }
  }

  render () {
    if (this.state.error != null) {
      return (
        <Box>
          <Box>Error: {this.state.error.message}</Box>
          <Box>
            <pre>{JSON.stringify(this.state.error.source, null, 2)}</pre>
          </Box>
        </Box>
      )
    }

    const { children } = this.props
    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
}

export default ErrorBoundary
