import initI18n from 'onyx-common/initI18n'

const defaultSetup = ({ config }) => {}

const defaultI18nSetup = ({ config }) => {
  return initI18n(config)
}

const getAppWrapperPayload = (opts) => {
  let {
    authable,
    config,
    services,
    store,
    queryHelper,
    router,
    queries,
    i18nSetup,
    setup,
    apps,
    theme,
    themeOverride,
    layoutVarsOverride,
    loadingComponent,
    routeLoadingComponent,
    themeWrapperComponent,
    layoutVars,
    recipes
  } = opts

  const { maxSnack } = config
  if (!theme) theme = config.theme

  const payload = {
    recipes: recipes || [],
    routeLoadingComponent,
    config,
    maxSnack: maxSnack || 1,
    services,
    store,
    router,
    queries,
    i18nSetup: i18nSetup || defaultI18nSetup,
    setup: setup || defaultSetup,
    apps,
    theme,
    themeOverride,
    layoutVarsOverride,
    loadingComponent,
    themeWrapperComponent,
    layoutVars,
    queryHelper,
    authable
  }

  return payload
}

export {
  defaultSetup,
  getAppWrapperPayload
}

export default getAppWrapperPayload
