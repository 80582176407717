import normalizeDate from 'onyx-common/normalizeDate'

const calculateSecondsBetween = (date1, date2) => {
  if (!date2) date2 = new Date()

  const finalDate1 = normalizeDate(date1)
  const finalDate2 = normalizeDate(date2)

  return Math.abs(finalDate1 - finalDate2) / 1000
}

export default calculateSecondsBetween
